import { useState, useEffect } from 'react';

export const usePostMessageIFrame = () => {
	const [dimensions, setDimensions] = useState({
		offsetTop: null,
		viewportHeight: null,
	});

	useEffect(() => {
		const listenDataEmit = (e) => {
			if (!e.data) return;

			let resp;
			
			try {
				resp = JSON.parse(e.data);
			} catch (e) {
				
			}

			if (!resp || !resp.type) return;
			if (resp && resp.type !== "dispatch_iframe_offset") return;

			const { offsetTop, viewportHeight } = resp.data;

			setDimensions({
				offsetTop,
				viewportHeight
			})
		}

		// add event listener mount
		window.addEventListener('message', listenDataEmit)

		// remove event listener unmount
		return () => {
			window.removeEventListener('message', listenDataEmit);
		}
	}, [])

	return dimensions;
}