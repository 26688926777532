import React from 'react';
import styled from 'styled-components';

import { toggleModalInfo, toggleModalEnhancedRaw } from '../../../redux/actions/modals';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '../../icons/DeleteIcon';

import useWindowWidth from "../../../utils/useWindowWidth/useWindowWidth";

const Wrapper = styled.div`
  left: 0;
  position: absolute;
  padding: 20px;
  bottom: 0;
  cursor: pointer;

  svg {
    width: 35px;
    height: 35px;
  }
`;

const DescriptionHead = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: ${({theme}) => theme.font.default};
  color: ${({theme}) => theme.palette.dark};
  ${({theme}) =>
    theme.onMD(`
    margin: 35px 0 25px !important;
    font-size: 18px;
  `)}
`;

const DescriptionText = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal 300 16px/25px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;


const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({isMobile}) => isMobile ? 'column': 'row'};
  width: 355px;
  margin: 35px auto 0 auto;
  ${({theme}) =>
    theme.onMD(`
      width: 100%;
      height: 111px;
  `)}
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 160px;
  height: 38px;
  line-height: 36px;
  overflow: hidden;
  vertical-align: center;
  border: 1px solid #D1D3D4;
  border-radius: 19px;
  font-size: 14px ;
  font-family: ${({theme}) => theme.font.default};
  color: ${({ color, theme: {palette}}) => palette[color]};
  background-color: ${({ backgroundColor, theme: {palette} }) => palette[backgroundColor]};
  cursor: pointer;
  &:hover {
    ${({hoverStyle}) => hoverStyle};
  }
  
  ${({theme}) =>
    theme.onMD(`
      width: 100%;
      height: 48px;
      line-height: 46px;
      border-radius: 24px;
  `)}
`;

export const deletePhotoCallback = async () => {};

export const DeleteInnerModal = ({deletePhotoRequest, closeModalEnhancedRaw}) => {
  const { t } = useTranslation();
  const {isMobile} = useWindowWidth();

  return (
    <>
      <DescriptionHead>Foto löschen</DescriptionHead>
      <DescriptionText>
        Möchtest Du diese Fotos wirklich unwiderruflich löschen?
      </DescriptionText>
      <ButtonsContainer isMobile={isMobile}>
        <Button
          color={'dark'}
          backgroundColor={'white'}
          onClick={closeModalEnhancedRaw}
          hoverStyle={'border: 1px solid #000000;'}
        >
          {t('cancel')}
        </Button>
        <Button
          color={'white'}
          backgroundColor={'newDart'}
          onClick={deletePhotoRequest}
          hoverStyle={'background-color: #000000'}
        >
          {t('delete')}
        </Button>
      </ButtonsContainer>
    </>
  );
}

export const DeleteButton = ({ eventID, photoID, onSuccess, onError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/delete`;

  const deletePhotoRequest = () => {
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventID,
        awsID: photoID,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && typeof onSuccess === 'function') {
          closeModalEnhancedRaw();
          onSuccess();
        } else if (!data.success && typeof onError === 'function') {
          onError(data.message);
          showMessage(data.message);
        }
      })
      .catch(err => console.log(err));
  };
  const deletePrompt = () => {
    toggleModalEnhancedRaw(dispatch, {
      children: (
        <DeleteInnerModal
          deletePhotoRequest={deletePhotoRequest}
          closeModalEnhancedRaw={closeModalEnhancedRaw}
        />
      ),
    });
  };
  const showMessage = message => {
    toggleModalInfo(dispatch, {
      title: 'BILD ENDGÜLTIG LÖSCHEN',
      message,
    });
  };
  const closeModalEnhancedRaw = () => {
    toggleModalEnhancedRaw(dispatch, false);
  };

  return (
    <Wrapper key="delete-link" onClick={deletePrompt}>
      <DeleteIcon alt="BILD ENDGÜLTIG LÖSCHEN" />
    </Wrapper>
  );
};
