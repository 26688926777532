import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getCurrentUser } from '../redux/actions/log';

import { MainContainer } from '../components/MainContainer';

import { AuthPage } from '../containers/auth';
import { Sidebar } from '../containers/sidebar';
import { LoginPage } from '../containers/login';
import { PhotoContainer } from '../containers/photoContainer';
import { ErrorPage } from '../containers/errorPage';
import { RestorePage } from '../containers/restore';
import { Loader } from '../components/common/loader';
import { CreateProductPage } from '../containers/createProductPage';
import { Socket } from '../socket';

const validRoutes = require('../../../routes');

const reactContainers = {
  '': { path: '/', name: 'nav_home', component: PhotoContainer },
  event: { path: '/event', name: 'nav_home', component: PhotoContainer, exact: false },
  login: { path: '/login', name: 'nav_login', component: LoginPage },
  register: { path: '/register', name: 'nav_register', component: LoginPage },
  restore: { path: '/restore', name: 'nav_restore', component: RestorePage },
  join: { path: '/join', name: 'nav_join', component: AuthPage },
  download: { path: '/download', name: 'nav_download', component: PhotoContainer, download: true },
  cabinet: { path: '/cabinet', name: 'nav_cabinet', component: PhotoContainer },
  create: { path: '/create', name: 'nav_create', component: CreateProductPage },
};
export const MainRouter = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const eventList = useSelector(state => state.eventList);
  const [loaded, setLoaded] = useState(false);
  const activeEvent = useSelector(state => state.activeEvent);


  const routes = validRoutes.map(e => {
    const { path, name, exact, component, ...props } = reactContainers[e];
    const RouteComponent = component || ErrorPage;
    return (
      <Route path={path} exact={exact === false ? false : true} key={path}>
        <RouteComponent {...props} />
      </Route>
    );
  });

  useEffect(() => {
    getCurrentUser(dispatch, null).then(() => {
      setLoaded(true);
    });
  }, []);

  const loggedIn = currentUser && currentUser.id;
  const isUpEvent = activeEvent && (activeEvent.evt_mode === 'up');

  return loaded ? (
    <MainContainer>
      {loggedIn ? (
        eventList.length ? (
          <>
            {isUpEvent && <Sidebar/>}
            <Switch>
              <Route exact path="/">
                <Redirect to="/event" />
              </Route>
              <Route exact path="/login">
                <Redirect to="/event" />
              </Route>
              <Route exact path="/register">
                <Redirect to="/event" />
              </Route>
              <Route exact path="/join">
                <Redirect to="/event" />
              </Route>
              <Route exact path="/restore">
                <Redirect to="/event" />
              </Route>

              {routes}

              <Route path="/404">
                <ErrorPage />
              </Route>
              <Route>
                <ErrorPage />
              </Route>
            </Switch>
            <Socket />
          </>
        ) : (
          <Switch>
            <Route exact path="/login">
              <Redirect to="/" />
            </Route>
            <Route exact path="/event">
              <Redirect to="/" />
            </Route>

            <Route path="/">
              <AuthPage />
            </Route>
          </Switch>
        )
      ) : (
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/event">
            <Redirect to="/login" />
          </Route>

          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/register">
            <LoginPage isRegister={true} />
          </Route>
          <Route path="/restore">
            <RestorePage />
          </Route>
          <Route path="/join">
            <AuthPage />
          </Route>
        </Switch>
      )}
    </MainContainer>
  ) : (
    <Loader />
  );
};
