import React from 'react';
import styled from 'styled-components';

const InfoContainer = styled.div`
  margin: ${({ mt }) => mt || 16 }px 0 0 0;
  color: ${({ theme }) => theme.palette.mintGreen};
  font-size: 20px;
  font-family: ${({ theme }) => theme.font.default};
`;

export const InfoMessage = ({ children, mt }) => (
  <InfoContainer mt={mt}>
    {children}
  </InfoContainer>
);
