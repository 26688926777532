import React, { createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel, { Modal, ModalGateway } from 'react-images';
import de from 'date-fns/locale/de';
import dateFnsFormat from 'date-fns/format';

import { deleteEventPicture, loadEventPicture, errorEventPicture } from '../../../redux/actions/gallery';
import { ButtonNext, ButtonPrev } from './carouselButtons';

import { formatWithTime } from '../../../utils/dayPicker';

import { CustomCaption } from './customCaption';
import { CustomHeader } from './customHeader';
import { CustomCount } from './customCount';
import { CustomView } from './customView';
import useWindowWidth from "../../../utils/useWindowWidth/useWindowWidth";

class Wrapper extends Carousel {
  constructor(props) {
    super(props);

    let currentIndex = props.currentIndex;

    if (currentIndex > props.views.length) {
      currentIndex = props.views.length;
    }

    this.state = {
      currentIndex,
      // interactionIsIdle: false,
    };

    // this.timer = false;

    // this.timer = setTimeout(() => {
    //   if (this.mounted) {
    //     this.setState({ interactionIsIdle: true });
    //   }
    // }, this.props.hideControlsWhenIdle);
  }

  getCommonProps() {
    const { frameProps, trackProps, modalProps, views, showNavigationOnTouchDevice, totalAmount, eventId } = this.props;
    const isModal = Boolean(modalProps);
    const isFullscreen = Boolean(modalProps && modalProps.isFullscreen);
    const { currentIndex, interactionIsIdle } = this.state;
    const currentView = this.getViewData();

    return {
      carouselProps: this.props,
      currentIndex,
      currentView,
      formatters: this.props.formatters,
      frameProps,
      getStyles: this.getStyles,
      showNavigationOnTouchDevice,
      isFullscreen,
      isModal,
      modalProps,
      // interactionIsIdle,
      trackProps,
      views,
      totalAmount,
      eventId
    };
  }

  componentWillUpdate(nextProps) {
    if (this.state.currentIndex > nextProps.views.length - 1) {
      this.setState({ currentIndex: nextProps.views.length - 1 });
    }

    if (this.state.currentIndex === nextProps.views.length - 6) {
      this.props.onEndOfList();
    }
  }
}

export const CustomCarousel = ({ //todo: make it using dummies for all images from total amount
  pictures,
  currentIndex,
  eventId,
  onDelete,
  onClose, setViewerIsOpen,
  viewerIsOpen,
  onEndOfList,
  totalAmount,
  onToggleFavorite
}) => {
  const dispatch = useDispatch();
  const wrapperRef = createRef();
  const {isMobile} = useWindowWidth();

  const currentUser = useSelector(state => state.currentUser);
  const participants = useSelector(state => state.participantsList);
  const activeEvent = useSelector(state => state.activeEvent)
  const isManager = participants
    ? (participants.find(p => p.id === currentUser.id) || {}).evp_role === 'manager'
    : false;

  const isHost = currentUser.id === activeEvent.evt_owner_id
  const customCarouselStyles = {
    footerCount: (base, props) => {
      return { ...base, marginLeft: 'auto' };
    }
  };

  const onDeletePhoto = (photoID, ownerId) => ownerId === currentUser.id || isManager ? () => {
    if (typeof onDelete === 'function') {
      onDelete();
    }

    dispatch(deleteEventPicture(eventId, photoID));
  } : null;

  const views = pictures.map(ep => {
    const creationDate = ep.ph_creation_date ? new Date(ep.ph_creation_date) : null;
    const imageDateOnly = creationDate ? new Date(creationDate.valueOf() + creationDate.getTimezoneOffset() * 60 * 1000) : (ep.ph_upload_date ? new Date(ep.ph_upload_date) : null);
    const useMb = ((ep.ph_size / 1024) / 1024) >= 1;

    const data = {
      src: `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/${eventId}/gallery/${
        ep.ph_aws_id
      }`,
      thumbnailSmall: `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/${eventId}/190/${
        ep.ph_aws_id
      }`,
      thumbnailBig: `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/${eventId}/360/${
        ep.ph_aws_id
      }`,
      width: ep.ph_width,
      height: ep.ph_height,
      alt: ep.ph_original_name,
      caption: true,
      id: ep.id,
      awsId: ep.ph_aws_id, 
      onDelete: onDeletePhoto(ep.id, ep.ph_customer_id),
      ownerName: `${ep.cs_first_name || ''} ${ep.cs_last_name || ''}`,
      ownerID: ep.ph_customer_id,
      imageDate: imageDateOnly ? `${dateFnsFormat(imageDateOnly, formatWithTime, de)}` : null,
      imageSize: `${(Math.round(((ep.ph_size / 1024) / (useMb ? 1024 : 1)) * 100) / 100).toFixed(2)}${ useMb ? 'Mb' : 'Kb'}`,
      error: ep.error,
      load: ep.load,
      onLoad: () => dispatch(loadEventPicture(eventId, ep.id)),
      onError: () => dispatch(errorEventPicture(eventId, ep.id)),
      isFavorite: !!ep.favorite,
      toggleFavorite: onToggleFavorite,
      favorite: ep.favorite,
      comments: ep.comments,
      comment: ep.comment,
      favorite_count: ep.favorite_count,
      thumbnail: `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/${
        eventId
      }/190/${ep.ph_aws_id}`,
      isHost,
    };

    return {
      ...data,
      src: isMobile ? data.thumbnailBig : data.src
    };
  });

  return (
    <ModalGateway>
      {viewerIsOpen && pictures && pictures.length ? (
        <Modal
          onClose={onClose}
          allowFullscreen={false}
          shouldFocusAfterRender={false}
          shouldReturnFocusAfterClose={false}
          styles={{
            blanket: (base, props) => {
              return { ...base, backgroundColor: '#3B3B39' };
            },
          }}
        >
          <Wrapper
            ref={wrapperRef}
            currentIndex={currentIndex}
            views={views}
            styles={customCarouselStyles}
            // hideControlsWhenIdle={100000}
            onEndOfList={onEndOfList}
            formatters={{
              getAltText: ({ data, index }) => data.alt
            }}
            components={{
              FooterCaption: CustomCaption,
              FooterCount: CustomCount,
              Header: CustomHeader,
              View: CustomView,
              NavigationNext: ButtonNext,
              NavigationPrev: ButtonPrev,
            }}
            eventId={eventId}
          />
        </Modal>
      ) : null}
    </ModalGateway>
  );
};
