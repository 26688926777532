import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import de from 'date-fns/locale/de';
import dateFnsFormat from 'date-fns/format';

import { showSidebar } from '../../redux/actions/sidebar';
import { setCurrentParticipant } from '../../redux/actions/participants';

import go_back_grey from '../../static/assets/icons/go_back_grey.svg';

import { format } from '../../utils/dayPicker';

import { PhotoSizeToggler } from './PhotoSizeToggler';
import { UploadPhotoButton } from './parts/uploadPhotoButton';
import { LoadedData } from './parts/loader';
import { getUserName } from '../../utils/userName';
// import { SelectionToggler } from './parts/selectionToggler';
import { showFavorites } from '../../redux/actions/gallery';
// import { GuestInfo } from './parts/guestInfo';

export const PhotoListTopbar = ({ download, sizeToggler, title, onGoBack }) => {
  const dispatch = useDispatch();
  const activeEvent = useSelector(state => state.activeEvent);
  const currentUser = useSelector(state => state.currentUser);
  // const uploadedData = useSelector(state => state.uploadContainer);
  const currentParticipant = useSelector(state => state.currentParticipant);
  const isGuest = currentUser ? currentUser.cs_role === 'guest' : false;
  // const uploading = !!uploadedData && !!Object.keys(uploadedData).length;
  const showToggler = !download && sizeToggler;
  const onlyFavorites = useSelector(state => state.showFavorites);
  const changeSidebarState = state => showSidebar(dispatch, state);
  const changeFavoritesState = state => dispatch(showFavorites(state));
  const userName = currentParticipant ? getUserName(currentParticipant) : null;
  const allEventPictures = useSelector(state => state.eventPictures);
  const eventPicturesObj = allEventPictures && allEventPictures[activeEvent.id];
  const eventPictures = eventPicturesObj && (currentParticipant && eventPicturesObj.pictures ?
      eventPicturesObj.pictures.filter(p => p.ph_customer_id === currentParticipant.id) :
      eventPicturesObj.pictures);
  const hasPictures = eventPictures && eventPictures.length;
  const showUpload = !title && (currentParticipant ?
       currentParticipant.id === currentUser.id && !hasPictures :
      !download && !onlyFavorites);

  return (
    <>
      <Container>
         {/*{ isGuest ? <GuestInfo /> : null }*/}
        <PhotoContainerTopBar
            fullWidth={!!currentParticipant || !!onlyFavorites || title}
            // showToggler={showToggler}
            download={download}
        >
          {title ?
            <ParticipantTitle>
              <Icon src={go_back_grey} alt="Go back" draggable={false} onClick={onGoBack} />
              <span>{title}</span>
            </ParticipantTitle>
            : (currentParticipant ?
              <>
                <ParticipantTitle>
                  <Icon src={go_back_grey} alt="Go back" draggable={false} onClick={() => {
                    setCurrentParticipant(dispatch, null);
                    changeSidebarState(true);
                  }} />
                  <span>{`Alle Bilder von: ${userName}`}</span>
                </ParticipantTitle>
              </>
              : <>
                {onlyFavorites ?
                  <ParticipantTitle toggler={showToggler}>
                    <Icon src={go_back_grey} alt="Go back" draggable={false} onClick={() => {
                      changeFavoritesState(false);
                      changeSidebarState(true);
                    }} />
                    <span>{'Meine Favoriten'}</span>
                  </ParticipantTitle>
                  : <>
                    <MenuToggler onClick={() => changeSidebarState(true)}>
                      <div></div>
                    </MenuToggler>
                    {activeEvent ?
                      <Title>
                        <div>{activeEvent.evt_name}</div>
                        <TitleDate>{dateFnsFormat(new Date(activeEvent.evt_event_date), format, de)}</TitleDate>
                      </Title>
                      : null
                    }
                  </>
                }
              </>)
          }
          {/*{showToggler ? <LoadedData /> : null}*/}
          {showUpload ? <UploadPhotoButton bottomPositionMobile={0} /> : null}
          {/*{showToggler ? <SelectionToggler /> : null}*/}
          {showToggler ? <PhotoSizeToggler /> : null}
        </PhotoContainerTopBar>
      </Container>

      <PlaceHolder isGuest={isGuest} />
    </>
  );
};

const Container = styled.div`
  width: 100%;
  ${({ theme }) =>
    theme.onMD(`
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
  `)}
`;

const PhotoContainerTopBar = styled.div`
  display: ${props => (props.download ? 'none' : 'flex')};
  flex-flow: row nowrap;
  height: 146px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${({ showToggler, fullWidth }) => (showToggler && !fullWidth ? '216px' : '')};
  background-color: ${({ theme }) => theme.palette.white};

  ${({ theme }) =>
    theme.onMD(`
    display: flex;
    padding-left: 0px;
    height: 113px;
  `)}

  ${({ theme }) =>
    theme.onXS(`
      height: 60px;
  `)}
`;

const MenuToggler = styled.div`
  display: none;
  margin: 5px 5px 5px 20px;
  width: 45px;
  flex-shrink: 0;
  ${({ theme }) =>
    theme.onMD(`
    display: block;
  `)}

  &::after,
  &::before,
  div {
    background-color: black;
    content: '';
    display: block;
    height: 3px;
    margin: 10px 0;
    transition: all 0.2s ease-in-out;
  }

  ${({ theme }) =>
    theme.onXS(`
      margin: 5px 5px 5px 10px;
      width: 35px;
      &::after,
      &::before,
      div {
        height: 2px;
        margin: 8px 0;
      }
    `)}
`;

const Title = styled.div`
  display: none;
  margin: 8px auto 5px 35px;
  font-size: 33px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.black};
  font-weight: 400;
  flex-flow: column;
  line-height: 1.2em;
  max-width: ${({ toggler }) => toggler ? 'calc(100% - 206px)' : '100%'};
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
  position: relative;

  div,
  span { overflow: hidden; text-overflow: ellipsis; }

  ${({ theme, toggler }) =>
    theme.onMD(`
    display: inline-flex;
    max-width: calc(100% - ${toggler ? '239px' : '0'});
  `)}

  ${({ theme, toggler }) =>
    theme.onSM(`
    font-size: 21px;
    max-width: calc(100% - ${toggler ? '159px' : '0'});
  `)}

  ${({ theme }) =>
    theme.onXS(`
    font-size: 17px;
    margin-left: 10px;
  `)}
`;

const TitleDate = styled.span`
  font-size: 15px; color: ${({ theme }) => theme.palette.darkGray};

  ${({ theme }) =>
    theme.onXS(`
    font-size: 12px;
  `)}
`;

const ParticipantTitle = styled(Title)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  font-size: 16px;

  ${({ theme }) =>
    theme.onMD(`
    display: flex;
  `)}

  ${({ theme }) =>
    theme.onSM(`
    font-size: 14px;
  `)}
`;

//todo: check better height for guest
const PlaceHolder = styled.div`
  display: none;

  ${({ theme, isGuest, uploading }) =>
    theme.onMD(`
      display: block;
      height: ${ 113 + (isGuest ? 58 : 0) + (uploading ? 72 : 0)}px;
  `)}

  ${({ theme, isGuest, uploading }) =>
    theme.onXS(`
      height: ${ 60 + (isGuest ? 50 : 0) + (uploading ? 72 : 0)}px;
  `)}
`;

const Icon = styled.img`
  width: 38px;
  height: 38px;
  margin-right: 10px;
  flex-shrink: 0;
  cursor: pointer;
  &:hover {
    filter: invert(100%);
  }
`;