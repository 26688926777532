import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import { PhotoList } from '../components/PhotoContainer/PhotoList';

const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  overflow: auto;
  flex-grow: 1;
  background-color: ${({ theme: { palette } }) => palette.white};
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

export const PhotoContainer = ({ download }) => {

  const activeEvent = useSelector(state => state.activeEvent);

  return (
    <Wrapper>
      {activeEvent ? (
        <React.Fragment>
          <PhotoList download={download} />
        </React.Fragment>
      ) : null}
    </Wrapper>
  );
};
