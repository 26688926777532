import React, {useEffect} from 'react';
import styled from 'styled-components';

import { CustomPinchZoomPan } from './customPinchZoomPan';
import useWindowWidth from "../../../utils/useWindowWidth/useWindowWidth";

export const CustomView = (props) => {
  const { data, formatters, getStyles, index, isFullscreen, isModal, currentIndex } = props;
  const { error, load, onError, onLoad, width, height } = data;

  const {isMobile} = useWindowWidth();

  const inView = index >= currentIndex - 5 && index <= currentIndex + 5;
  const innerProps = {
    alt: formatters.getAltText({ data, index }),
    src: data.src,
  };
  // use same values for styles. File: index.css, line: .react-images__view img
  const stageSize = {
    w: window.innerWidth - (isMobile ? 30 : 180),
    h: window.innerHeight - 210
  };
  const noShrink = width < stageSize.w && height < stageSize.h;
  const isWider = (width / height) > (stageSize.w / stageSize.h);
  const maxImageSize = {
    w: (isWider ? stageSize.w : (width / height) * stageSize.h),
    h: (isWider ? (height / width) * stageSize.w : stageSize.h)
  };

  const imageSize = {
    w: noShrink ? width : maxImageSize.w,
    h: noShrink ? height : maxImageSize.h
  };

  const image = <img
    {...innerProps}
    className={`view-image ${isFullscreen ? 'isFullscreen' : ''} ${isModal ? 'isModal' : ''}`}
    style={{
      width: `${imageSize.w}px`,
      height: `${imageSize.h}px`,
      userSelect: 'none'
    }}
    onError={onError}
  />;

  useEffect(() => {
    if (inView && !load) {
      onLoad();
    }
  }, [inView, load]);

  return (
    <div
      style={{
        ...getStyles('view', {...props})
      }}

      className={`react-images__view view ${isFullscreen ? 'isFullscreen' : ''} ${isModal ? 'isModal' : ''}`}
    >
      {error || !load ?
        <ErrorMessage>Dieses Bild wurde gelöscht. Bitte laden Sie die Seite neu.</ErrorMessage>
        : (
          isMobile ?
            <CustomPinchZoomPan
              visible={currentIndex === index}
              imageSize={imageSize}
              stageSize={stageSize}
            >
              {image}
            </CustomPinchZoomPan>
            : image
        )
      }
    </div>
  );
};

const ErrorMessage = styled.div`
    color: #FFFFFF;
    line-height: 1.5em;
    display: block;
    padding: 20px;
  `;