import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  margin-top: ${({ mt }) => mt || 16 }px;
  color: ${({ theme }) => theme.palette.error};
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.roboto};
  ${({font}) => font ? font: ''}
  ${({ theme }) =>
    theme.onMD(`
    font-size: 14px;
  `)}
`;

export const ErrorMessage = ({ children, mt, font }) => {
  let content = children;

  if (typeof children === 'string') {
    content = [];
    children.split('\n').forEach((l, i, arr) => content.push(l, i < arr.length - 1 ? <br key={`nl_${i}`}/> : null));
  }

  return (
    <ErrorContainer mt={mt} font={font}>
      {content}
    </ErrorContainer>
  );
}
