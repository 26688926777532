export const status = [
  { label: 'Frau', value: 'woman', selected: true },
  { label: 'Herr', value: 'man', selected: false },
  { label: 'Familie', value: 'family', selected: false },
  { label: 'Firma', value: 'firma', selected: false },
  { label: 'Keine Angabe', value: 'none', selected: false }
];

export const getSalutationLabel = value => {
  return (status.find(s => s.value === value) || {}).label || '';
};
