import { LOG_USER, LOGGED_USER, USER_FAILURE, AUTH_TYPE } from '../types';

export const currentUser = (state = null, action) => {
  switch (action.type) {
    case LOGGED_USER:
      return action.payload;
    case LOG_USER:
      return action.payload;
    case USER_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

export const authType = (state = null, action) => {
  switch (action.type) {
    case AUTH_TYPE:
      return action.payload;
    default:
      return state;
  }
}
