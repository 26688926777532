import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SelectBox } from '../../common/selectBox';
import { status } from '../../../utils/salutation';

const Label = styled.label`
  display: block;
  width: 100%;
  text-align: left;
  margin-top: ${({ mt }) => mt || 60}px;
  font-size: 22px;
  color: #000000;
  font-family: ${({ theme }) => theme.font.default};
  ${({ theme }) =>
    theme.onSM(`
    margin-top: 30px;
    font-size: 14px;
  `)}
`;

export const SalutationDropBox = ({ label = true, value = '', onChange = () => {} }) => {
  const { t } = useTranslation();
  const [activeStatus, changeStatus] = useState(status.filter(s => s.value === value)[0] || status[0]);

  useEffect(() => {
    if (value) {
      status.forEach(s => s.selected = s === activeStatus);
    }

    onChange(activeStatus.value); // set right value on mount
  }, []);

  return (
    <>
      {label ? <Label htmlFor="salutation" mt={20}>{`${t("salutation")}*`}</Label> : null}
      <SelectBox
        key="salutation"
        value={value}
        options={status}
        onValueChange={option => {
          changeStatus(option);
          
          onChange(option.value);
        }}
      />
    </>
  );
};