import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {changeDrag, toggleSelectMode} from "../../redux/actions/gallery";

import option_check from '../../static/assets/icons/option_check.svg';
import sort_icon from '../../static/assets/icons/sort_icon.svg';
import {parentPostMessage} from "../../utils/postMessage";
import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";

const DotsIcon = ({isOver}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			viewBox="0 0 40 40"
		>
			<g id="dots" transform="translate(40) rotate(90)">
				<circle
					id="Ellipse_1848"
					data-name="Ellipse 1848"
					cx="20"
					cy="20"
					r="20"
					transform="translate(0 40) rotate(-90)"
					fill={isOver ? '#f7f3ed' : '#fff'}
				/>
				<g id="Group_18340" data-name="Group 18340" transform="translate(-5906 1114) rotate(90)">
					<rect
						id="Rectangle_23892"
						data-name="Rectangle 23892"
						width="40"
						height="40"
						transform="translate(-1114 -5946)"
						fill='#fff'
						opacity="0"
					/>
					<circle id="Ellipse_1776" data-name="Ellipse 1776" cx="1.5" cy="1.5" r="1.5" transform="translate(-1096 -5935)"/>
					<circle id="Ellipse_1777" data-name="Ellipse 1777" cx="1.5" cy="1.5" r="1.5" transform="translate(-1096 -5927.5)"/>
					<circle id="Ellipse_1778" data-name="Ellipse 1778" cx="1.5" cy="1.5" r="1.5" transform="translate(-1096 -5920)"/>
				</g>
			</g>
		</svg>

	)
}

export const GalleryOptionsToggler = ({bottomPositionMobile, includeOpacity, isThankYouCardGuest, showUpload}) => {
	const [isOver, changeIsOver] = useState(false);
	const [isActive, changeIsActive] = useState(false);

	const { isMobile } = useWindowWidth();

	const currentUser = useSelector(state => state.currentUser);
	const activeEvent = useSelector(state => state.activeEvent);

	const isHost = currentUser && (currentUser.id == activeEvent.evt_owner_id);

	const menuRef = useRef(null);

	const dispatch = useDispatch();

	useEffect(() => {
		const  handleClickOutside = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				changeIsOver(false);
				changeIsActive(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [menuRef]);

	useEffect(() => {
		parentPostMessage('dispatch_scroll_top_button_position', false, true, false, true, isActive);
	}, [isActive])


	return (
		<Wrapper isThankYouCardGuest={isThankYouCardGuest} showUpload={showUpload}>
			<IconContainer
				className={!!bottomPositionMobile && 'in-view'}
				bottomPositionMobile={bottomPositionMobile}
				includeOpacity={includeOpacity}
				onMouseOut={() => {
					changeIsOver(false)
				}}
				onMouseOver={() => {
					changeIsOver(true)
				}}
				onClick={() => changeIsActive(!isActive)}
			>
				<DotsIcon
					isOver={isOver || isActive}
				/>

				<OptionContainer visible={isActive} ref={menuRef}>
					<OptionElement
					 onClick={() => dispatch(toggleSelectMode())}
					>
						<OptionIcon src={option_check}/>
						<div>Foto(s) auswählen</div>
					</OptionElement>

					{isHost && !isMobile && (
						<OptionElement
							onClick={() => {
								dispatch(changeDrag(true))
								parentPostMessage('dispatch_gallery_sort_mode', false, true, false, true, true);
							}}
						>
							<OptionIcon style={{marginLeft: '4px'}} src={sort_icon}/>
							<div>Sortieren per Drag&Drop</div>
						</OptionElement>
					)}
				</OptionContainer>
			</IconContainer>
		</Wrapper>
	)
}

const Wrapper = styled.div`
  ${({ theme, bottomPositionMobile, includeOpacity, isThankYouCardGuest, showUpload }) => {
    if (isThankYouCardGuest || !showUpload) {
    	return theme.onMD(`
				&.in-view {
					opacity: 1;
					transition: opacity 0.2s ease-in;
				}
				position: fixed;
				height: 64px;
				width: 100%;
				right: 0;
			 
				bottom: ${bottomPositionMobile || 0}px;
				transition: none;
				opacity: ${includeOpacity ? 0 : 1};
				border-radius: 0;
				margin: 0;
				z-index: 3;
				background-color: #ffffff;
		`)
		}
    return null;
  }}
`;

const IconContainer = styled.div`
  height: 40px;
	cursor: pointer;
  width: auto;
	position: relative;
  margin-right: 14px;
  ${({ theme, bottomPositionMobile, includeOpacity }) => {

    return theme.onMD(`
    &.in-view {
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }
    position: fixed;
    height: 64px;
    padding: 10px;
    right: 0;
    bottom: ${bottomPositionMobile || 0}px;
    transition: none;
    opacity: ${includeOpacity ? 0 : 1};
    border-radius: 0;
    margin: 0;
    z-index: 3;
    background-color: #ffffff;
  `)
  }}
`;

const OptionContainer = styled.div`
	display: ${({visible}) => visible ? 'block': 'none'};
	position: absolute;
  top: 42px;
  right: 19px;
  width: 179px;
	padding: 6px 0;
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 2px 6px #00000029;
  opacity: 1;
  ${({ theme }) => theme.onMD(`
		top: -38px;
		right: 21px;
  `)}
`;

const OptionElement = styled.div`
	display: flex;
  align-items: center;
	padding: 3px 8px;
  font: normal normal 300 14px/19px Roboto;
  letter-spacing: 0;
  color: #000000;
  &:hover {
    background-color: ${({ theme }) => theme.palette.lotionGray};
  }
`;

const OptionIcon = styled.img`
	width: 25px;
	margin-right: 15px;
`;
