import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { toggleModalEvent } from '../redux/actions/modals';
// import { getCurrentUser } from '../redux/actions/log';
import sm_logo from '../static/assets/images/sendmoments_big.png';

import { ActivateCode } from '../components/common/activateCode';
import { FullButton } from '../components/common/fullBtn';
// import { useState } from 'react';
// import { CodeActivatedModal } from '../components/Modals/codeActivated';
import { Separator } from '../components/common/separator';
import { SocialName } from '../components/common/socialName';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  ${({ theme }) =>
    theme.onSM(`
    justify-content: flex-end;
    padding: 40px;
  `)}
`;

const Logo = styled.img`
  width: 20rem;
  margin-bottom: 40px;
  ${({ theme }) =>
    theme.onSM(`
    margin-bottom: 0;
    width: 80%;
  `)}
`;

const InfoText = styled.div`
  width: 386px;
  max-width: 100%;
  font-size: 17px;   
  margin: 7vh 0 0;
  text-align: center;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme: { palette } }) => palette.dark};
  ${({ theme }) =>
    theme.onSM(`
    font-size: 14px;
  `)}
`;

const LogOut = styled.div`
  text-align: right;
  font-size: 15px;
  color: #636363;
  width: 346px;
  max-width: 100%;
  padding: 10px 0;
`;
  
export const AuthPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);

  const loggedIn = currentUser && currentUser.id;

  const changeModalEvent = state => toggleModalEvent(dispatch, state);

  const redirectToLogin = () => {
    window.location.replace(`${window.location.origin}/login`);
  };

  const logOut = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}${'auth/logout'}`;

    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          redirectToLogin();
        } else if (data.message === 'redirect') {
          window.location.replace(data.data.redirect_url);
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <Wrapper>
      { loggedIn ? <>
          <Logo src={sm_logo} alt="logo" draggable={false} />
          <InfoText>Sie sind mit {currentUser.accounts.sendmoments ? currentUser.cs_email : <SocialName user={currentUser} />} eingeloggt. Wählen Sie eine der folgenden Optionen:</InfoText>
        </> 
        : null 
      }
      <ActivateCode logo={!loggedIn} info={false} />
      {loggedIn ? (
        <>
          <Separator>{'oder'}</Separator>
          <FullButton
            genWith="386px"
            btnColor="mintGreen"
            text={t('create_new_event')}
            click={() => changeModalEvent(true)}
            mt={0}
          />
          <Separator style={{ marginTop: '25px' }} />
          <LogOut><a herf="#" onClick={logOut}>{t('logout')}</a></LogOut>
          {/* <FullButton
            genWith="386px"
            btnColor="lightGray"
            click={() => logOut()}
            text={t('LogOut')}
          /> */}
        </>
      ) : (
        <FullButton
          genWith="386px"
          mt={20}
          btnColor="lightGray"
          click={redirectToLogin}
          text={t('login_or_register')}
        />
      )}
    </Wrapper>
  );
};
