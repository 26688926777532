import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";

class Wrapper extends PinchZoomPan {
    static defaultProps = {
        initialScale: 1,
        maxScale: 3,
        minScale: 1,
        zoomButtons: false,
        position: 'center',
        doubleTapBehavior: 'zoom'
    };

    componentDidUpdate(prevProps) {
        const { visible } = this.props;
        const { imageDimensions = { width: 0, height: 0 }, containerDimensions = { width: 0, height: 0 } } = this.state;

        if (visible === false && visible !== prevProps.visible) {
            this.setState({ scale: 1, left: (containerDimensions.width - imageDimensions.width) / 2, top: (containerDimensions.height - imageDimensions.height) / 2 });
        }
    }

    canBeSwiped() {
        const { scale, left, imageDimensions, containerDimensions } = this.state;
        const widthDifference = containerDimensions.width - imageDimensions.width * scale;

        if (
            typeof left === 'number'
            && scale
            && imageDimensions
            && typeof imageDimensions.width === 'number'
        ) {
            return (left === (widthDifference > 0 ? widthDifference / 2 : 0) || left === widthDifference);
        }

        return true;
    }

    getScale() {
        return this.state.scale;
    }
}

export const CustomPinchZoomPan = ({ visible, stageSize, children }) => {
    const wrapperRef = useRef();
    const pinchZoomRef = useRef();

    // prevent gallery navigation on zoomed image move
    useEffect(() => {
        const wrapper = wrapperRef.current;
        const pinchZoom = pinchZoomRef.current;

        let couldBeSwiped = false;

        const onTouchMove = (evt) => {
            if (pinchZoom) {
                const scale = pinchZoom.getScale();
                const canBeSwiped = pinchZoom.canBeSwiped();

                if (scale && scale !== 1 && !(canBeSwiped && couldBeSwiped)) {
                    evt.preventDefault && evt.preventDefault();
                    evt.stopPropagation && evt.stopPropagation();
                }
            }
        };
        const onTouchEnd = () => {
            if (pinchZoom) {
                couldBeSwiped = pinchZoom.canBeSwiped();
            }
        };

        if (wrapper) {
            wrapper.addEventListener('touchmove', onTouchMove, { passive: false });
            wrapper.addEventListener('touchend', onTouchEnd);
        }

        return () => {
            if (wrapper) {
                wrapper.removeEventListener('touchmove', onTouchMove, { passive: false });
                wrapper.removeEventListener('touchend', onTouchEnd);
            }
        }
    }, [wrapperRef.current, pinchZoomRef.current]);

    return (
        <PinchZoomWrapper 
            ref={wrapperRef} 
            stageSize={stageSize} 
            style={{
                width: stageSize.w,
                height: stageSize.h
            }}
        >
            <Wrapper
                {...Wrapper.defaultProps}
                ref={pinchZoomRef}
                visible={visible}
            >
                {children}
            </Wrapper>
        </PinchZoomWrapper>
    );
};

const PinchZoomWrapper = styled.div`
  overflow: hidden;
  text-align: left;
  
  & > div { 
    overflow: visible !important; 
    img { 
        ${ ({ stageSize }) => `
        max-width: ${ stageSize.w }px;
        max-height: ${ stageSize.h }px;
        ` }
    }
  }
`;
