import React, { useState } from 'react';

export const Icon = (props) => {
    const { viewBox, className, style, colors, hoverColors, paths, alt } = props;
    const [hover, setHover] = useState(false);
    const width = props.width || props.size;
    const height = props.height || props.size;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} width={width} height={height} className={className} style={style} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { alt ? <title>{alt}</title> : null }
            { paths.map((path, i) => <path key={`path-${i}`} d={path} fill={ hover && hoverColors ? hoverColors[i] : colors[i] } />) }
        </svg>
    );
};