import dateFnsFormat from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import de from 'date-fns/locale/de';

export const formatLong = date =>
  dateFnsFormat(new Date(date), 'EEEE d. MMMM yyyy', { locale: de }) || 'Invalide date';

export const timeDistance = date =>
  formatDistanceToNow(date, {
    includeSeconds: true,
    addSuffix: false,
    locale: de,
  });
