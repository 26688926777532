import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { validate } from '../../utils/validateForms';
import { InputContainer } from './parts/inputContainer';
import { FullButton } from '../common/fullBtn';
import { ErrorMessage } from '../common/errorMessage';
import { SalutationDropBox } from './parts/salutationDropBox';
import { useHistory } from 'react-router';
import { toggleModalInfo } from '../../redux/actions/modals';
import { useDispatch } from 'react-redux';

export const RegisterForm = ({ isGuest, onSuccess }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const changeModalInfo = state => toggleModalInfo(dispatch, state);
  const [data, setData] = useState({
    email: '',
    password: '',
    salutation: '',
    firstname: '',
    lastname: ''
  });
  const [errorMessages, setErrorMessages] = useState({
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    company: ''
  });
  const isFirma = data => data.salutation === 'firma';
  const changeData = (name, value) => {
    const newData = {
      ...data,
    };
    newData[name] = value;
    setData(newData);
  };

  const onSalutationCahnge = (value) => {
    const newData = {
      ...data,
      salutation: value
    };

    if (value === 'firma') {
      newData['company'] = newData['company'] || '';
    } else {
      delete newData['company'];
    }

    setData(newData);
  };

  const isValid = (key, value) => {
    let valid = true;

    const dataToCheck = key ? {[key]: value.trim()} : { 
      email: data.email.trim(),
      password: data.password,
      salutation: data.salutation,
      firstname: data.firstname.trim(),
      lastname: data.lastname.trim()
    };
    const result = validate(dataToCheck);
    const messages = { ...errorMessages };

    Object.keys(dataToCheck).forEach(key => {
      if (result[key]) {
        valid = valid && result[key].success;
        messages[key] = t(result[key].error) || '';
      }
    });

    setErrorMessages({ ...messages, general: ''});

    return valid;
  }

  const submitForm = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${isGuest ? 'auth/register_temp' : 'auth/register'}`; //`${process.env.REACT_APP_BACKEND_DOMAIN}auth/register`

    if (isValid()) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email: data.email.trim(),
          password: data.password,
          salutation: data.salutation,
          firstname: data.firstname.trim(),
          lastname: data.lastname.trim(),
          ...(isFirma(data) ? { firmname: data.company.trim() } : null)
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            onRegisterSuccess();
          } else {
            setErrorMessages({ ...errorMessages, general: t(data.message) });
          }
        })
        .catch(err => console.log(err));
    }
  };

  const onRegisterSuccess = () => {
    changeModalInfo({ 
        title: 'Vielen Dank für Ihre Registrierung!', 
        message: `Sie erhalten in Kürze eine E-Mail an Ihre E-Mail-Adresse ${data.email} mit einem Aktivierungslink. Bitte bestätigen Sie Ihre Anmeldung durch Klick auf den Aktivierungslink in der E-Mail.`, 
        onClose: () => {
          if (typeof onSuccess === 'function') {
            onSuccess();
          } else {
            history.push('/login'); 
          }
        } 
      });
  }

  return (
    <>

      <SalutationDropBox onChange={onSalutationCahnge} />

      { isFirma(data) ? <InputContainer 
        key="company" 
        label={`${t("company_name")}*`} 
        value={data.company || ''}
        onChange={({ target: { value } }) => changeData('company', value) }
        onBlur={ ({ target: { value } }) => isValid('company', value) }
        placeholder={t("company_name")}
        error={errorMessages.company}
      /> : null}

      <InputContainer 
        name="first_name" 
        label={`${t("first_name")}*`} 
        value={data.firstname}
        onChange={({ target: { value } }) => changeData('firstname', value) }
        onBlur={ ({ target: { value } }) => isValid('firstname', value) }
        placeholder={t("first_name")}
        error={errorMessages.firstname}
      />

      <InputContainer 
        name="last_name" 
        label={`${t("last_name")}*`} 
        value={data.lastname}
        onChange={({ target: { value } }) => changeData('lastname', value) }
        onBlur={ ({ target: { value } }) => isValid('lastname', value) }
        placeholder={t("last_name")}
        error={errorMessages.lastname}
      />

      <InputContainer
        label={`${t('email_address')}*`}
        name="email"
        placeholder={t('email')}
        value={data.email}
        onChange={({ target: { value } }) => changeData('email', value) }
        onBlur={ ({ target: { value } }) => isValid('email', value) }
        error={errorMessages.email}
      />

      <InputContainer
        label={`${t('password')}*`}
        type="password"
        name="password"
        placeholder={t('password')}
        value={data.password}
        onChange={({ target: { value } }) => changeData('password', value) }
        onBlur={ ({ target: { value } }) => isValid('password', value) }
        error={errorMessages.password}
      />

      { errorMessages.general ? <ErrorMessage>{ errorMessages.general }</ErrorMessage> : null }

      <FullButton
        genWith="386px"
        mt={30}
        btnColor="mintGreen"
        click={submitForm}
        text={t('submit')}
      />
    </>
  );
};