import {ACTIVE_EVENT, EVENT_LIST, EVENT_TYPES, ADD_EVENT, CHANGE_EVENT, DELETE_EVENT} from '../types';

export const activeEvent = (state = null, action) => {
  switch (action.type) {
    case CHANGE_EVENT:
      const event = action.payload.event;
      if (state.id === event.id) {
        return event;
      } else {
        return state;
      }

    case ADD_EVENT:
    case ACTIVE_EVENT:
      return action.payload;
    case DELETE_EVENT:
      if (state === action.payload) {
        return null;
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const eventList = (state = [], action) => {
  switch (action.type) {
    case CHANGE_EVENT:
      const event = action.payload.event;
      return [ ...state.map((e) => e.id === event.id ? event : e)];
    case DELETE_EVENT:
      const index = state.indexOf(action.payload);
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    case ADD_EVENT:
      const newEvent = action.payload;
      return [ ...state.filter(e => e.id !== newEvent.id), newEvent ];
    case EVENT_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const eventTypes = (state = [], action) => {
  switch (action.type) {
    case EVENT_TYPES:
      return action.payload;
    default:
      return state;
  }
};
