import {
  ACTIVE_EVENT,
  EVENT_LIST,
  EVENT_TYPES,
  CHANGE_EVENT,
  ADD_EVENT,
  DELETE_EVENT,
} from '../types';
import { fetchEventPicturesIfNeeded } from './gallery';
import { getParticipants } from './participants';
import { socketConnection } from '../../socket';

export const switchEvent = async (dispatch, getState) => {
  if (getState) {
    socketConnection().emit('event_message', getState.id, data => console.log('data', data));
    dispatch({ type: ACTIVE_EVENT, payload: getState });
    dispatch(getParticipants(getState.id));
    dispatch(fetchEventPicturesIfNeeded(getState.id));
  }
};

export const eventList = (dispatch, getState) => {
  if (typeof getState === 'object' && getState.length) {
    dispatch({ type: EVENT_LIST, payload: getState });
  }
};

export const eventTypes = (dispatch, getState) => {
  if (typeof getState === 'object' && getState.length) {
    dispatch({ type: EVENT_TYPES, payload: getState });
  }
};

export const changeEvent = (dispatch, getState) => {
  if (typeof getState === 'object') {
    socketConnection().emit('event_message', getState.id, data => console.log('data', data));
    dispatch({ type: CHANGE_EVENT, payload: getState });
  }
};

export const addEvent = (dispatch, getState) => {
  if (typeof getState === 'object') {
    socketConnection().emit('event_message', getState.id, data => console.log('data', data));
    dispatch({ type: ADD_EVENT, payload: getState });
    dispatch(getParticipants(getState.id));
    dispatch(fetchEventPicturesIfNeeded(getState.id));
  }
};

export const deleteEvent = (dispatch, getState) => {
  if (typeof getState === 'object') {
    dispatch({ type: DELETE_EVENT, payload: getState });
  }
};
