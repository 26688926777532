import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme: { palette } }) => palette.dark};
  border-bottom: ${({ hideBorder }) => (!hideBorder ? '1px solid rgba(138, 132, 128, 0.6)' : 'none')};
  cursor: pointer;
  flex-shrink: 0;
  background-color: ${({ theme: { palette }, selected }) => selected ? palette.middleGray : 'none'};
  &:hover {
    background-color: ${({ theme: { palette }, selected }) => selected ? palette.middleGray : palette.lotionGray};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 77px;
  height: 80px;
  margin-right: 5px;
  ${({ theme }) =>
    theme.onMD(`
    height: 58px;
  `)}
`;

const Icon = styled.img`
  height: ${({ iconProps }) => iconProps.height || 45}px;
  width: ${({ iconProps }) => iconProps.width || 56}px;
  ${({ theme, mobIconProps }) =>
    theme.onMD(`
    height: ${(mobIconProps && mobIconProps.height) || 35}px;
    width: ${(mobIconProps && mobIconProps.width) || 35}px;
  `)}
`;

const Text = styled.div`
  font-family: ${({theme}) => theme.font.default};
  font-size: 21px;
  ${({ theme }) =>
    theme.onMD(`
    font-size: 15px;
  `)}
`;

export const ActionButton = ({
                               hideBorder,
                               iconSrc,
                               mobIconProps,
                               iconProps,
                               text,
                               click,
                               href,
                               selected,
                               style,

}) => {
  return (
    <Container
      as={href ? 'a' : null}
      href={href} target={href ? '_blank' : null}
      hideBorder={hideBorder} onClick={() => click()}
      selected={selected}
      style={style}
    >
        <IconContainer>
          {iconSrc && (
          <Icon src={iconSrc} iconProps={iconProps} mobIconProps={mobIconProps} />
          )}
        </IconContainer>
      <Text>{text}</Text>
    </Container>
  );
};
