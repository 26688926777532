import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { toggleModalInvitation, toggleModalInfo } from '../../redux/actions/modals';
import { navigateToFace, showSidebar } from '../../redux/actions/sidebar';
import { setCurrentParticipant, addParticipantManager, addParticipantGuest, getParticipants } from '../../redux/actions/participants';

import { EventTitle } from '../common/eventTitle';
import { EventDate } from '../common/eventDate';
import { BackToMain } from './parts/backToMain';
import { FullBtnWithIcon } from './parts/fullBtnIcon';
import { ActionButton } from '../common/actionButton';
import { UserButton } from './parts/userButton';
import { TextHead } from './parts/textHead';

import settings_icon from '../../static/assets/icons/settings_icon.svg';
// import user_icon from '../../static/assets/icons/user_icon.svg';
import album_owner from '../../static/assets/icons/album_owner.svg';
import album_participant from '../../static/assets/icons/album_participant.svg';
import plus_icon from '../../static/assets/icons/plus_icon.svg';
import minus_icon from '../../static/assets/icons/minus_icon.svg';
import { getUserName } from '../../utils/userName';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;

const List = styled.div`
  /* margin: 0 0 0 14px;
  ${({ theme }) =>
    theme.onMD(`
      margin: 0 0 0 14px;
    `)} */
`;

const NoGuests = styled.div`
  padding: 10px 20px;
  line-height: 1.5em;
  font-size: 17px;
  color: rgb(138, 132, 128);
  ${({ theme }) =>
    theme.onMD(`
      font-size: 14px;
    `)}
`;

export const GuestFace = ({ state }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const participantsList = useSelector(state => state.participantsList);
  const currentUser = useSelector(state => state.currentUser);
  const activeEvent = useSelector(state => state.activeEvent);
  const currentParticipant = useSelector(state => state.currentParticipant);
  const isManager = participantsList ?
      (participantsList.filter(p => p.id === currentUser.id)[0] || {}).evp_role === 'manager' :
      false;
  const isOwner = currentUser.id === activeEvent.evt_owner_id;

  let request = null;

  const changeFace = state => navigateToFace(dispatch, state);
  const changeModalInvitation = state => toggleModalInvitation(dispatch, state);
  const changeSidebarState = state => showSidebar(dispatch, state);

  const owner = participantsList.find(e => e.id === activeEvent.evt_owner_id);
  const managers = participantsList.filter(e => e.evp_role === 'manager' && e.id !== activeEvent.evt_owner_id);
  const participants = participantsList.filter(e => e.evp_role !== 'manager');
  // const guests = participantsList.filter(e => e.evp_role === 'guest');


  const toggleCurrentParticipant = (participant) => {
    setCurrentParticipant(dispatch, currentParticipant === participant ? null : participant);
    changeSidebarState(false);
  };

  const addManagers = () => {
    toggleModalInfo(dispatch, {
      title: 'WEITEREN ORGANISATOR ERNENNEN',
      message: <>
        {participants.map((e, i) => (
          <UserButton
            hideBorder={i === participants.length - 1}
            iconSrc={album_owner}
            iconProps={{ width: 27, height: 42 }}
            text={`${getUserName(e)} ${currentUser.id === e.id ? '(ich)' : ''}`}
            click={() => addManager(e)}
            key={`add_${e.id}`}
          />
        ))}
      </>,
      buttonText: t('cancel')
    });
  };

  const addManager = (participant) => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'api/user/promote'}`;
    const data = {
      customerID: participant.id, 
      eventID: activeEvent.id
    };

    if (!request) {
      request = fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            toggleModalInfo(dispatch, false);
            addParticipantManager(dispatch, { ...participant, evp_role: 'manager' });
          } else {
            toggleModalInfo(dispatch, {
              title: t('there_was_an_error'),
              message: t(data.error)
            });
          }
        })
        .catch(err => console.log(err))
        .finally(() => request = null);
    }
  };

  const removeManager = (participant) => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'api/user/demote'}`;
    const data = {
      customerID: participant.id, 
      eventID: activeEvent.id
    };
    
    if (!request) {
      request = fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            toggleModalInfo(dispatch, {
              title: 'ORGANISATOR ERFOLGREICH ENTFERNT',
              message: `${getUserName(participant)}`
            });
            addParticipantGuest(dispatch, { ...participant, evp_role: 'participant' });
          } else {
            toggleModalInfo(dispatch, {
              title: t('there_was_an_error'),
              message: t(data.error)
            });
          }
        })
        .catch(err => console.log(err))
        .finally(() => request = null);
    }

  }
  
  useEffect(() => {
    if (state === 'mount') {
      dispatch(getParticipants(activeEvent.id));
    }
  }, []);

  return (
    <Wrapper>
      <BackToMain mb={30} onClick={() => { setCurrentParticipant(dispatch, null); }} />
      <EventTitle text={activeEvent && activeEvent.evt_name} />
      <EventDate text={activeEvent && activeEvent.evt_event_date} />
      { isManager ? 
        <FullBtnWithIcon
          margins={'35px 0 10px 0'}
          bgColor="mintGreen"
          text={t('send_code')}
          click={() => changeModalInvitation(true)}
        />
        : null 
      }
      <TextHead
          text={t('organizators')} {...(isOwner && !!participants && !!participants.length ?
          { iconSrc: plus_icon, onClick: !!participants && !!participants.length ? addManagers : null }
          : null)}
      />
      <List>
        {owner ? <UserButton
          hideBorder={managers.length === 0}
          iconSrc={album_owner}
          iconProps={{ width: 27, height: 42 }}
          text={`${getUserName(owner)} ${isOwner ? '(ich)' : ''}`}
          click={() => toggleCurrentParticipant(owner)}
          selected={currentParticipant && owner.id === currentParticipant.id}
          key={owner.id}
          counter={owner.total}
        /> : null}
        {!!managers &&
          !!managers.length &&
          managers.map((e, i) => (
            <UserButton
              hideBorder={i === managers.length - 1}
              iconSrc={album_owner}
              iconProps={{ width: 27, height: 42 }}
              mobIconProps={{ width: 20, height: 31 }}
              text={`${getUserName(e)} ${currentUser.id === e.id ? '(ich)' : ''}`}
              click={() => toggleCurrentParticipant(e)}
              { ...(isOwner ? {
                actionIcon: minus_icon,
                actionClick: () => removeManager(e)
              } : null)}
              selected={currentParticipant && e.id === currentParticipant.id}
              key={`m_${e.id}`}
              counter={e.total}
            />
        ))}
      </List>

      <TextHead text={t('attendees')} />
      <List>
        {!!participants &&
          !!participants.length ?
          participants.map((e, i) => {
            return (
              <UserButton
                hideBorder={i === participants.length - 1}
                iconSrc={album_participant}
                iconProps={{ width: 27, height: 42 }}
                text={`${getUserName(e)} ${currentUser.id === e.id ? '(ich)' : ''}`}
                // text={currentUser.id === e.id ? `${currentUser.cs_first_name} (ich)` : `${e.cs_first_name}`}
                click={() => toggleCurrentParticipant(e)}
                selected={currentParticipant && e.id === currentParticipant.id}
                key={`p_${e.id}`}
                counter={e.total}
              />
            );
          }) :
        <NoGuests>
          Derzeit sind keine Gäste da.<br/> Möchten Sie sie <a href="#"
                                                               onClick={() => changeModalInvitation(true)}>einladen</a>?
        </NoGuests>
      }
      </List>

      {/*{*/}
      {/*  !!guests &&*/}
      {/*  !!guests.length && (*/}
      {/*      <>*/}
      {/*      <TextHead text={t('guests')} />*/}
      {/*        <List>*/}
      {/*          { guests.map((e, i) => {*/}
      {/*            return (*/}
      {/*              <UserButton*/}
      {/*                hideBorder={i === guests.length - 1}*/}
      {/*                iconSrc={user_icon}*/}
      {/*                iconProps={{ width: 27, height: 42 }}*/}
      {/*                text={currentUser.id === e.id ? `${currentUser.cs_first_name} (ich)` : `${e.cs_first_name}`}*/}
      {/*                click={() => toggleCurrentParticipant(e)}*/}
      {/*                selected={e === currentParticipant}*/}
      {/*                key={`g_${e.id}`}*/}
      {/*              />*/}
      {/*            );*/}
      {/*          })*/}
      {/*          }*/}
      {/*        </List>*/}
      {/*      </>*/}
      {/*  )*/}
      {/*}*/}

      <div style={{ marginTop: 'auto' }} />
      {/*<ActionButton*/}
      {/*  hideBorder={true}*/}
      {/*  iconSrc={settings_icon}*/}
      {/*  iconProps={{ width: 36, height: 36 }}*/}
      {/*  mobIconProps={{ width: 26, height: 26 }}*/}
      {/*  text={t('settings')}*/}
      {/*  click={*/}
      {/*    () => {*/}
      {/*      if (!isMobile && document.documentElement.scrollTop) {*/}
      {/*        document.documentElement.scrollTop = 0;*/}
      {/*      }*/}

      {/*      changeFace('customer');*/}
      {/*      setCurrentParticipant(dispatch, null);*/}
      {/*    }*/}
      {/*  }*/}
      {/*/>*/}
    </Wrapper>
  );
};
