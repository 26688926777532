import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import { Photo } from '../Photo';
import Gallery from "react-photo-album";
import {useSelector, useDispatch} from "react-redux";
import Cookies from 'js-cookie';

import {VoucherContainer, voucherProps, voucherSmallProps} from "./voucher";
import { parentPostMessage, messageKeys } from "../../../utils/postMessage";
import theme from "../../../theme";

import {
  changeDragPhotos,
  clearSelection,
  fetchEventPicturesIfNeeded,
  selectAllImages
} from "../../../redux/actions/gallery";
import checkmark_material from "../../../static/assets/icons/checkmark_material.svg";
import useWindowWidth from "../../../utils/useWindowWidth/useWindowWidth";

const { dispatch_gallery_height } = messageKeys;

const Wrapper = styled(({ small, forwardRef, removeBottomPadding, ...props }) => <div ref={forwardRef} {...props} />)`
  padding: ${({ small }) => (small ? '35.5px' : '24px')};
  ${({ theme }) =>
    theme.onMD(`
        padding: 2.5px;
        padding-top: 12px;
        padding-bottom: 50px
      `)}
  ${({ theme, removeBottomPadding }) => removeBottomPadding &&
    theme.onMD(`
        padding: 2.5px;
        padding-top: 12px;
        padding-bottom: 50px
      `)}
  
  ${({ small }) =>
    small ? '& > div > div { display: flex; flex-flow: row wrap; height: auto !important; } ' : ''}
`;

const SelectAll = styled.div`
    display: flex;
    width: auto;
    font: normal normal 300 14px/19px Roboto;
    letter-spacing: 0;
    cursor: pointer;
    color: #000000;
    margin-bottom: 9px;
    margin-top: -12px
`;

const SelectionMark = styled.div`
    width: 19px;
    height: 19px;
    margin-right: 5px;
    border-radius: 50%;
    background: transparent;
    border: ${({ isActive }) => isActive ? 0: 1}px solid #707070;
    box-shadow:0 0 1px 0 rgba(0, 0, 0, 0.5);
    
    img { height: 19px; width: 19px }
`;

export const GalleryWrapper = ({ small, onClick, pictures, eventId, onToggleFavorite, forwardRef, isMenuCard, isUpEvent, isHost, filterByOwner, ...props }) => {
  const [includeVoucher, changeIncludeVoucher] = useState(false);
  const [photos, changePhotos] = useState([]);
  const [allSelected, changeAllSelected] = useState(false);
  const {windowWidth} = useWindowWidth();

  const [hoverPhoto, setHoverPhoto] = useState({id: 0, on: 'left'});

  const dispatch = useDispatch();
  const modalState = useSelector(state => state.modalEnhanced);
  const {selectMode, selectedImages} = useSelector(state => state.selectionReducer);
  const activeEvent = useSelector(state => state.activeEvent);

  const reqSetTrueHeight = modalState && modalState.reqSetTrueHeight;

  const allEventPictures = useSelector(state => state.eventPictures);

  const {draggable, drag_photos} = useSelector(state => state.dragReducer);


  const photoData = (ep) => ({
      id: ep.id,
      order: ep.order || ep.id,
      src: `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/${eventId}/gallery/${
        ep.ph_aws_id
      }`,
      thumbnailSmall: `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/${
        eventId
      }/190/${ep.ph_aws_id}`,
      thumbnailBig: `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/${
        eventId
      }/360/${ep.ph_aws_id}`,
      width: small ? 190 : ep.ph_width,
      height: small ? 190 : ep.ph_height,
      alt: ep.ph_original_name,
      ph_aws_id: ep.ph_aws_id,
      selected: ep.selected,
      favorite: ep.favorite,
      comments: ep.comments,
      comment: ep.comment,
      favorite_count: ep.favorite_count,
      ownerID: ep.ph_customer_id,
      ownerName: `${ep.cs_first_name || ''} ${ep.cs_last_name || ''}`,
      // columnsRatios: 1
    })

    useEffect(() => {
      if (allEventPictures[activeEvent.id] && allEventPictures[activeEvent.id].pictures) {
        dispatch(changeDragPhotos(allEventPictures[activeEvent.id].pictures.map(photo => photoData(photo))))
      }
    }, [draggable]);

    useEffect(() => {
      if (draggable) {
        changePhotos(drag_photos)
      } else if (pictures) {
        if (includeVoucher) {
          const newPhotoList = pictures.reduce((acc, ep, i) => {
            if (i === 0) {
              const voucherPropsData = small ? voucherSmallProps : voucherProps
              return [...acc, photoData(ep), voucherPropsData];
            }
            return [...acc, photoData(ep)]
          }, [])
          changePhotos(newPhotoList);
        } else {
          changePhotos(pictures.map(ep => {
            return photoData(ep)
          }))
        }
      }
    },[pictures, drag_photos, includeVoucher, small, draggable]);

    useEffect(() => {
      const checkIfHaveVoucher = () => {
        const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'auth/check_event_voucher/'}${eventId}`;

        fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            project: Cookies.get('project') || 'post'
          })
        }).then(response => response.json()).then(data => {
          if (data && !data.error) {
            changeIncludeVoucher(data.voucher);
          }
        }).catch(err => console.log(err))
      }

      checkIfHaveVoucher()
    },[eventId, isHost]);

    useEffect(() => {

      changeAllSelected(photos.every(photo => selectedImages.includes(photo.order || photo.id)) && !!selectedImages.length);
    }, [selectedImages])

    const imageMargin = !small && !(windowWidth <= 992) ? 14 : 2.5;

    const numberOfColumnsDesktop = () => {
      const reservedWidth = 190;
      const maxWidth = theme.container.maxWidth;
      let currentWith = window.screen.width;

      if (typeof visualViewport !== "undefined") {
        currentWith = visualViewport.width;
      }

      const useWidth = Math.min(maxWidth, currentWith);

      return Math.round(useWidth / reservedWidth)
    }

    const columns = () => {
        return small ? ((windowWidth <= 992) ? 3 : numberOfColumnsDesktop()) : (windowWidth <= 992)  ? 1 : 3;
    };

    const removeVoucherFromList = () => {
      changeIncludeVoucher(false);
    }

    const toggleSelectAll = () => {
      if (allSelected) {
        dispatch(clearSelection());
      } else {
        dispatch(fetchEventPicturesIfNeeded(activeEvent.id, false, true, (pictures) => {

          const newPictures = pictures && pictures.length && pictures.map(photo => photo.id) || []
          const oldPictures =  photos.length && photos.map(photo => photo.id) || []

          dispatch(selectAllImages([...oldPictures,...newPictures]));
        }))
      }
    }

  const handleReorder = ({ left, right, pointer, current }) => {
    const newPhotos = [...drag_photos];

    const targetIndex = newPhotos.findIndex(item => item.id === current);
    const pointerIndex = newPhotos.findIndex(item => item.id === pointer);

    if (targetIndex === -1 || pointerIndex === -1) {
      return;
    }

    const [removedItem] = newPhotos.splice(targetIndex, 1);

    let adjustedIndex = pointerIndex;
    if (targetIndex > pointerIndex && pointer == left) {
      adjustedIndex = pointerIndex + 1;
    }

    newPhotos.splice(adjustedIndex, 0, removedItem);


    const sorted = drag_photos.sort((ph1, ph2) => ph1.id > ph2.id ? -1 : 1);

    const newPhotosSorted =  newPhotos.map((photo, idx) => {
      photo.order = sorted[idx].id;
      return photo;
    })

    changePhotos(newPhotosSorted);
    dispatch(changeDragPhotos(newPhotosSorted));
  };

  const changeIsHover = (data) => {
    setHoverPhoto(data)
  }

  const imageRenderer = useCallback(
    (props) => {
      const {left, top, key, photo, margin } = props;

      const { layout: {height, width, index}} = props;

      if (photo.isVoucher) {
        return <VoucherContainer
          key={`photo_voucher`}
          small={small}
          amount={includeVoucher.amount}
          currency={includeVoucher.currency}
          innerHeight={width}
          removeVoucherFromList={removeVoucherFromList}
        />
      }

      const internalIndex = index - Number(!!(index && Number(!!includeVoucher)));

      return (
        <Photo
          key={`photo_${photo.id}`}
          margin={margin || 0}
          index={internalIndex}
          photo={photo}
          renderPhoto={{height, width: width}}
          left={left}
          top={top}
          square={small}
          onClick={onClick}
          eventId={eventId}
          onToggleFavorite={onToggleFavorite}
          movePhoto={handleReorder}
          hoverOn={hoverPhoto.id === photo.id && hoverPhoto}
          changeIsHover={changeIsHover}
        />
      );
    },
    [imageMargin, small, onClick, includeVoucher, reqSetTrueHeight, hoverPhoto],
  );

  return (
    <Wrapper id={'gallery-wrapper'} small={small} forwardRef={forwardRef} removeBottomPadding={isMenuCard && !isHost}>
      {
        !!selectMode ? (
          <SelectAll
            onClick={() => toggleSelectAll()}
          >

            <SelectionMark isActive={allSelected}>
              {allSelected ? (
                <div style={{height: '19px', width: '19px', backgroundColor: '#FFB689', borderRadius: '50%'}}>
                  <img src={checkmark_material}/>
                </div>
              )  : null}
            </SelectionMark>
            {allSelected ? 'Alle ausgewählt' : 'Alle auswählen'}
          </SelectAll>
        ) : (
          <SelectAll><div style={{height: '19px'}}>&nbsp;</div></SelectAll>
        )
      }
      <Gallery
        photos={photos}
        renderPhoto={imageRenderer}
        columns={columns}
        layout={"masonry"}
        spacing={imageMargin}
        renderColumnContainer={({columnContainerProps, children} ) => {
          const containerProps = {
            ...columnContainerProps,
            style: {
              ...columnContainerProps.style,
              justifyContent: "space-start",
            }
          };

          parentPostMessage(dispatch_gallery_height, false, false, reqSetTrueHeight, isHost);

          return (
            <div {...containerProps}>
              {children}
            </div>
          );
        }}
      />
    </Wrapper>
  );
};