import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';

import { UploadPhotoButton } from './parts/uploadPhotoButton';
import sendmoments_logo from '../../static/assets/images/sendmoments_logo.svg';
import birthday_logo from '../../static/assets/icons/birthday_logo.svg';

import {PhotoSizeToggler} from "./PhotoSizeToggler";

import {usePostMessageIFrame} from "../../utils/usePostMessageIFrame";
import { getInnerHeight } from '../../utils/postMessage';
import {isIframe} from "../../utils/isIframe/isIframe";
import {GalleryOptionsToggler} from "./GalleryOptionsToggler";
import {SelectImagesActions} from "./parts/selectedImagesActions";
import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";
import {DragImagesActions} from "./parts/dragImagesBar";


const eventIconByType = {
	// 1: wedding_logo,
	// 2: birthday_logo,
	// 3: communion_logo,
	// 4: communion_logo,
	// 5: birthday_logo,
	// 6: communion_logo,
	// 7: birthday_logo,
	// 8: schooling_logo,
	// 9: childrens_birthday_logo,
	// 10: communion_logo,
	'*': birthday_logo,
};

// hide on scroll
// show after 0.5 on stop
// tolerance 10px

let timer = null;


export const ThankAndMenuTopBar = ({ download }) => {
	const [positions, setPositions] = useState({
		headerPosition: 0,
		uploadPosition: 0,
		includeOpacity: false
	});

	const [pageDrive, setPageDrive] = useState({
		prevMove: null,
		lastMove: null
	});

	const activeEvent = useSelector(state => state.activeEvent);
	const currentUser = useSelector(state => state.currentUser);
	const uploadedData = useSelector(state => state.uploadContainer);
	const {selectMode} = useSelector(state => state.selectionReducer);
	const {draggable} = useSelector(state => state.dragReducer);

	const {isMobile} = useWindowWidth();

	const uploadContainer = uploadedData[activeEvent.id] || {};

	const { offsetTop, viewportHeight } = usePostMessageIFrame();

	const isThankYouCard = activeEvent.evt_mode === 'thank';
	// const isMenuCard = activeEvent.evt_mode === 'menu';
	const isUpEvent = activeEvent.evt_mode === 'up';
	const isHost = currentUser && currentUser.id === activeEvent.evt_owner_id;

	const allEventPictures = useSelector(state => state.eventPictures);
	const eventPicturesObj = allEventPictures && allEventPictures[activeEvent.id];
	const eventPictures = eventPicturesObj && eventPicturesObj.pictures;

	const hasPictures = eventPictures && eventPictures.length;

	const showUpload = !activeEvent.evt_cancel && (isHost || !isThankYouCard);
	// const showUpload = false;
	const isThankYouCardGuest = !isHost && isThankYouCard

	let showUploadEmptyMobile = true;

	if (isMobile && !hasPictures && !uploadContainer['started']) {
		showUploadEmptyMobile = false;
	}

	const showLogo = !(isHost && !isUpEvent) || !isIframe;

	useEffect(() => {
		if (!offsetTop || !viewportHeight) return;

		if (isUpEvent) return;
		if (!isHost) return;

		const innerHeight = getInnerHeight();

		const maxUploadPosition = innerHeight - 250;
		const minUploadPosition = 0;

		let uploadPosition = innerHeight + offsetTop - viewportHeight;

		if (uploadPosition > maxUploadPosition) uploadPosition = maxUploadPosition;
		else if (uploadPosition < minUploadPosition) uploadPosition = minUploadPosition;

		setPositions({
			...positions,
			uploadPosition: 0,
			includeOpacity: !!uploadPosition
		});

		if (timer) {
			clearTimeout(timer)
		}


		setPageDrive({
			prevMove: pageDrive.prevMove || pageDrive.lastMove,
			lastMove: Date.now()
		})

		if (pageDrive.lastMove && (pageDrive.lastMove - Date.now() < 200)) {
			timer = setTimeout(() => {
				setPageDrive({
					prevMove: null,
					lastMove: null
				})

				setPositions({
					...positions,
					uploadPosition
				});
			},400);
		}
	}, [offsetTop, viewportHeight]);

	return (
		<Wrapper showLogo={showLogo}>
			<InnerWrapper>
			{showLogo && <ContainerLogo src={sendmoments_logo}/>}
			<ContainerHealLine>
				{/*<ContainerLine />*/}
				<ContainerEventTypeLogo src={eventIconByType[activeEvent.evt_type_id] || eventIconByType['*']}/>
				<ContainerEventTitle>
					{activeEvent.evt_name}
				</ContainerEventTitle>
				<ContainerButtonUpload showUpload={showUpload} selectMode={selectMode}>
					{
						activeEvent.evt_lock && !isHost ? null: (
							<PhotoContainerTopBar
								download={download}
							>
								{!(selectMode || draggable) ? (
									<>
										{showUpload && showUploadEmptyMobile && (
											<UploadPhotoButton bottomPositionMobile={positions.uploadPosition || 0} includeOpacity={positions.includeOpacity} />
										)}

										<SizeTogglerWrapperDesktop>
											{!activeEvent.evt_cancel && <PhotoSizeToggler sizeBig={22}/>}
										</SizeTogglerWrapperDesktop>

										{!!hasPictures && <GalleryOptionsToggler isThankYouCardGuest={isThankYouCardGuest} showUpload={showUpload} bottomPositionMobile={positions.uploadPosition || 0} includeOpacity={positions.includeOpacity} />}
									</>
								) : selectMode ? (
									<SelectImagesActions bottomPositionMobile={positions.uploadPosition || 0} includeOpacity={positions.includeOpacity}/>
								) : (
									<DragImagesActions bottomPositionMobile={positions.uploadPosition || 0} includeOpacity={positions.includeOpacity}/>
								)}

							</PhotoContainerTopBar>
						)
					}

				</ContainerButtonUpload>

				{!(selectMode || draggable) && (
					<SizeTogglerWrapperTablet>
						{(!activeEvent.evt_lock || isHost ) && !activeEvent.evt_cancel && <PhotoSizeToggler sizeSmall={26}/>}
					</SizeTogglerWrapperTablet>
				)}

			</ContainerHealLine>
			</InnerWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: ${({showLogo}) => showLogo ?  66 : 26}px;
  width: 100%;
	margin-bottom: 30px;
	position: relative;
  z-index: 1000;
`;

const InnerWrapper = styled.div`
	display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
	max-width: ${({theme}) => theme.container.maxWidth}px;
	position: fixed;
  padding-bottom: 20px;
	//top: 50px;
  z-index: 1000;
  background-color: #ffffff;
	${({theme}) => {
		return theme.onMD(`
			padding-bottom: 10px;
		`)
	}}
`;


const ContainerEventTypeLogo = styled.img`
	height: 40px;
	width: auto;
  margin-left: 42px;
	margin-top: -7px;
  //z-index: 5;
  ${({ theme }) =>
          theme.onMD(`
		margin-left: 12px;
  `)}
`;

const ContainerEventTitle = styled.div`
	max-width: 70%;
	margin-left: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font: normal normal normal 18px/24px Roboto;
  font-family: ${({theme}) => theme.font.default};
  letter-spacing: 0;
  //z-index: 5;
`;

const ContainerLogo = styled.img`
	height: 20px;
	margin-top: 20px;
	margin-left: 42px;
	z-index: 5;
  ${({ theme }) => 
					theme.onMD(`
  	margin-top: 12px;
		margin-left: 12px;
  `)}
`;

const ContainerHealLine = styled.div`
	display: flex;
	width: 100%;
	height: 26px;
	position: relative;
	margin-top: 17px;
  background-color: #F7F3ED;
`;


// const maxLeft = 9500/2 - 1820 /2;
// position: fixed;
// top: auto;
// left: -${maxLeft}px;

// const ContainerLine = styled.div`
//
// 	width: 100%;
//   height: 26px;
//   background-color: #F7F3ED;
// 	z-index: 0;
// `

const SizeTogglerWrapperDesktop = styled.div`
	display: flex;
	align-items: center;
	margin-right: 5px;
  ${({theme}) => theme.onMD(`
		display: none;
	`)}
`;

const SizeTogglerWrapperTablet = styled.div`
  display: none;
	${({theme}) => theme.onMD(`
		display:block;
		position: absolute;
		right: 0;
		top: 0;
	`)}
`;

const ContainerButtonUpload = styled.div`
  //width: 100%;
  position: absolute;
	padding-left: 10px;
	right: 0;
	top: ${({showUpload, selectMode}) => showUpload || selectMode ? -22 : -7 }px;
  background: ${({showUpload, selectMode}) => (!showUpload && !selectMode) ? '#fff': 'linear-gradient(to right, transparent 35%, #fff 35%)'};
  ${({ theme }) =>
	theme.onMD(`
    position: fixed;
    padding-left: 0;
    z-index: 2;
    top: 0;
    left: 0;
    right: auto;
    background: none;
    border: none;
  `)}
`;

const PhotoContainerTopBar = styled.div`
  display: ${({download}) => (download ? 'none' : 'flex')};
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${({ showToggler, fullWidth }) => (showToggler && !fullWidth ? '216px' : '')};
  // background-color: ${({ theme }) => theme.palette.white};

  ${({ theme }) =>
	theme.onMD(`
    display: flex;
    padding-left: 0px;
    height: 113px;
  `)}

  ${({ theme }) =>
	theme.onXS(`
      height: 60px;
  `)}
`;

const Title = styled.div`
  display: none;
  margin: 8px auto 5px 35px;
  font-size: 33px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.black};
  font-weight: 400;
  flex-flow: column;
  line-height: 1.2em;
  max-width: ${({ toggler }) => toggler ? 'calc(100% - 206px)' : '100%'};
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
  position: relative;

  div,
  span { overflow: hidden; text-overflow: ellipsis; }

  ${({ theme, toggler }) =>
	theme.onMD(`
    display: inline-flex;
    max-width: calc(100% - ${toggler ? '239px' : '0'});
  `)}

  ${({ theme, toggler }) =>
	theme.onSM(`
    font-size: 21px;
    max-width: calc(100% - ${toggler ? '159px' : '0'});
  `)}

  ${({ theme }) =>
	theme.onXS(`
    font-size: 17px;
    margin-left: 10px;
  `)}
`;

const TitleDate = styled.span`
  font-size: 15px; color: ${({ theme }) => theme.palette.darkGray};

  ${({ theme }) =>
	theme.onXS(`
    font-size: 12px;
  `)}
`;

const ParticipantTitle = styled(Title)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  font-size: 16px;

  ${({ theme }) =>
	theme.onMD(`
    display: flex;
  `)}

  ${({ theme }) =>
	theme.onSM(`
    font-size: 14px;
  `)}
`;

//todo: check better height for guest
const PlaceHolder = styled.div`
  display: none;

  ${({ theme, isGuest, uploading }) =>
	theme.onMD(`
      display: block;
      height: ${ 113 + (isGuest ? 58 : 0) + (uploading ? 72 : 0)}px;
  `)}

  ${({ theme, isGuest, uploading }) =>
	theme.onXS(`
      height: ${ 60 + (isGuest ? 50 : 0) + (uploading ? 72 : 0)}px;
  `)}
`;

const Icon = styled.img`
  width: 38px;
  height: 38px;
  margin-right: 10px;
  flex-shrink: 0;
  cursor: pointer;
  &:hover {
    filter: invert(100%);
  }
`;