import React, {useEffect} from "react";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {toggleModalEnhancedRaw,  toggleModalInfo} from "../../redux/actions/modals";
import {ParticipantsFavoriteModal} from "../PhotoContainer/parts/participantsFavoriteModal";
import {ParticipantsCommentingModal} from "../PhotoContainer/parts/paticipantsCommentingModal";
import {ParticipantsCommentsModal} from "../PhotoContainer/parts/paticipantsCommentsModal";
import trash from "../../static/assets/icons/trash.svg";
import {DeleteInnerModal} from "../PhotoContainer/parts/deleteButton";
import {deleteEventPicture} from "../../redux/actions/gallery";

const colors = ['rgba(255,255,255,0.7)', 'none', '#000000'];
const selectedColors = ['rgba(255,255,255,1)', '#80b4ab', 'none'];
const hoverColors = ['rgba(255,255,255,1)', 'none', '#000000'];
const hoverSelectedColors = ['rgba(255,255,255,1)', '#80b4ab', 'none'];


export const HeartIconSVG = ({isFavorite, isHost, count}) => {
	if (isHost && !count) {
		return (
			<svg id="memory_list" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<g id="Gruppe_18779" data-name="Gruppe 18779" transform="translate(-13 -13)">
					<rect id="Rechteck_24192" data-name="Rechteck 24192" width="24" height="24" transform="translate(13 13)" fill="none"/>
				</g>
				<path id="Pfad_283706" data-name="Pfad 283706" d="M34.29,16.732l-.018-.016a6.06,6.06,0,0,0-8.617.015L25,17.394l-.656-.663a6.064,6.064,0,0,0-8.634,0,6.208,6.208,0,0,0,0,8.7L24.591,34.4a.575.575,0,0,0,.818,0l8.88-8.969A6.2,6.2,0,0,0,34.29,16.732Zm-.818,7.894L25,33.183l-8.473-8.557a5.054,5.054,0,0,1,0-7.087,4.893,4.893,0,0,1,3.5-1.464h0a4.893,4.893,0,0,1,3.5,1.465l1.064,1.075a.577.577,0,0,0,.41.165h0a.57.57,0,0,0,.406-.165l1.066-1.078a4.913,4.913,0,0,1,7,.006A5.051,5.051,0,0,1,33.472,24.626Z" transform="translate(-13 -13)" fill="#d1d3d4"/>
			</svg>

		);
	}

	if (!isFavorite) {
		return (
			<svg id="memory_list" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<g id="Gruppe_18779" data-name="Gruppe 18779" transform="translate(-13 -13)">
					<rect id="Rechteck_24192" data-name="Rechteck 24192" width="24" height="24" transform="translate(13 13)" fill="none"/>
				</g>
				<path fill={colors[3]} id="Pfad_283706" data-name="Pfad 283706" d="M34.29,16.732l-.018-.016a6.06,6.06,0,0,0-8.617.015L25,17.394l-.656-.663a6.064,6.064,0,0,0-8.634,0,6.208,6.208,0,0,0,0,8.7L24.591,34.4a.575.575,0,0,0,.818,0l8.88-8.969A6.2,6.2,0,0,0,34.29,16.732Zm-.818,7.894L25,33.183l-8.473-8.557a5.054,5.054,0,0,1,0-7.087,4.893,4.893,0,0,1,3.5-1.464h0a4.893,4.893,0,0,1,3.5,1.465l1.064,1.075a.577.577,0,0,0,.41.165h0a.57.57,0,0,0,.406-.165l1.066-1.078a4.913,4.913,0,0,1,7,.006A5.051,5.051,0,0,1,33.472,24.626Z" transform="translate(-13 -13)"/>
			</svg>
		)
	}

	return (
		<svg id="memory_list" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

			<g id="Gruppe_18779" data-name="Gruppe 18779" transform="translate(-13 -13)">
				<rect id="Rechteck_24192" data-name="Rechteck 24192" width="24" height="24" transform="translate(13 13)" fill="none"/>
			</g>
			<path id="Pfad_283706" data-name="Pfad 283706" d="M34.29,16.732l-.018-.016a6.06,6.06,0,0,0-8.617.015L25,17.394l-.656-.663a6.064,6.064,0,0,0-8.634,0,6.208,6.208,0,0,0,0,8.7L24.591,34.4a.575.575,0,0,0,.818,0l8.88-8.969A6.2,6.2,0,0,0,34.29,16.732Zm-.818,7.894L25,33.183l-8.473-8.557a5.054,5.054,0,0,1,0-7.087,4.893,4.893,0,0,1,3.5-1.464h0a4.893,4.893,0,0,1,3.5,1.465l1.064,1.075a.577.577,0,0,0,.41.165h0a.57.57,0,0,0,.406-.165l1.066-1.078a4.913,4.913,0,0,1,7,.006A5.051,5.051,0,0,1,33.472,24.626Z" transform="translate(-13 -13)"/>
			<g id="Gruppe_19908" data-name="Gruppe 19908" transform="translate(1.5 2.5)">
				<g id="Gruppe_19907" data-name="Gruppe 19907" clipPath="url(#clip-path)">
					<path id="Pfad_291235" data-name="Pfad 291235" d="M19.379,1.636a5.486,5.486,0,0,0-7.813,0l0,0L10.5,2.712,9.435,1.636a5.488,5.488,0,0,0-7.816,0,5.622,5.622,0,0,0,0,7.895l1.065,1.076L10.5,18.5l7.816-7.894L19.38,9.53a5.618,5.618,0,0,0,0-7.891l0,0Z"/>
				</g>
			</g>
		</svg>
	)
};

export const LabelIconSVG = ({labelExist, showGray}) => {
	if (showGray) {
		return (
			<svg id="comment" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<defs>
					<clipPath id="clip-path">
						<rect id="Rechteck_25150" data-name="Rechteck 25150" width="24" height="24" fill="none"/>
					</clipPath>
				</defs>
				<g id="Gruppe_19839" data-name="Gruppe 19839" clipPath="url(#clip-path)">
					<path id="Pfad_291184" data-name="Pfad 291184" d="M8.5,22.075a.578.578,0,0,1-.575-.575V18.033a.959.959,0,0,0-.958-.958H4.034a2.111,2.111,0,0,1-2.109-2.108V4.033A2.111,2.111,0,0,1,4.034,1.925H19.967a2.112,2.112,0,0,1,2.109,2.108V14.967a2.112,2.112,0,0,1-2.109,2.108H14.593a.961.961,0,0,0-.645.249l-5.061,4.6a.581.581,0,0,1-.387.149m-4.466-19a.959.959,0,0,0-.958.958V14.967a.959.959,0,0,0,.958.958H6.967a2.112,2.112,0,0,1,2.109,2.108V20.2l4.1-3.728a2.11,2.11,0,0,1,1.418-.547h5.374a.959.959,0,0,0,.958-.958V4.033a.959.959,0,0,0-.958-.958Z" fill="#d1d3d4"/>
				</g>
			</svg>
		)
	}

	if (labelExist) {
		return (
			<svg id="comment" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
				<defs>
					<clipPath id="clip-path">
						<rect id="Rechteck_25150" data-name="Rechteck 25150" width="28" height="28" transform="translate(-0.078 -0.078)" stroke="rgba(0,0,0,0)" strokeWidth="1"/>
					</clipPath>
				</defs>
				<g id="Gruppe_19839" data-name="Gruppe 19839" transform="translate(0.078 0.078)" clipPath="url(#clip-path)">
					<path id="Differenzmenge_151" data-name="Differenzmenge 151" d="M21907.547,15567.3a.665.665,0,0,1-.27-.061.658.658,0,0,1-.4-.6v-4.022a1.115,1.115,0,0,0-1.111-1.117h-3.4a2.449,2.449,0,0,1-2.449-2.442v-12.683a2.452,2.452,0,0,1,2.449-2.448h18.486a2.449,2.449,0,0,1,2.443,2.448v12.683a2.445,2.445,0,0,1-2.443,2.442h-6.238a1.117,1.117,0,0,0-.748.292l-5.871,5.337A.671.671,0,0,1,21907.547,15567.3Zm-2.576-13.265a.576.576,0,1,0,0,1.149h12.865a.576.576,0,1,0,0-1.149Zm0-4.925a.576.576,0,1,0,0,1.15h12.865a.576.576,0,1,0,0-1.15Z" transform="translate(-21897.688 -15541.688)" stroke="rgba(0,0,0,0)" strokeWidth="1"/>
				</g>
			</svg>

		);
	}

	return (
		<svg id="comment" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<defs>
				<clipPath id="clip-path">
					<rect id="Rechteck_25150" data-name="Rechteck 25150" width="24" height="24" fill="none"/>
				</clipPath>
			</defs>
			<g id="Gruppe_19839" data-name="Gruppe 19839" clipPath="url(#clip-path)">
				<path id="Pfad_291184" data-name="Pfad 291184" d="M8.5,22.075a.578.578,0,0,1-.575-.575V18.033a.959.959,0,0,0-.958-.958H4.034a2.111,2.111,0,0,1-2.109-2.108V4.033A2.111,2.111,0,0,1,4.034,1.925H19.967a2.112,2.112,0,0,1,2.109,2.108V14.967a2.112,2.112,0,0,1-2.109,2.108H14.593a.961.961,0,0,0-.645.249l-5.061,4.6a.581.581,0,0,1-.387.149m-4.466-19a.959.959,0,0,0-.958.958V14.967a.959.959,0,0,0,.958.958H6.967a2.112,2.112,0,0,1,2.109,2.108V20.2l4.1-3.728a2.11,2.11,0,0,1,1.418-.547h5.374a.959.959,0,0,0,.958-.958V4.033a.959.959,0,0,0-.958-.958Z"/>
			</g>
		</svg>
	)
};

const DownloadIconSVG = (props) => (
	<svg id="download" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<path id="Pfad_283715" data-name="Pfad 283715" d="M35.938,24.743a3.942,3.942,0,0,0-3.777-3.014.69.69,0,0,1,0-.107,5.69,5.69,0,0,0-1.884-4.815,5.969,5.969,0,0,0-5.027-1.274,5.887,5.887,0,0,0-3.992,2.991l-.113-.033A5.4,5.4,0,0,0,15.62,20a6.433,6.433,0,0,0-1.448,6.5A5.767,5.767,0,0,0,20,30.575h1a.575.575,0,0,0,0-1.15H20a4.633,4.633,0,0,1-4.741-3.3,5.276,5.276,0,0,1,1.189-5.328A4.226,4.226,0,0,1,20.831,19.6a1.873,1.873,0,0,1,.24.08.842.842,0,0,0,1.124-.465A4.709,4.709,0,0,1,25.47,16.66a4.819,4.819,0,0,1,4.053,1.017,4.632,4.632,0,0,1,1.488,3.888,1.058,1.058,0,0,0,.914,1.248A2.984,2.984,0,0,1,34.818,25a3.546,3.546,0,0,1-2.405,4.3c-.081.021-.163.041-.242.054a4.3,4.3,0,0,1-.721.065l-1.412.006a.575.575,0,0,0,0,1.15h0l1.415-.006a5.634,5.634,0,0,0,.913-.082c.115-.021.231-.048.343-.078A4.692,4.692,0,0,0,35.938,24.743Z" transform="translate(-13 -13)"/>
		<path id="Pfad_283716" data-name="Pfad 283716" d="M28.907,32.594a.575.575,0,0,0-.814,0l-2.018,2.017V25.5a.575.575,0,0,0-1.15,0v9.111l-2.018-2.017a.575.575,0,0,0-.814.812l3,3a.575.575,0,0,0,.814,0l3-3A.575.575,0,0,0,28.907,32.594Z" transform="translate(-13 -13)"/>
		<g id="Gruppe_18788" data-name="Gruppe 18788" transform="translate(-13 -13)">
			<rect id="Rechteck_24197" data-name="Rechteck 24197" width="24" height="24" transform="translate(13 13)" fill="none"/>
		</g>
	</svg>
);

const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
  position: relative;
	height: ${ ({height}) => height }px;
	width: ${ ({width}) => width }px;
	max-width: ${ ({maxWidth}) => maxWidth || 24 }px;;
  cursor: ${ ({disabled}) => disabled ? 'default' : 'pointer' };
	margin: auto 8px;
	font-size: 0;
	overflow: hidden;
  svg {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const Counter = styled.div`
	display: ${({count}) => count ? "block": "none"};
	margin: auto 5px auto -5px;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
`;

export const HeartIcon = ({ eventID, photoID, isFavorite, onToggle, isHost, count, className, innerComponent }) => {
	const dispatch = useDispatch();
	const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/${isFavorite ? 'remove_favorite' : 'add_favorite'}/${photoID}`;

	const toggleFavoriteRequest = (callback) => {
		if (!isHost) {
			fetch(url, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				}
			})
				.then(response => response.json())
				.then(data => {
					if (data.success) {
						onToggle(eventID, photoID, isFavorite);
					} else if (!data.success) {
						showMessage(data.message);
					}
				})
				.catch(err => console.log(err));
		} else {
			toggleModalEnhancedRaw(dispatch, {
					children: <ParticipantsFavoriteModal eventID={eventID} photoID={photoID} />,
					fullOnMobile: true
			})
		}
	};

	const showMessage = message => {
		toggleModalInfo(dispatch, {
			title: isFavorite ? 'Bild konnte nicht aus Favoriten entfernt werden' : 'Bild konnte nicht zu Favoriten hinzugefügt werden',
			message,
		});
	};

	if (innerComponent) {
		return <span onClick={isHost && !count ? null : toggleFavoriteRequest} > {innerComponent} </span>
	}

	return (
		<>
			<Wrapper
				width={24}
				height={24}
				onClick={isHost && !count ? null : toggleFavoriteRequest}
				count={count}
			>
				<HeartIconSVG isFavorite={isFavorite} isHost={isHost} count={count} />

			</Wrapper>
			<Counter count={count}>{count}</Counter>
		</>
	)
};

export const LabelIcon = (props) => {
	const dispatch = useDispatch();
	const {count, labelExist, myComment, isHost, photoID, eventID, thumbnail, ownerName, innerComponent} = props;

	const openModalGuest = () => {
		toggleModalEnhancedRaw(dispatch, {
			children: <ParticipantsCommentingModal {...props} />,
			fullOnMobile: true,
			reqSetTrueHeight: true
		})
	}

	const openModalHost = () => {
		toggleModalEnhancedRaw(dispatch, {
			children: <ParticipantsCommentsModal {...props} />,
			fullOnMobile: true
		})
	}

	const toggle = isHost && count ? openModalHost : isHost && !count  ? null : openModalGuest;

	if (innerComponent) {
		return <span onClick={toggle} > {innerComponent} </span>
	}

	return (
		<>
			<Wrapper
				width={24}
				height={24}
				count={count}
				onClick={toggle}
			>
				<LabelIconSVG labelExist={myComment && !isHost} showGray={isHost && !count} />
			</Wrapper>
			<Counter count={count}>{count}</Counter>
		</>
	)
};

export const DownloadIconBottom = ({eventID, awsID}) => {
	const url = `${process.env.REACT_APP_BACKEND_DOMAIN ||
	'/'}api/photo/download/${eventID}/${awsID}`;

	return (
		<Wrapper
			width={24}
			height={24}
		>
			<a key="download-link" href={url} target="_blank" download>
				<DownloadIconSVG />
			</a>
		</Wrapper>
	)
};

export const DeleteIcon = ({ eventID, photoID, onSuccess, onError, iconSvg, btnParams, onDeleteCallback }) => {
	const dispatch = useDispatch();
	const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/delete`;

	const deletePhotoRequest = () => {
		fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				eventID,
				awsID: photoID,
			}),
		})
			.then(response => response.json())
			.then(data => {
				if (data.success) {
					if (onDeleteCallback) onDeleteCallback();
					closeModalEnhancedRaw();
					dispatch(deleteEventPicture(eventID, photoID))
				} else if (!data.success && typeof onError === 'function') {
					onError(data.message);
				}
			})
			.catch(err => console.log(err));
	};

	const deletePrompt = () => {
		toggleModalEnhancedRaw(dispatch, {
			children: (
				<DeleteInnerModal
					deletePhotoRequest={deletePhotoRequest}
					closeModalEnhancedRaw={closeModalEnhancedRaw}
				/>
			),
		});
	};

	const showMessage = message => {
		toggleModalInfo(dispatch, {
			title: 'BILD ENDGÜLTIG LÖSCHEN',
			message,
		});
	};
	const closeModalEnhancedRaw = () => {
		toggleModalEnhancedRaw(dispatch, false);
	};

	return (
		<Wrapper
			width={btnParams && btnParams.width || 24}
			height={btnParams && btnParams.width ||24}
			maxWidth={btnParams && btnParams.maxWidth || 24}
			onClick={() => {
				deletePrompt()
			}}
			>
		 <img src={iconSvg || trash} alt={'delete_image'} />
		</Wrapper>
	)
}