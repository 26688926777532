import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ArrowIcon } from '../icons/ArrowIcon';
import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";

export const SelectBox = props => {
  const options = props.options && props.options.length ? props.options : null;
  const onChange = props.onValueChange;
  const {isMobile} = useWindowWidth();
  const [isOpened, setOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options && onChange ? (options.filter(o => props.value ? o.value === props.value : o.selected)[0] || options[0]) : null);
  const scrollTop = selectedOption ? options.indexOf(selectedOption) * (isMobile ? 45 : 62) : 0;
  const optionsListRef = React.createRef();
  const wrapperRef = useRef();

  const closeSelect = (e) => {
    // if (e.target.parentElement !== wrapperRef.current || e.target.parentElement.parentElement !== wrapperRef.current) {
    if (e.target.parentElement !== wrapperRef.current) {
      setOpened(false);
    }
  };

  useEffect(() => {
    if (optionsListRef.current) {
      optionsListRef.current.scrollTop = scrollTop;
    }

    document.documentElement.addEventListener('click', closeSelect);

    return () => {
      document.documentElement.removeEventListener('click', closeSelect)
    };
  }, []);

  if (options && onChange) {
    return (
      <Wrapper opened={isOpened} ref={wrapperRef}>
        <Value opened={isOpened} onClick={()=>{
          setOpened(true);
        }}>
          {selectedOption.label}
        </Value>
        <IconWrapper opened={isOpened}>
          <ArrowIcon />
        </IconWrapper>
        <OptionsList opened={isOpened} scrollTop={scrollTop} ref={optionsListRef} optionsNumber={options.filter(o => !o.placeholder).length}>
          {options.filter(o => !o.placeholder).map(o => (
            <Option
              value={o.value}
              selected={o === selectedOption}
              key={o.value}
              onClick={!o.disabled ? () => {
                setSelectedOption(o);
                onChange(o);
              } : null}
            >
              {o.label}
            </Option>
          ))}
        </OptionsList>
      </Wrapper>
    );
  } else {
    return null;
  }
};

const Wrapper = styled.div`
  width: 100%;
  cursor: default;
  margin-top: 15px;
  overflow: ${props => (props.opened ? '' : 'hidden')};
  position: relative;
  z-index: ${props => (props.opened ? '5' : '0')};
  select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.001;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
  ${({ theme }) =>
    theme.onMD(`
    margin-top: 10px;
  `)}
`;

const Value = styled.div`
  text-align: left;
  padding: 0 17px;
  width: 100%;
  max-width: 100%;
  height: 62px;
  line-height: 58px;
  border: 2px solid ${({ theme, opened }) => (opened ? 'rgba(128, 180, 171, 0.5)' : theme.palette.lightGray)};
  background-color: ${({ theme }) => theme.palette.white};
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.default};
  pointer-events: ${({ opened }) => opened ? 'none' : 'auto'};
  position: relative;
  z-index: 2;
  ${({ theme }) =>
    theme.onMD(`
    border-width: 1px;
    height: 45px;
    line-height: 45px;
    border-width: 1px;
    font-size: 14px;
    padding: 0 13px;
  `)}
`;

const IconWrapper = styled.div`
  position: absolute;
  z-index: 3;
  pointer-events: none;
  top: 2px;
  right: 2px;
  font-size: 0;
  background: #FFFFFF;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%);

  svg {
    width: 58px;
    height: 58px;
    padding: 10px;
    transform: ${ ({ opened }) => opened ? 'rotate(180deg)' : 'none' };

    path {
      fill: ${ ({ opened }) => opened ? 'rgba(128, 180, 171, 0.5)' : '' };
    }
  }

  ${({ theme }) =>
    theme.onMD(`
    top: 1px;
    right: 1px;

    svg {
      width: 43px;
      height: 43px;
    }
  `)}
`;

const OptionsList = styled.div`
  text-align: left;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.default};
  border: 2px solid ${({ theme, opened }) => (opened ? 'rgba(128, 180, 171, 0.5)' : theme.palette.lightGray)};
  border-top: 0;
  ${({ opened }) => opened ? '' : 'margin-top: -2px'};
  background-color: ${({ theme }) => theme.palette.white};
  position: relative;
  
  left: 0;
  width: 100%;
  box-sizing: border-box;
  max-height: ${({ opened, optionsNumber }) => opened ? optionsNumber * 62 : 0}px;
  transition: max-height .5s ease-in-out;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* ${({ opened }) => (!opened ? 'display: none;' : '')}; */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  ${({ theme, opened }) =>
    theme.onMD(`
    border-width: 1px;
    font-size: 14px;
    ${opened ? '' : 'margin-top: -1px'};
  `)}
`;

const Option = styled.div`
  cursor: pointer;
  padding: 0 17px;
  height: 62px;
  line-height: 58px;
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  background-color: ${({ selected, theme }) => (selected ? 'rgba(128,180,171,0.2)' : theme.palette.white)};

  &:hover {
    background-color: rgba(128, 180, 171, 0.2);
  }
  ${({ theme }) =>
    theme.onMD(`
    height: 45px;
    line-height: 45px;
    padding: 0 13px;
  `)}
`;
