import {
  MODAL_JOIN,
  MODAL_GUEST,
  MODAL_EVENT,
  MODAL_INVITATION,
  MODAL_FORGOT_PASSWORD,
  MODAL_CODE_ACTIVATED,
  MODAL_INFO,
  MODAL_IMAGES_SELECTED, MODAL_FAST_LOGIN, MODAL_ENHANCED, MODAL_PROMPT,
} from '../types';

export const modalJoin = (state = false, action) => {
  switch (action.type) {
    case MODAL_JOIN:
      return action.payload;
    default:
      return state;
  }
};
export const modalEnhanced = (state = false, action) => {
  switch (action.type) {
    case MODAL_ENHANCED:
      return action.payload;
    default:
      return state;
  }
};

export const promptEnhanced = (state = false, action) => {
  switch (action.type) {
    case MODAL_PROMPT:
      return action.payload;
    default:
      return state;
  }
};

export const modalEvent = (state = false, action) => {
  switch (action.type) {
    case MODAL_EVENT:
      return action.payload;
    default:
      return state;
  }
};
export const modalInvitation = (state = false, action) => {
  switch (action.type) {
    case MODAL_INVITATION:
      return action.payload;
    default:
      return state;
  }
};
export const modalForgotPassword = (state = false, action) => {
  switch (action.type) {
    case MODAL_FORGOT_PASSWORD:
      return action.payload;
    default:
      return state;
  }
};
export const modalCodeActivated = (state = false, action) => {
  switch (action.type) {
    case MODAL_CODE_ACTIVATED:
      return action.payload;
    default:
      return state;
  }
};
export const modalInfo = (state = false, action) => {
  switch (action.type) {
    case MODAL_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const modalGuest = (state = false, action) => {
  switch (action.type) {
    case MODAL_GUEST:
      return action.payload;
    default:
      return state;
  }
};

export const modalFastLogin = (state = false, action) => {
	switch (action.type) {
		case MODAL_FAST_LOGIN:
			return action.payload;
		default:
			return state;
	}
};

export const modalImagesSelected = (state = false, action) => {
  switch (action.type) {
    case MODAL_IMAGES_SELECTED:
      return action.payload;
    default:
      return state;
  }
};

export const modalVisible = (state = false, action) => {
  switch (action.type) {
    case MODAL_INVITATION:
    case MODAL_EVENT:
    case MODAL_JOIN:
    case MODAL_FORGOT_PASSWORD:
    case MODAL_CODE_ACTIVATED:
    case MODAL_INFO:
    case MODAL_GUEST:
		case MODAL_FAST_LOGIN:
    case MODAL_ENHANCED:
    case MODAL_PROMPT:
    case MODAL_IMAGES_SELECTED:
      return !!action.payload || !!action.open;
    default:
      return state;
  }
};
