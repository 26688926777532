import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { validate } from '../../utils/validateForms';
import { SalutationDropBox } from './parts/salutationDropBox';
import { InputContainer } from './parts/inputContainer';
import { FullButton } from '../common/fullBtn';
import { toggleModalInfo } from '../../redux/actions/modals';
import { ErrorMessage } from '../common/errorMessage';
import { getCurrentUser } from '../../redux/actions/log';

const Title = styled.div`  
  margin-bottom: 40px;
  font-size: 21px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  ${({ theme }) =>
    theme.onMD(`
    font-size: 15px;
  `)}
`;

export const EditUserForm = ({ salutation, firstname, lastname, company }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    salutation,
    firstname,
    lastname,
    ...(salutation === 'firma' ? { company } : null)
  });
  const [errorMessages, setErrorMessages] = useState({
    firstname: '',
    lastname: '',
    company: '',
  });
  const isFirma = data => data.salutation === 'firma';
  const changeData = (name, value) => {
    const newData = {
      ...data,
    };
    newData[name] = value;
    setData(newData);
  };
  const onSalutationCahnge = (value) => {
    const newData = {
      ...data,
      salutation: value
    };

    if (value === 'firma') {
      newData['company'] = newData['company'] || '';
    } else {
      delete newData['company'];
    }

    setData(newData);
  };

  const isValid = (key, value) => {
    let valid = true;

    const dataToCheck = { salutation: data.salutation, firstname: data.firstname.trim(), lastname: data.lastname.trim() };
    const result = key ? validate({[key]: value.trim()}) :  validate(dataToCheck);
    const messages = { ...errorMessages };
    const keysToChek = key ? [key] : Object.keys(dataToCheck);

    keysToChek.forEach(key => {
      if (result[key]) {
        valid = valid && result[key].success;
        messages[key] = t(result[key].error) || '';
      }
    });

    setErrorMessages({ ...messages, general: ''});

    return valid;
  }

  const submitForm = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'auth/personal_info'}`;

    if (isValid()) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          salutation: data.salutation, 
          firstname: data.firstname.trim(), 
          lastname: data.lastname.trim(),
          ...(data.salutation === 'firma' ? { firmname: data.company.trim() } : null)
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            onChangeSuccess();
          } else {
            setErrorMessages({ ...errorMessages, general: t(data.error)});
          }
        })
        .catch(err => console.log(err));
    }
  };

  const onChangeSuccess = () => {
    getCurrentUser(dispatch, null).then(() => {
      toggleModalInfo(dispatch, {
        title: 'Vielen Dank!',
        message: 'Wir haben die Änderung Ihrer Daten übernommen.'
      });
    });
  }

  return (
    <>
      <Title>{'Ihre persönlichen Daten'}</Title>
      
      <SalutationDropBox value={data.salutation} onChange={onSalutationCahnge} />

      { isFirma(data) ? <InputContainer 
        key="company" 
        label={`${t("company_name")}*`} 
        value={data.company || ''}
        onChange={({ target: { value } }) => changeData('company', value) }
        onBlur={ ({ target: { value } }) => isValid('company', value) }
        placeholder={t("company_name")}
        error={errorMessages.company}
      /> : null}

      <InputContainer 
        name="first_name" 
        label={`${t("first_name")}*`} 
        value={data.firstname}
        onChange={({ target: { value } }) => changeData('firstname', value) }
        onBlur={ ({ target: { value } }) => isValid('firstname', value) }
        placeholder={t("first_name")}
        error={errorMessages.firstname}
      />

      <InputContainer 
        name="last_name" 
        label={`${t("last_name")}*`} 
        value={data.lastname}
        onChange={({ target: { value } }) => changeData('lastname', value) }
        onBlur={ ({ target: { value } }) => isValid('lastname', value) }
        placeholder={t("last_name")}
        error={errorMessages.lastname}
      />

      {errorMessages.general ? <ErrorMessage>{ errorMessages.general }</ErrorMessage> : null}

      <FullButton genWith="386px"
        mt={30}
        btnColor="mintGreen"
        text={t('save')}
        click={submitForm}></FullButton>
    </>
  );
};