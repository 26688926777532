import { UPLOAD_CONTAINER, UPDATE_CONTAINER, REMOVE_CONTAINER, PROCESS_CONTAINER } from '../types';

const containerUpdate = data => ({ type: UPLOAD_CONTAINER, payload: data });

export const uploadContainer = (dispatch, data) => {
  dispatch({ type: UPLOAD_CONTAINER, payload: data });
};

export const updateContainer = (dispatch, eventID, index, newState, done = false, abort) => {
  dispatch({ type: UPDATE_CONTAINER, payload: { eventID, index, newState, done, abort } });
};

export const removeContainer = (dispatch, eventID) => {
  dispatch({ type: REMOVE_CONTAINER, payload: { eventID } });
};

export const processContainer = (dispatch, eventID) => {
  dispatch({ type: PROCESS_CONTAINER, payload: { eventID } });
};
