import { PARTICIPANTS_LIST, REMOVE_PARTICIPANT, ADD_PARTICIPANT_GUEST, ADD_PARTICIPANT_MANAGER, CURRENT_PARTICIPANT } from '../types';

export const addParticipantGuest = (dispatch, participant) => {
  if (participant) {
    dispatch({ type: ADD_PARTICIPANT_GUEST, payload: participant });
  }
};

export const addParticipantManager = (dispatch, participant) => {
  if (participant) {
    dispatch({ type: ADD_PARTICIPANT_MANAGER, payload: participant });
  }
};

export const removeParticipant = (dispatch, participant) => {
  if (participant) {
    dispatch({ type: REMOVE_PARTICIPANT, payload: participant });
  }
};

export const setParticipantList = (dispatch, participantList) => {
  if (typeof participantList === 'object') {
    dispatch({ type: PARTICIPANTS_LIST, payload: participantList });
  }
};

export const setCurrentParticipant = (dispatch, participant) => {
  dispatch({ type: CURRENT_PARTICIPANT, payload: participant });
};

const fetchParticipantsList = (eventId) => {
  return async dispatch => {
    const participantsUrl = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}${`api/event/guests/${eventId}`}`;
    const participants = await fetch(participantsUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setParticipantList(dispatch, data.participants);
        } else {
          throw new Error('Fetch was not successful');
        }
      })
      .catch(err => console.log(err));

    return participants;
  }
}

export const getParticipants = (eventId) => {
  return dispatch => dispatch(fetchParticipantsList(eventId));
};
