import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {toggleModalPromptRaw} from '../../redux/actions/modals';
import { DefaultModal } from './modal';



export const PromptModalRaw = () => {
	const dispatch = useDispatch();
	const modalState = useSelector(state => state.promptEnhanced);

	const changeModalState = state => toggleModalPromptRaw(dispatch, state);

	const {children, showCloseButton, fullOnMobile} = modalState;

	return (
		<DefaultModal
			modalState={!!modalState}
			removeCloseBtn={!showCloseButton}
			modalLabel="prompt_enhanced"
			onRequestClose={() => changeModalState(false)}
			fullOnMobile={fullOnMobile}
		>
			{children}
		</DefaultModal>
	);
};