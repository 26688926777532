import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import de from 'date-fns/locale/de';
import dateFnsFormat from 'date-fns/format';
import { useTranslation } from 'react-i18next';

import { toggleModalForgotPassword } from '../redux/actions/modals';

import { ForgotPasswordModal } from '../components/Modals/forgotPassword';
import { LoginForm } from '../components/forms/loginForm';
import { RegisterForm } from '../components/forms/registerForm';
import { joinEventCode } from '../components/common/activateCode';

import sm_logo_big from '../static/assets/images/sendmoments_big.png';
import checkmark_green from '../static/assets/icons/checkmark_green.svg';
import { SocialLink } from '../components/common/socialLink';
import { Separator } from '../components/common/separator';

const Logo = styled.img`
  width: 90%;
  margin: auto 0 4vh;
  ${({ theme }) =>
    theme.onSM(`
    margin: auto 0 3vh;
  `)}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 386px;
  max-width: 100%;
  min-height: 100%;
  padding: 20px 0;
  ${({ theme }) =>
    theme.onSM(`
    justify-content: flex-end;
    padding: 30px;
  `)}
`;

const MidLine = styled.div`
  width: 100%;
  margin: 3vh 0 ${({ mb }) => (typeof mb !== 'undefined' ? mb : '3vh')};
  border-top: 2px solid ${({ theme }) => theme.palette.lightGray};
  position: relative;
  font-size: 0;

  span {
    padding: 10px;
    background: #ffffff;
    position: absolute;
    font-size: 18px;
    color: ${({ theme }) => theme.palette.lightGray};
    top: -2px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${({ theme }) =>
    theme.onSM(`
      border-width: 1px;
      margin: 2vh 0 ${({ mb }) => (typeof mb !== 'undefined' ? mb : '2vh')};

      span {
        font-size: 12px;
        top: -1px;
      }
  `)}
`;

const ForgotPassword = styled.div`
  font-size: 15px;
  color: ${({ theme: { palette } }) => palette.lightGray};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  ${({ theme }) =>
    theme.onSM(`
    font-size: 14px;
  `)}
`;

const InfoText = styled.div`
  font-size: 17px;
  line-height: 1.8em;
  color: ${({ theme: { palette } }) => palette.dark};
  text-align: center;
  flex-shrink: 0;
  margin: 0 0 ${({ mb }) => (typeof mb !== 'undefined' ? mb : '4vh')};
  ${({ theme, mb }) =>
    theme.onSM(`
    font-size: 14px;
    margin: 0 0 ${typeof mb !== 'undefined' ? mb : '3vh'};
    line-height: 1.2em;
  `)}
`;

const Code = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0;
  font-size: 26px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.mintGreen};

  img {
    width: 26px;
    height: 26px;
    margin: 0 10px 0 0;
  }

  ${({ theme }) =>
    theme.onMD(`
    margin: 0;
    font-size: 19px;
    img { width: 20px; height: 20px;}
  `)}
  ${({ theme }) =>
    theme.onXS(`
    margin: 0;
  `)}
`;

const NoAccount = styled.div`
  color: ${({ theme }) => theme.palette.lightGray};
  margin-bottom: auto;
  margin-top: 10px;
`;

const HasAccount = styled.div`
  color: ${({ theme }) => theme.palette.lightGray};
  margin-bottom: auto;
`;

export const LoginPage = ({ isRegister }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const changeModalForgotPassword = state => toggleModalForgotPassword(dispatch, state);

  const temporaryEventCode = Cookies.get('temporary_event');
  const [temporaryEvent, setEvent] = useState(null);
  const info =
    temporaryEventCode && temporaryEvent ? (
      <>
        <Code>
          <img src={checkmark_green} /> {temporaryEventCode}
        </Code>
        {temporaryEvent.name},{' '}
        {dateFnsFormat(new Date(temporaryEvent.date), 'EEEE d. LLLL y', { locale: de })}
        <br />
        { t('to_continue_log_into_sendmoments_up') }
      </>
    ) : (
      t('to_continue_log_into_sendmoments_up')
    );

  useEffect(() => {
    if (temporaryEventCode) {
      joinEventCode(
        temporaryEventCode,
        data => {
          if (data.event) {
            setEvent(data.event);
          }
        },
        null,
        false,
      );
    }
  }, [temporaryEventCode]);

  const login = () => (
    <>
      <Wrapper>
        <Logo src={sm_logo_big} alt="logo" draggable={false} />
        <InfoText>{info}</InfoText>


        <LoginForm
          forgotPassword={
            <ForgotPassword onClick={() => changeModalForgotPassword(true)}>
              { t('forgot_password?') }
            </ForgotPassword>
          }
        />

        <Separator />

        <SocialLink google={true} href="/auth/google">
          {`${ t('continue_with') } Google`}
        </SocialLink>
        <SocialLink facebook={true} noMB={true} href="/auth/facebook">
          {`${ t('continue_with') } Facebook`}
        </SocialLink>

        <Separator />
        <NoAccount>
          { t('new_customer?') } <a href="/register">{ t('join_now') }</a>
        </NoAccount>
      </Wrapper>
      <ForgotPasswordModal />
    </>
  );

  const registration = () => (
    <Wrapper>
      <Logo src={sm_logo_big} alt="logo" draggable={false} />
      <InfoText>{info}</InfoText>
      {/*  <SocialLink google={true} href="/auth/google">*/}
      {/*    {`${ t('register_with') } Google`}*/}
      {/*  </SocialLink>*/}
      {/*  <SocialLink facebook={true} href="/auth/facebook">*/}
      {/*    {`${ t('register_with') } Facebook`}*/}
      {/*  </SocialLink>*/}
      {/*<Separator>{t('or')}</Separator>*/}
      <RegisterForm />
      <Separator />
      <HasAccount>
        { t('have_an_account?') } <a href="/login">{ t('login_now') }</a>
      </HasAccount>
    </Wrapper>
  );

  return !isRegister ? login() : registration();
};
