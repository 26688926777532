import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { PhotoListTopbar } from '../components/PhotoContainer/PhotoListTopbar';
import { useHistory } from 'react-router';
import { showSidebar} from '../redux/actions/sidebar';
import { showFavorites } from '../redux/actions/gallery';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../components/common/actionButton';
import heart_icon from '../static/assets/icons/heart.svg';
import all_images from '../static/assets/icons/all_images.svg';
import {toggleModalFastLogin, toggleModalInfo} from "../redux/actions/modals";
import {LoginForm} from "../components/forms/loginForm";

const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  overflow: auto;
  flex-grow: 1;
  background-color: ${({ theme: { palette } }) => palette.white};
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

const Content = styled.div`
  font-family: ${({ theme }) => theme.font.default};
  font-size: 18px;
  padding: 10px 20px;
  line-height: 1.5em;

  p { margin: 0 20px 20px; }

  ${({ theme }) =>
    theme.onMD(`
    font-size: 15px;
  `)}
`;

export const CreateProductPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.currentUser);
    const participants = useSelector(state => state.participantsList);
    const currentParticipant = participants && currentUser ? participants.find(p => p.id === currentUser.id) : null;
    const isManager = currentParticipant ? currentParticipant.evp_role === 'manager' : false;
    const activeEvent = useSelector(state => state.activeEvent);
    const allEventPictures = useSelector(state => state.eventPictures);
    const allFavoritePictures = useSelector(state => state.favoritePictures);
    const changeSidebarState = state => showSidebar(dispatch, state);
    const eventLocation = isManager ? `/event/${activeEvent.evt_code}` : '/event';
    const isGuest = currentUser ? currentUser.cs_role === 'guest' : false;

    const openFastLoginPopup = () => {
        toggleModalFastLogin(dispatch, {open: true});
    };

    return (
        <Wrapper>
            {activeEvent ? (
                <>
                    <PhotoListTopbar title={t('create_a_photo_product')} onGoBack={() => {
                        history.push(eventLocation);
                        changeSidebarState(true);
                    }} />
                    <Content>
                        <p>
                            Mit den Fotoprodukten von sendmoments haben Sie Ihre schönsten Momente immer bei sich. Gestalten Sie Fotobücher, Wandbilder, Kalender und vieles mehr. Wählen Sie einfach die gewünschten Bilder aus, gehen Sie am unteren Bildschirmrand auf die Weiter-Funktion und wählen dann die Option „Fotoprodukt gestalten“ aus. Sobald Sie sich für ein Produkt entschieden haben, warten Ihre Fotos bereits im Gestaltungsmodus auf Sie.
                        </p>
                        <ActionButton
                            iconSrc={all_images}
                            iconProps={{ width: 45, height: 37 }}
                            mobIconProps={{ width: 33, height: 27 }}
                            text={'Aus allen Fotos auswählen'}
                            click={() => {
                                history.push(eventLocation);
                                changeSidebarState(false);
                            }}
                        />
                        <ActionButton
                            iconSrc={heart_icon}
                            iconProps={{ width: 45, height: 37 }}
                            mobIconProps={{ width: 33, height: 27 }}
                            text={'Aus meinen Favoriten auswählen'}
                            click={() => {
                                if (isGuest) {
                                    openFastLoginPopup();
                                } else {
                                    history.push(eventLocation);
                                    changeSidebarState(false);
                                    dispatch(showFavorites(true));
                                }
                            }}
                        />
                    </Content>
                </>
            ) : null}
        </Wrapper>
    );
};
