import { SIDEBAR_OPEN, SIDEBAR_CLOSE, FACE_MAIN, FACE_EVENTS, FACE_GUESTS, FACE_CUSTOMER } from '../types';

const closeSidebar = { type: SIDEBAR_CLOSE, payload: false };
const openSidebar = { type: SIDEBAR_OPEN, payload: true };

const faceMain = { type: FACE_MAIN, payload: 'main' };
const faceEvents = { type: FACE_EVENTS, payload: 'events' };
const faceGuest = { type: FACE_GUESTS, payload: 'guests' };
const faceCustomer = { type: FACE_CUSTOMER, payload: 'customer' };

export const showSidebar = (dispatch, getState) => {
  if (getState) {
    dispatch(openSidebar);
  } else {
    dispatch(closeSidebar);
  }
};

export const navigateToFace = (dispatch, getState) => {
  if (getState === 'main') {
    dispatch(faceMain);
  } else if (getState === 'events') {
    dispatch(faceEvents);
  } else if (getState === 'guests') {
    dispatch(faceGuest);
  } else if (getState === 'customer') {
    dispatch(faceCustomer);
  }
};
