const fallbackCopyTextToClipboard = (text, onSuccsess, onError) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    textArea.style.opacity = "0.001";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        if (document.execCommand('copy')) { 
            onSuccsess(); 
        } else {
            onError();
        }
    } catch (err) {
        onError(err);
    }

    document.body.removeChild(textArea);
}

export const copyToClipboard = (text, onSuccsess = () => {}, onError = () => {}) => {
    return () => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text, onSuccsess, onError);
            return;
        }
        navigator.clipboard.writeText(text).then(() => {
            onSuccsess();
        }, (err) => {
            onError(err);
        });
    }
}