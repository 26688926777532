import {
  GALLERY_BIG,
  GALLERY_SMALL,
  EVENT_PICTURES,
  REQUEST_EVENT_PICTURES,
  ADD_EVENT_PICTURE,
  DELETE_EVENT_PICTURE,
  NEW_EVENT_PICTURES,
  LOAD_EVENT_PICTURE,
  ERROR_EVENT_PICTURE,
  FAVORITE_EVENT_PICTURE,
  SHOW_FAVORITES,
  ADD_FAVORITE_EVENT_PICTURE,
  ADD_FAVORITE_EVENT_PICTURES,
  FAVORITE_PICTURES,
  REQUEST_FAVORITE_PICTURES,
  DELETE_EVENT_PICTURES,
  INCREASE_FAVORITE_COUNT,
  DECREASE_FAVORITE_COUNT,
  DECREASE_COMMENTS_COUNT,
  INCREMENT_COMMENTS_COUNT,
  TOGGLE_SELECT_MODE,
  SELECT_IMAGE,
  UNSELECT_IMAGE,
  CLEAR_SELECT,
  ARCHIVE_PHOTO,
  SELECT_ALL_IMAGES, SELECT_IMAGE_RANGE, SET_DRAG, SET_DRAG_PHOTOS
} from '../types';

export const galleryStatus = (state = 'big', action) => {
  switch (action.type) {
    case GALLERY_BIG:
      return action.payload;
    case GALLERY_SMALL:
      return action.payload;
    default:
      return state;
  }
};

export const showFavorites = (state = false, action) => {
  switch (action.type) {
    case SHOW_FAVORITES:
      return action.payload;
    default:
      return state;
  }
};

export const eventPictures = (state = null, action) => {
  if (action.payload) {
    const { eventId, totalAmount, pictures, isFetching } = action.payload;
    const thisEventPicturesObj = state && state[eventId];
    switch (action.type) {
      case ERROR_EVENT_PICTURE:
        if (action.payload instanceof Object) {
          const { pictureId } = action.payload;

          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id === pictureId) {
                return { ...p, error: true };
              } else {
                return p;
              }
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        } else {
          return state;
        }
      case LOAD_EVENT_PICTURE:
        if (action.payload instanceof Object) {
          const { pictureId } = action.payload;

          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id === pictureId) {
                return { ...p, load: true };
              } else {
                return p;
              }
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        } else {
          return state;
        }
      case FAVORITE_EVENT_PICTURE:
        if (action.payload instanceof Object) {
          const { pictureId, favorite } = action.payload;
          // console.log(thisEventPicturesObj, pictureId, favorite)
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id === pictureId) {
                return { ...p, favorite };
              } else {
                return p;
              }
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        } else {
          return state;
        }
      case ADD_FAVORITE_EVENT_PICTURES:
        if (action.payload instanceof Object) {
          const { pictures } = action.payload;
          // console.log(thisEventPicturesObj)
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              return { ...p, favorite: pictures.find(fp => fp.id == p.id) ? true : p.favorite };
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        } else {
          return state;
        }
      case INCREASE_FAVORITE_COUNT:
        if (action.payload instanceof Object) {
          const {eventId, photoID, amount, isCurrentCustomer} = action.payload;
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id == photoID) {
                return {
                  ...p,
                  favorite_count: p.favorite_count + amount,
                  favorite: isCurrentCustomer ? true : p.favorite,
                }
              }
              else return { ...p };
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        } else {
          return state;
        }
      case DECREASE_FAVORITE_COUNT:
        if (action.payload instanceof Object) {
          const {eventId, photoID, amount, isCurrentCustomer} = action.payload;
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id == photoID) {
                return {
                  ...p,
                  favorite_count: p.favorite_count >= amount ? p.favorite_count - amount : 0,
                  favorite: isCurrentCustomer ? false : p.favorite,
                }
              }
              else return { ...p };
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        } else {
          return state;
        }
      case INCREMENT_COMMENTS_COUNT:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const {eventId, photoID, amount, isCurrentCustomer} = action.payload;
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id == photoID) {
                return {
                  ...p,
                  comments: p.comments + amount,
                  comment: isCurrentCustomer ? true : p.comment,
                }
              }
              else return { ...p };
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        }
        return state;
      case DECREASE_COMMENTS_COUNT:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const {eventId, photoID, amount, isCurrentCustomer} = action.payload;
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id == photoID) {
                return {
                  ...p,
                  comments: p.comments >= amount ? p.comments - amount : 0,
                  comment: isCurrentCustomer ? false : p.comment,
                }
              }
              else return { ...p };
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        }
        return state;
      case DELETE_EVENT_PICTURES:
        if (action.payload instanceof Object) {
          const { pictureIds } = action.payload;

          let removed = 0;
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.filter(p => {
              // return pictureIds.indexOf(p.id) === -1;
              for (let i = 0; i < pictureIds.length; i++) {
                if (p.id == pictureIds[i]) {
                  removed += 1;
                  return false;
                }
              }
              return true;
            }),
          ];

          return {
            ...state,
            ...{
              [eventId]: {
                ...thisEventPicturesObj,
                totalAmount: thisEventPicturesObj.totalAmount - removed,
              },
            },
          };
        } else {
          return state;
        }
      case DELETE_EVENT_PICTURE:
        if (action.payload instanceof Object) {
          const { pictureId } = action.payload;

          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.filter(p => {
              return p.id !== pictureId;
            }),
          ];

          return {
            ...state,
            ...{
              [eventId]: {
                ...thisEventPicturesObj,
                totalAmount: thisEventPicturesObj.totalAmount - 1,
              },
            },
          };
        } else {
          return state;
        }
      case ADD_EVENT_PICTURE:
        if (action.payload instanceof Object) {
          const { picture } = action.payload;

          thisEventPicturesObj.pictures = [
            { load: false, error: false, selected: false, ...picture },
            ...thisEventPicturesObj.pictures.filter(p => {
              return p.id !== picture.id;
            }),
          ].sort((ep1, ep2) => {
           if (ep1.order && ep2.order) {
             return ep1.order > ep2.order ? -1 : 1;
           } else {
             return new Date(ep1.ph_upload_date).getTime() > new Date(ep2.ph_upload_date).getTime() ? -1  : 1;
           }
          });

          return {
            ...state,
            ...{
              [eventId]: {
                ...thisEventPicturesObj,
                totalAmount: thisEventPicturesObj.totalAmount + 1,
              },
            },
          };
        } else {
          return state;
        }
      case NEW_EVENT_PICTURES:
        if (action.payload instanceof Object) {

          const newEventPictures = pictures;

          const thisEventPictures = ((thisEventPicturesObj && thisEventPicturesObj.pictures) || []).filter(p => {
            return !pictures.find(tp => tp.id === p.id);
          });

          return {
            ...state,
            ...{
              [eventId]: {
                isFetching,
                totalAmount: thisEventPicturesObj.totalAmount + newEventPictures.length,
                pictures: [...thisEventPictures, ...newEventPictures]
                  .map(ep => {
                    return { load: false, error: false, selected: false, ...ep };
                  })
                  .sort((ep1, ep2) => {
                    if (ep1.order && ep2.order) {
                      return ep1.order > ep2.order ? -1 : 1;
                    } else {
                      return new Date(ep1.ph_upload_date).getTime() > new Date(ep2.ph_upload_date).getTime() ? -1  : 1;
                    }
                  }),
              },
            },
          };
        } else {
          return state;
        }
      case EVENT_PICTURES:
        if (action.payload instanceof Object) {
          const newEventPictures = pictures;

          const thisEventPictures = ((thisEventPicturesObj && thisEventPicturesObj.pictures) || []).filter(p => {
            return !pictures.find(tp => tp.id === p.id);
          });

          return {
            ...state,
            ...{
              [eventId]: {
                isFetching,
                totalAmount,
                pictures: [...thisEventPictures, ...newEventPictures]
                  .map(ep => {
                    return { load: false, error: false, selected: false, ...ep };
                  })
                  .sort((ep1, ep2) => {
                    if (ep1.order && ep2.order) {
                      return ep1.order > ep2.order ? -1 : 1;
                    } else {
                      return new Date(ep1.ph_upload_date).getTime() > new Date(ep2.ph_upload_date).getTime() ? -1  : 1;
                    }
                  } ),
              },
            },
          };
        } else {
          return state;
        }
      case REQUEST_EVENT_PICTURES:
        return { ...state, ...{ [eventId]: { ...thisEventPicturesObj, isFetching } } };
      default:
        return state;
    }
  } else {
    return state;
  }
};

export const favoritePictures = (state = null, action) => {
  if (action.payload) {
    const { eventId, totalAmount, pictures, isFetching } = action.payload;
    const thisEventPicturesObj = state && state[eventId];
    switch (action.type) {
      case INCREASE_FAVORITE_COUNT:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const {eventId, photoID, amount, isCurrentCustomer} = action.payload;
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id == photoID) {
                return {
                  ...p,
                  favorite_count: p.favorite_count + amount,
                  favorite: isCurrentCustomer ? true : p.favorite,
                }
              }
              else return { ...p };
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        }
        return state;
      case DECREASE_FAVORITE_COUNT:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const {eventId, photoID, amount, isCurrentCustomer} = action.payload;
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id == photoID) {
                return {
                  ...p,
                  favorite_count: p.favorite_count >= amount ? p.favorite_count - amount : 0,
                  favorite: isCurrentCustomer ? false : p.favorite,
                }
              }
              else return { ...p };
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        }
        return state;
      case INCREMENT_COMMENTS_COUNT:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const {eventId, photoID, amount, isCurrentCustomer} = action.payload;
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id == photoID) {
                return {
                  ...p,
                  comments: p.comments + amount,
                  comment: isCurrentCustomer ? true : p.comment,
                }
              }
              else return { ...p };
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        }
        return state;
      case DECREASE_COMMENTS_COUNT:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const {eventId, photoID, amount, isCurrentCustomer} = action.payload;
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id == photoID) {
                return {
                  ...p,
                  comments: p.comments >= amount ? p.comments - amount : 0,
                  comment: isCurrentCustomer ? false : p.comment,
                }
              }
              else return { ...p };
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        }
        return state;
      case ERROR_EVENT_PICTURE:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const { pictureId } = action.payload;

          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id === pictureId) {
                return { ...p, error: true };
              } else {
                return p;
              }
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        } else {
          return state;
        }

      case LOAD_EVENT_PICTURE:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const { pictureId } = action.payload;

          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.map(p => {
              if (p.id === pictureId) {
                return { ...p, load: true };
              } else {
                return p;
              }
            }),
          ];

          return {
            ...state,
            ...{ [eventId]: { ...thisEventPicturesObj } },
          };
        } else {
          return state;
        }
      case FAVORITE_EVENT_PICTURE:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const { pictureId, favorite } = action.payload;

          if (!favorite) {
            thisEventPicturesObj.pictures = [
              ...thisEventPicturesObj.pictures.filter(p => {
                return p.id !== pictureId;
              }),
            ];

            return {
              ...state,
              ...{
                [eventId]: {
                  ...thisEventPicturesObj,
                  totalAmount: thisEventPicturesObj.totalAmount - 1,
                },
              },
            };
          } else {
            return state;
          }
        } else {
          return state;
        }
      case ADD_FAVORITE_EVENT_PICTURE:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const { picture } = action.payload;

          thisEventPicturesObj.pictures = [
            { load: false, error: false, selected: false, ...picture, favorite: true },
            ...thisEventPicturesObj.pictures.filter(p => {
              return p.id !== picture.id;
            }),
          ].sort((ep1, ep2) => {
            if (ep1.order && ep2.order) {
              return ep1.order > ep2.order ? -1 : 1;
            } else {
              return new Date(ep1.ph_upload_date).getTime() > new Date(ep2.ph_upload_date).getTime() ? -1  : 1;
            }
          });

          return {
            ...state,
            ...{
              [eventId]: {
                ...thisEventPicturesObj,
                totalAmount: thisEventPicturesObj.totalAmount + 1,
              },
            },
          };
        } else {
          return state;
        }
      case ADD_FAVORITE_EVENT_PICTURES:
        if (thisEventPicturesObj && action.payload instanceof Object) {

          const { pictures } = action.payload;
          const newEventPictures = pictures;

          const thisEventPictures = ((thisEventPicturesObj && thisEventPicturesObj.pictures) || []).filter(p => {
            return !pictures.find(tp => tp.id === p.id);
          });

          return {
            ...state,
            ...{
              [eventId]: {
                isFetching,
                totalAmount: thisEventPicturesObj.totalAmount + newEventPictures.length,
                pictures: [...thisEventPictures, ...newEventPictures]
                  .map(ep => {
                    return { ...ep, favorite: true };
                  })
                  .sort((ep1, ep2) => {
                    if (ep1.order && ep2.order) {
                      return ep1.order > ep2.order ? -1 : 1;
                    } else {
                      return new Date(ep1.ph_upload_date).getTime() > new Date(ep2.ph_upload_date).getTime() ? -1  : 1;
                    }
                  }),
              },
            },
          };
        } else {
          return state;
        }
      case DELETE_EVENT_PICTURES:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const { pictureIds } = action.payload;
          let removed = 0;
          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.filter(p => {
              // return pictureIds.indexOf(String(p.id)) === -1;
              for (let i = 0; i < pictureIds.length; i++) {
                if (p.id == pictureIds[i]) {
                  removed += 1;
                  return false;
                }
              }
              return true;
            }),
          ];

          return {
            ...state,
            ...{
              [eventId]: {
                ...thisEventPicturesObj,
                totalAmount: thisEventPicturesObj.totalAmount - removed,
              },
            },
          };
        } else {
          return state;
        }
      case DELETE_EVENT_PICTURE:
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const { pictureId } = action.payload;

          thisEventPicturesObj.pictures = [
            ...thisEventPicturesObj.pictures.filter(p => {
              return p.id !== pictureId;
            }),
          ];

          return {
            ...state,
            ...{
              [eventId]: {
                ...thisEventPicturesObj,
                totalAmount: thisEventPicturesObj.totalAmount - 1,
              },
            },
          };
        } else {
          return state;
        }
      case NEW_EVENT_PICTURES: // new fetch?
        if (thisEventPicturesObj && action.payload instanceof Object) {
          const newEventPictures = pictures;

          const thisEventPictures = ((thisEventPicturesObj && thisEventPicturesObj.pictures) || []).filter(p => {
            return !pictures.find(tp => tp.id === p.id);
          });

          return {
            ...state,
            ...{
              [eventId]: {
                isFetching,
                totalAmount: thisEventPicturesObj.totalAmount + newEventPictures.length,
                pictures: [...thisEventPictures, ...newEventPictures]
                  .map(ep => {
                    return { load: false, error: false, selected: false, ...ep };
                  })
                  .sort((ep1, ep2) => {
                    if (ep1.order && ep2.order) {
                      return ep1.order > ep2.order ? -1 : 1;
                    } else {
                      return new Date(ep1.ph_upload_date).getTime() > new Date(ep2.ph_upload_date).getTime() ? -1  : 1;
                    }
                  }),
              },
            },
          };
        } else {
          return state;
        }
      case FAVORITE_PICTURES:
        if (action.payload instanceof Object) {
          const thisEventPictures = (thisEventPicturesObj && thisEventPicturesObj.pictures) || [];
          const newEventPictures = pictures.filter(p => {
            return !thisEventPictures.find(tp => tp.id === p.id);
          });

          return {
            ...state,
            ...{
              [eventId]: {
                isFetching,
                totalAmount,
                pictures: [...thisEventPictures, ...newEventPictures]
                  .map(ep => {
                    return { load: false, error: false, selected: false, ...ep };
                  })
                  .sort((ep1, ep2) => {
                    if (ep1.order && ep2.order) {
                      return ep1.order > ep2.order ? -1 : 1;
                    } else {
                      return new Date(ep1.ph_upload_date).getTime() > new Date(ep2.ph_upload_date).getTime() ? -1  : 1;
                    }
                  }),
              },
            },
          };
        } else {
          return state;
        }
      case REQUEST_FAVORITE_PICTURES:
        return { ...state, ...{ [eventId]: { ...thisEventPicturesObj, isFetching } } };
      default:
        return state;
    }
  } else {
    return state;
  }
};

export const fetchAmount = (state = 30) => {
  return state;
};

const selectInitialState = {
  selectMode: false,
  selectedImages: [],
  lastSelectedIndex: null,
  archive: {
    upload: 0,
    totalPhotos: 0
  }
};

export const selectionReducer = (state = selectInitialState, action) => {
  // id or order index
  switch (action.type) {
    case TOGGLE_SELECT_MODE:
      return {
        ...state,
        selectMode: !state.selectMode,
        lastSelectedIndex: null
      };
    case SELECT_IMAGE:
      return {
        ...state,
        selectedImages: [...state.selectedImages, action.payload.id],
        lastSelectedIndex: action.payload.index
      };
    case SELECT_IMAGE_RANGE:
      const newSelectedIds = action.payload.ids.filter(id => !state.selectedImages.includes(id));
      return {
        ...state,
        selectedImages: [...state.selectedImages, ...newSelectedIds],
        lastSelectedIndex: action.payload.lastSelectedIndex,
      };
    case SELECT_ALL_IMAGES:
      return {
        ...state,
        selectedImages: action.payload,
      };
    case UNSELECT_IMAGE:
      return {
        ...state,
        selectedImages: state.selectedImages.filter(id => id != action.payload),
        lastSelectedIndex: null
      };
    case CLEAR_SELECT:
      return {
        ...state,
        selectedImages: [],
      };
    case ARCHIVE_PHOTO:
      return {
        ...state,
        archive: action.payload
      }
    default:
      return state;
  }
};


const setDrag = {
  draggable: false,
  drag_photos: []
};

export const dragReducer = (state = setDrag, action) => {
  switch (action.type) {
    case SET_DRAG:
      return {
        ...state,
        draggable: action.payload,
      };
    case SET_DRAG_PHOTOS:
      return {
        ...state,
        drag_photos: action.payload,
      };
    default:
      return state;
  }
};

