import React from 'react';
import styled from 'styled-components';
import { formatLong } from '../../utils/dateFns';

const Container = styled.div`
  font-size: 20px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.lightGray};
  margin-bottom: 36px;
  flex-shrink: 0;
  ${({ theme }) =>
    theme.onMD(`
    margin-bottom: 20px;
    font-size: 15px;
  `)}
`;

export const EventDate = ({ text }) => <Container>{`${!!text && formatLong(text)}`}</Container>;
