import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DefaultModal } from './modal';
import { EventTitle } from '../common/eventTitle';
// import { EventDate } from '../common/eventDate';
import { ActionButton } from '../common/actionButton';

import { toggleModalInvitation, toggleModalInfo } from '../../redux/actions/modals';

import whatsapp_icons from '../../static/assets/icons/whatsapp_icon.svg';
import zwischenspeichern_icon from '../../static/assets/icons/zwischenspeichern_icon.svg';
import email_icon from '../../static/assets/icons/email_icon.svg';

import { copyToClipboard } from '../../utils/copyToClipboard';

import { FullButton } from '../common/fullBtn';
import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";

const Inner = styled.div`
  margin-top: 60px;
  width: 100%;
  ${({ theme }) =>
    theme.onMD(`
    margin-top: 30px;
  `)}
  ${({ theme }) =>
    theme.onXS(`
    margin-top: 10px;
  `)}
`;

// const Title = styled.div`
//   margin-bottom: 26px;
//   font-size: 21px;
//   font-family: ${({ theme }) => theme.font.default};
//   color: ${({ theme }) => theme.palette.dark};
//   display: inline-block;
//   ${({ theme }) =>
//     theme.onMD(`
//     display: block;
//     margin-bottom: 15px;
//     font-size: 14px;
//   `)}
// `;
//
// const Code = styled.div`
//   margin: 0 0 26px 20px;
//   font-size: 26px;
//   font-family: ${({ theme }) => theme.font.default};
//   color: ${({ theme }) => theme.palette.mintGreen};
//   display: inline-block;
//   ${({ theme }) =>
//     theme.onMD(`
//     display: block;
//     margin: 0 0 40px;
//     font-size: 19px;
//   `)}
//   ${({ theme }) =>
//     theme.onXS(`
//     margin: 0 0 20px;
//   `)}
// `;

const InfoText = styled.div`
  margin: 20px 0;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  ${({ theme }) =>
    theme.onMD(`
    margin: 15px 0;
    font-size: 15px;
  `)}
`;

const TextArea = styled.textarea`
  text-align: left;
  width: 100%;
  max-width: 100%;
  border: 2px solid ${({ theme }) => theme.palette.lightGray};
  background-color: ${({ theme }) => theme.palette.white};
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.default};
  padding: 14px;
  margin: 0px;
  height: 230px;
  line-height: 1.4em;
  resize: none;
  &:focus {
    outline: none !important;
    border: 2px solid rgba(128, 180, 171, 0.5);
  }
  ${({ theme }) =>
    theme.onMD(`
    height: 200px;
    font-size: 14px;
    padding: 0 13px;
    border-width: 1px;
    margin-top: 10px;
    &:focus {
      border-width: 1px;
    }
  `)}
`;

export const EventInvitationModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {isMobile} = useWindowWidth();

  const modalState = useSelector(state => state.modalInvitation);
  const activeEvent = useSelector(state => state.activeEvent);
  const changeModalState = state => toggleModalInvitation(dispatch, state);
  const [invitationText, setInvitationText] = useState(``);

  // const mailSubject = encodeURI('Sie wurden zum Event eingeladen');
  // const mailBody = encodeURI(invitationText);
  const mailSubjectText = 'Sie wurden zum Event eingeladen';
  const mailSubject = encodeURIComponent(mailSubjectText).replace(/%0A/g, '%0D%0A');
  const mailBody = encodeURIComponent(invitationText).replace(/%0A/g, '%0D%0A');
  const whatsappMessage = encodeURI(invitationText);

  const onCopySuccess = () => {
    toggleModalInfo(dispatch, { title: 'Erfolgreich in die Zwischenablage kopiert!', message: '' });
  };

  useEffect(() => {
    setInvitationText(`Hallo zusammen,
für die Fotos unserer Feier haben wir bei sendmoments ein digitales Fotoalbum angelegt, in dem wir alle unsere Bilder hochladen und sammeln können. 
Über den Link ${ window.location.origin }/auth/join_event?eventcode=${ activeEvent ? activeEvent.evt_code : '' }
könnt Ihr dem Album ganz einfach beitreten.​ Viele Grüße und Danke im Voraus für Eure Fotos​ Eure`);
  }, [activeEvent, modalState]);

  return (
    <DefaultModal
      modalState={modalState}
      modalLabel="event_invitation"
      onRequestClose={() => changeModalState(false)}
      title={t('share_event')}
      modalStyles={{ content: { width: isMobile ? '490px' : '600px' } }}
    >
      <Inner>
        <EventTitle text={activeEvent && activeEvent.evt_name} />
        {/*<EventDate text={activeEvent && activeEvent.evt_event_date} />*/}
        {/*<Title>{t('invitation_code')}</Title>*/}
        {/*<Code>{activeEvent && activeEvent.evt_code}</Code>*/}
        {isMobile ? (
          <>
            <ActionButton
              href={`mailto:?subject=${mailSubject}&body=${mailBody}`}
              iconSrc={email_icon}
              iconProps={{ width: 51, height: 51 }}
              mobIconProps={{ width: 38, height: 38 }}
              text="Email"
            />
            <ActionButton
              href={`https://wa.me/?text=${whatsappMessage}`}
              iconSrc={whatsapp_icons}
              iconProps={{ width: 54, height: 44 }}
              text="WhatsApp"
            />
            <ActionButton
              iconSrc={zwischenspeichern_icon}
              iconProps={{ width: 48, height: 48 }}
              text={t('clipboard')}
              click={copyToClipboard(invitationText, onCopySuccess)}
            />
          </>
        ) : (
          <>
            <InfoText>
              Ganz einfach den unteren Text kopieren und an die gewünschten Teilnehmer schicken.
            </InfoText>
            <TextArea onChange={({ target: { value } }) => setInvitationText(value)}>
              {invitationText}
            </TextArea>
            <FullButton
              mt={20}
              btnColor="mintGreen"
              text={t('clipboard')}
              click={copyToClipboard(invitationText, onCopySuccess)}
            />
          </>
        )}
      </Inner>
    </DefaultModal>
  );
};
