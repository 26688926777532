import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTransition, animated } from 'react-spring';
import { MainFace } from './mainFace';
import { EventFace } from './eventFace';
import { GuestFace } from './guestFace';
import { CustormerFace } from './customerFace';

const NavContainer = styled.div`
  display: flex;
  flex-grow: 1;
  will-change: transform, opacity;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

export const SidebarPages = () => {
  const sidebarFace = useSelector(state => state.sidebarFace);


  const transitions = useTransition(sidebarFace, {
    from: data =>
      data === 'main'
        ? { width: '100%', opacity: 0, transform: 'translate3d(-100%, 0, 0)', display: 'block' }
        : { width: '100%', opacity: 0, transform: 'translate3d(100%, 0, 0)', display: 'block' },
    enter: { width: '100%', opacity: 1, transform: 'translate3d(0%, 0, 0)', display: 'block' },
    leave: { display: 'none' },
  });

  const pages = {
    main: ({ style, forwardKey }) => (
      <animated.div key={`animated-face-${forwardKey}`} style={{ ...style }}>
        <MainFace />
      </animated.div>
    ),
    events: ({ style, forwardKey }) => (
      <animated.div key={`animated-face-${forwardKey}`} style={{ ...style }}>
        <EventFace />
      </animated.div>
    ),
    guests: ({ style, state, forwardKey }) => (
      <animated.div key={`animated-face-${forwardKey}`} style={{ ...style }}>
        <GuestFace state={state} />
      </animated.div>
    ),
    customer: ({ style, forwardKey }) => (
      <animated.div key={`animated-face-${forwardKey}`} style={{ ...style }}>
        <CustormerFace />
      </animated.div>
    ),
  };

  return (
    <NavContainer>
      {/* {transitions.map(data => {
        const { item, props, key, state } = data;
        const Page = pages[item];
        return <Page key={key} style={props} state={state} />;
      }) */}
      {
        transitions((style, item, t, i) => {
          const Page = pages[item];
          return <Page forwardKey={i} style={style} state={t.phase} />;
        })
      }
    </NavContainer>
  );
};
