import React from 'react';
import styled from 'styled-components';

import arrow_right from '../../../static/assets/icons/arrow_right.svg';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 78px;
  width: 100%;
  padding: 0 24px;
  margin: ${({ margins }) => margins};
  font-size: 21px;
  font-family: ${({ theme: { font } }) => font.default};
  color: ${({ theme: { palette } }) => palette.white};
  background-color: ${({ theme: { palette }, bgColor }) => (bgColor ? palette[bgColor] : palette.mintGreen)};
  cursor: pointer;
  flex-shrink: 0;
  &:hover {
    background-color: ${({ theme }) => theme.palette.dark};
  }
  ${({ theme }) =>
    theme.onMD(`
    height: 57px;
    font-size: 14px;
  `)}
`;

const Content = styled.span``;

const Icon = styled.img`
  width: 10px;
  height: 19px;
  ${({ theme }) =>
    theme.onMD(`
    width: 7px;
    height: 13px
  `)}
`;

export const FullBtnWithIcon = ({ margins, text, bgColor, click }) => {
  return (
    <Container margins={margins} bgColor={bgColor} onClick={() => click()}>
      <Content>{text}</Content>
      <Icon src={arrow_right} />
    </Container>
  );
};
