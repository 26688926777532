import React from 'react';
import styled from 'styled-components';

import checkmark_green from '../../../static/assets/icons/checkmark_green.svg';

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  border-bottom: ${({ hideBorder }) => (!hideBorder ? '1px solid rgba(138, 132, 128, 0.6)' : 'none')};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.lotionGray};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 76px;
  height: 104px;
  padding-top: 18px;
  ${({ theme }) =>
    theme.onSM(`
    width: 58px;
    height: 73px;
    padding-top: 13px;
  `)}
`;

const Icon = styled.img`
  display: ${({ active }) => (active ? 'block' : 'none')};
  width: 36px;
  height: 36px;
  ${({ theme }) =>
    theme.onSM(`
     width: 27px;
      height: 27px;
  `)}
`;

const DescitionContainer = styled.div`
  width: calc(100% - 76px);
  flex-grow: 0;
  display: flex;
  flex-direction: column;
`;

const EventName = styled.div`
  margin-top: 20px;
  font-size: 21px;
  line-height: 33px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  ${({ theme }) =>
    theme.onSM(`
    margin-top: 16px;
    line-height: 20px;
    font-size: 16px;
  `)}
`;

const EventDate = styled.div`
  font-size: 19px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.lightGray};
  margin: 3px 0 16px;
  line-height: 27px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  ${({ theme }) =>
    theme.onSM(`
    margin: 0 0 10px;
    font-size: 14px;
  `)}
`;

export const EventElement = ({ active, click, title, date, hideBorder }) => {
  return (
    <Container hideBorder={hideBorder} onClick={() => click()}>
      <IconContainer>
        <Icon active={active} src={checkmark_green} alt="active event" />
      </IconContainer>
      <DescitionContainer>
        <EventName>{title}</EventName>
        <EventDate>{date}</EventDate>
      </DescitionContainer>
    </Container>
  );
};
