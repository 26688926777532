import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Container = styled.div`
    display: inline-block;
    position: relative;
    width: ${ ({size}) => size || 128 }px;
    height: ${ ({size}) => size || 128 }px;
  
  div {
    position: absolute;
    width: 10%;
    height: 10%;
    transform: translate(-50%, -50%);
    background: rgb(159,42,73);
    border-radius: 50%;
    animation: lds-default 2.4s linear infinite;
  }

div:nth-child(1) {
  animation-delay: -1.1s;
  top: 18.75%;
  left: 31.25%;
}

div:nth-child(2) {
  animation-delay: -1s;
  top: 31.25%;
  left: 18.75%;
}

div:nth-child(3) {
  animation-delay: -0.9s;
  top: 50%;
  left: 12.5%;
}

div:nth-child(4) {
  animation-delay: -0.8s;
  top: 68.75%;
  left: 18.75%;
}

div:nth-child(5) {
  animation-delay: -0.7s;
  top: 81.25%;
  left: 31.25%;
}

div:nth-child(6) {
  animation-delay: -0.6s;
  top: 86.25%;
  left: 50%;
}

div:nth-child(7) {
  animation-delay: -0.5s;
  top: 81.25%;
  left: 68.75%;
}

div:nth-child(8) {
  animation-delay: -0.4s;
  top: 68.75%;
  left: 81.25%;
}

div:nth-child(9) {
  animation-delay: -0.3s;
  top: 50%;
  left: 85%;
}

div:nth-child(10) {
  animation-delay: -0.2s;
  top: 31.25%;
  left: 81.25%;
}

div:nth-child(11) {
  animation-delay: -0.1s;
  top: 18.75%;
  left: 68.75%;
}

div:nth-child(12) {
  animation-delay: -0s;
  top: 12.5%;
  left: 50%;
}

  @keyframes lds-default {
    0%, 10%, 40%, 50% {
      background: rgba(159,42,73,.5);
    }
    25% {
      background: rgba(159,42,73,.5);
    }
    50%, 60%, 90%, 100% {
      background: rgba(159,42,73,1);
    }
    75% {
      background: rgba(159,42,73,1);
    }
  }
`;

export const Loader = ({ size, style }) => (
  <Wrapper style={style}>
    <Container size={size}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Container>
  </Wrapper>
);
