import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import {
  addEventPicture, archiveSelection,
  decreaseCommentsCounter,
  decreaseFavoriteCounter,
  deleteEventPictures,
  increaseCommentsCounter,
  increaseFavoriteCounter, toggleSelectMode
} from '../redux/actions/gallery';

export const socketConnection = (() => {
  const connect = io(process.env.REACT_APP_BACKEND_DOMAIN, {
    path: '/socket',
    transports: ['websocket'],
  });
  return () => connect;
})();

const MessageType = {
    add_photo: 'add_photo',
    delete_photo: 'delete_photo',
    add_like: 'add_like',
    remove_like: 'remove_like',
    add_favorite: 'add_favorite',
    remove_favorite: 'remove_favorite',
    add_comment: 'add_comment',
    remove_comment: 'remove_comment',
    edit_comment: 'edit_comment',
    archive: 'archive'
}

export const Socket = () => {
  const connect = socketConnection();
  const dispatch = useDispatch();
  const activeEvent = useSelector(state => state.activeEvent);
  const currentUser = useSelector(state => state.currentUser);
  useEffect(() => {
    connect.on('connect', function() {
      socketConnection().emit('event_message', activeEvent.id, data => console.log('data', data));
    });

    socketConnection().emit('event_message', activeEvent.id, data => console.log('data', data));
    connect.on('event_message', resp => {
      if (resp && resp.type) {
        const { eventID, data, customerID} = resp.payload;
        switch (resp.type) {
          case MessageType.add_photo:

            if (data && data[0]) {
              dispatch(addEventPicture(eventID, data[0]));
            }
            break;
          case MessageType.delete_photo:
            dispatch(deleteEventPictures(eventID, data));
            break;
          case MessageType.add_favorite:
            data.favorite.forEach(el => {
              dispatch(increaseFavoriteCounter(eventID, el.photoID, el.amount, customerID === currentUser.id))
            });
            break;
          case MessageType.remove_favorite:
            data.favorite.forEach(el => {
              dispatch(decreaseFavoriteCounter(eventID, el.photoID, el.amount, customerID === currentUser.id));
            });
            break;
          case MessageType.add_comment:
            data.comments.forEach(el => {
              dispatch(increaseCommentsCounter(eventID, el.photoID, el.amount, customerID === currentUser.id));
            });
            break;
          // case MessageType.edit_comment:
          //   data.favorite.forEach(el => {
          //     dispatch(decreaseFavoriteCounter(eventID, el.photoID, el.amount, customerID === currentUser.id));
          //   });
          //   break;
          case MessageType.remove_comment:
            data.comments.forEach(el => {
              dispatch(decreaseCommentsCounter(eventID, el.photoID, el.amount, el.elementOwnerID === currentUser.id));
            });
            break;
          case MessageType.archive:
            if (customerID === currentUser.id) {
              if (data.upload == data.totalPhotos){
                dispatch(archiveSelection({upload: 0, totalPhotos: 0}))
              } else {
                dispatch(archiveSelection(data))
              }
            }
        }
      }
    });

    connect.on('connect_error ', err => console.log(err));
    connect.on('connect_failed ', err => console.log(err));
    connect.on('exception ', function(data) {
      console.log('exception ', data);
    });
    return () => {};
  }, []);

  return <></>;
};
