import React, {useState} from 'react';
import styled from 'styled-components';
import arrow_icon from "../../static/assets/icons/go_up_icon_black.svg";
import { useEffect } from 'react';

export const ScrollToTop = ({ bottomBar }) => {
    const [toTopVisible, setVisibility] = useState(false);
    let userScrolled = false;
    const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0 && !userScrolled) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 8);
        } else {
            userScrolled = false;
            setVisibility(false);
        }
    };
    const onClick = () => {
        userScrolled = false;
        scrollToTop();
    };
    const scrolledElement = document.documentElement;
    const onWheel = () => {
        userScrolled = true;
        setVisibility(scrolledElement.scrollTop >= scrolledElement.clientHeight / 2);
    };

    useEffect(() => {
        scrolledElement.addEventListener('wheel', onWheel);
        scrolledElement.addEventListener('touchmove', onWheel);

        return () => {
            scrolledElement.removeEventListener('wheel', onWheel);
            scrolledElement.removeEventListener('touchmove', onWheel);
        }
    });

    return (
        <Wrapper bottomBar={bottomBar} visible={toTopVisible} onClick={onClick}>
            <img src={arrow_icon} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
  ${ props => props.visible ? `
  width: 60px;
  height: 60px;
  ` : `
  width: 0;
  height: 0;
  `}
  
  position: fixed;
  right: 25px;
  bottom: ${({ bottomBar }) => bottomBar ? 85 : 70}px;
  z-index: 2;
  transition: all .5s;
  
  img { width: 100%; user-drag: none; user-select: none; cursor: pointer; }
  
  ${({theme, visible}) =>
    theme.onMD(`
      ${ visible ? `
        width: 38px;
        height: 38px;
      ` : ''}
  `)}
`;