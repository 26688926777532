export const LOG_USER = 'LOG_USER';
export const LOGGED_USER = 'LOGGED_USER';
export const USER_FAILURE = 'USER_FAILURE';
export const AUTH_TYPE = 'AUTH_TYPE';

export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';

export const FACE_MAIN = 'FACE_MAIN';
export const FACE_EVENTS = 'FACE_EVENTS';
export const FACE_GUESTS = 'FACE_GUESTS';
export const FACE_CUSTOMER = 'FACE_CUSTOMER';

export const GALLERY_SMALL = 'GALLERY_SMALL';
export const GALLERY_BIG = 'GALLERY_BIG';
export const EVENT_PICTURES = 'EVENT_PICTURES';
export const REQUEST_EVENT_PICTURES = 'REQUEST_EVENT_PICTURES';
export const ADD_EVENT_PICTURE = 'ADD_EVENT_PICTURE';
export const DELETE_EVENT_PICTURE = 'DELETE_EVENT_PICTURE';
export const DELETE_EVENT_PICTURES = 'DELETE_EVENT_PICTURES';
export const NEW_EVENT_PICTURES = 'NEW_EVENT_PICTURES';
export const LOAD_EVENT_PICTURE = 'LOAD_EVENT_PICTURE';
export const ERROR_EVENT_PICTURE = 'ERROR_EVENT_PICTURE';
export const FAVORITE_EVENT_PICTURE = 'FAVORITE_EVENT_PICTURE';
export const SHOW_FAVORITES = 'SHOW_FAVORITES';
export const ADD_FAVORITE_EVENT_PICTURE = 'ADD_FAVORITE_EVENT_PICTURE';
export const ADD_FAVORITE_EVENT_PICTURES = 'ADD_FAVORITE_EVENT_PICTURES';
export const INCREASE_FAVORITE_COUNT = 'INCREASE_FAVORITE_COUNT';
export const DECREASE_FAVORITE_COUNT = 'DECREASE_FAVORITE_COUNT';
export const INCREMENT_COMMENTS_COUNT = 'INCREMENT_COMMENTS_COUNT';
export const DECREASE_COMMENTS_COUNT = 'DECREASE_COMMENTS_COUNT';
export const FAVORITE_PICTURES = 'FAVORITE_PICTURES';
export const REQUEST_FAVORITE_PICTURES = 'REQUEST_FAVORITE_PICTURES';

export const ACTIVE_EVENT = 'ACTIVE_EVENT';
export const EVENT_LIST = 'EVENT_LIST';
export const EVENT_TYPES = 'EVENT_TYPES';
export const CHANGE_EVENT = 'CHANGE_EVENT';
export const ADD_EVENT = 'ADD_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const PARTICIPANTS_LIST = 'PARTICIPANTS_LIST';
export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
export const ADD_PARTICIPANT_GUEST = 'ADD_PARTICIPANT_GUEST';
export const ADD_PARTICIPANT_MANAGER = 'ADD_PARTICIPANT_MANAGER';
export const CURRENT_PARTICIPANT = 'CURRENT_PARTICIPANT';

export const MODAL_EVENT = 'MODAL_EVENT';
export const MODAL_JOIN = 'MODAL_JOIN';
export const MODAL_INVITATION = 'MODAL_INVITATION';
export const MODAL_FORGOT_PASSWORD = 'MODAL_FORGOT_PASSWORD';
export const MODAL_CODE_ACTIVATED = 'MODAL_CODE_ACTIVATED';
export const MODAL_INFO = 'MODAL_INFO';
export const MODAL_ENHANCED = 'MODAL_ENHANCED';
export const MODAL_PROMPT = 'MODAL_PROMPT';
export const MODAL_IMAGES_SELECTED = 'MODAL_IMAGES_SELECTED';
export const MODAL_GUEST = 'MODAL_GUEST';
export const MODAL_FAST_LOGIN = 'MODAL_FAST_LOGIN';

export const UPLOAD_CONTAINER = 'UPLOAD_CONTAINER';
export const UPDATE_CONTAINER = 'UPDATE_CONTAINER';
export const REMOVE_CONTAINER = 'REMOVE_CONTAINER';
export const PROCESS_CONTAINER = 'PROCESS_CONTAINER';

export const TOGGLE_SELECT_MODE = 'TOGGLE_SELECT_MODE';
export const SELECT_IMAGE = 'SELECT_IMAGE';
export const SELECT_IMAGE_RANGE = 'SELECT_IMAGE_RANGE';
export const SELECT_ALL_IMAGES = 'SELECT_ALL_IMAGES';
export const UNSELECT_IMAGE = 'UNSELECT_IMAGE';
export const CLEAR_SELECT = 'CLEAR_SELECT';
export const ARCHIVE_PHOTO = 'ARCHIVE_PHOTO';

export const SET_DRAG = 'SET_DRAG';
export const SET_DRAG_PHOTOS = 'SET_DRAG_PHOTOS';


