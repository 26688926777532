import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';

import {toggleModalEnhancedRaw, toggleModalPromptRaw} from '../../../redux/actions/modals';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LabelIcon } from "../../icons/GalleryPhotoIcons";
import {PromptModalInner} from "../../Modals/promptModal";
import { FreeFocusInside } from 'react-focus-lock';
import {parentPostMessage, messageKeys} from "../../../utils/postMessage";
const { dispatch_gallery_height } = messageKeys;

const ModalContainer = styled.div`
	width: 100%;
	height: 100%;
  overflow-x: hidden;
`;

const InnerContainer = styled.div``;

const DescriptionText = styled.div`
  width: 100%;
	margin-top: 45px;
  text-align: left;
  font: normal normal 300 14px/19px Roboto;
  font-family: ${({theme}) => theme.font.default};
  ${({theme}) =>
	theme.onMD(`
				margin-top: 20px;
				font-size: 14px;
				text-align: left;
  `)}
`;

const ParticipantsContainer = styled.div`
	width: 100%;
	max-height: 395px;
	display: flex;
	flex-direction: column;
	margin: 15px auto 20px;
	${({theme}) => theme.onMD(`
		max-height: calc(100% - 50px - 30px - 45px);
	`)}
`;

const HeaderList = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
  width: 100%;
  height: 50px;
  background-color: #F7F3ED;
`;

const TitleColumn = styled.div`
	display: flex;
	height: 50px;
	line-height: 50px;
  text-align: center;
`;

const TitleText = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: auto 10px auto;
  font: normal normal normal 14px/20px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;

const IconContainer = styled.div`
	width: 24px;
	height: 24px;
	margin: auto 25px auto 0;
`;

const ThumbnailContainer = styled.div`
	height: 100%;
	width: auto;
	margin-right: 15px;
`;

const TextAreaMain = styled.textarea`
	margin: 10px 0;
	height: 171px;
	min-height: 70px;
  border: 1px dashed #000000;
  resize: none;
  :focus {
    outline: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 355px;
  margin: 35px auto 0 auto;
  ${({theme}) =>
	theme.onMD(`
			position: fixed;
			bottom: 0;
			left: 0;
			overflow: hidden;
      width: 100%;
      height: 36px;
      margin:0;
      padding: 0;
  `)}
`;


const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 160px;
  height: 38px;
  line-height: 36px;
  overflow: hidden;
  vertical-align: center;
  border: 1px solid #D1D3D4;
  border-radius: 19px;
  font-size: 14px ;
  font-family: ${({theme}) => theme.font.default};
	cursor: pointer;
  color: ${({ colorDesktop, theme: {palette}}) => palette[colorDesktop]};
  background-color: ${({ backgroundColor, theme: {palette} }) => palette[backgroundColor]};
  &:hover {
    ${({hoverStyle}) => hoverStyle};
  }
  
  ${({theme, colorMobile, hoverMobile}) =>
		theme.onMD(`
				width: 50%;
				height: auto;
				line-height: 36px;
				border-radius: 0;
				border: none;
				background-color: #FAF9F7;
				color: red;
				color: ${theme.palette[colorMobile]};
				&:hover {
					${hoverMobile}
				}
		`)}
`;

export const ParticipantsCommentingModal = ({count, labelExist, isHost, photoID, eventID, thumbnail, ownerName}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [commentID, changeCommentID] = useState(false);
	const [comment, changeComment] = useState('');

	useEffect(() => {

		const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/comment/${photoID}`;

		fetch(url, {
			method: 'GET',
			credentials: "include",
			headers: {
				'Content-Type': 'application/json',
			}
		})
			.then(response => response.json())
			.then(resp => {
				if (resp && resp.length) {
					changeComment(resp[0].text);
					changeCommentID(resp[0].commentID);
				}
			})
			.catch(err => console.log(err));
	}, [])

	const closeModalEnhancedRaw = () => {
		toggleModalEnhancedRaw(dispatch, false);
		parentPostMessage(dispatch_gallery_height, false, false, false, false);
	}

	const postComment = () => {
		const commentData = comment.trim()

		if (!commentData.length && !commentID) {
			closeModalPromptRaw(true);
			return;
		}

		const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/add_comment`;
		const editUrl = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/edit_comment`;
		const deleteUrl = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/remove_comment_guest`;


		const data = {
			eventID,
			photoID,
			comment: commentData,
			commentID,
		};

		fetch(commentID ? !commentData ? deleteUrl : editUrl : url, {
			method: 'POST',
			credentials: "include",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		})
			.then(response => response.json())
			.then(resp => {
				if (resp.success) {
					closeModalPromptRaw(true);
				} else if (!resp.success && resp.message === 'no_comment') {
					closeModalPromptRaw(true);
				}
			})
			.catch(err => console.log(err));
	}

	const closeModalPromptRaw = (close) => {
		const closePopUps = ()  => {
			changeCommentID(false);
			toggleModalPromptRaw(dispatch, false);
			closeModalEnhancedRaw();
		}
		if (close) {
			closePopUps();
		}
		else toggleModalPromptRaw(dispatch, {
			children: (
				<PromptModalInner
					title={'Du hast Änderungen vorgenommen'}
					description={`An dieser Nachricht wurden Änderungen vorgenommen. Möchtest Du, dass diese übernommen werden?`}
					actionToDo={postComment}
					onClose={() => closeModalPromptRaw(true)}
					textCancel={'Änderungen verwerfen'}
					textAccept={'Änderungen übernehmen'}
					btnWidthDesktop={190}
					btnColors={{
						cancel: {
							cancel: 'dark',
							bgColor: 'white',
							hoverStyle: 'border: 1px solid #000000;'
						},
						accept: {
							color: 'white',
							bgColor: 'newDart',
							hoverStyle: 'background-color: #000000;'
						}
					}}
				/>
			),
		});
	};

	const promptModalRaw = () => {
		postComment()
	}

	return (
		<FreeFocusInside>
			<ModalContainer>
				<InnerContainer>
					<DescriptionText>
						Dein Kommentar kann ausschließlich von Dir und vom Admin gelesen werden.
					</DescriptionText>
				</InnerContainer>
				<ParticipantsContainer>
					<HeaderList>
						<TitleColumn>
							<ThumbnailContainer>
								<img src={thumbnail} width='auto' height='100%' alt={'small_thumbnail'}/>
							</ThumbnailContainer>
							<TitleText>Foto von {ownerName}</TitleText>
						</TitleColumn>
						<IconContainer>
							<LabelIcon />
						</IconContainer>
					</HeaderList>
					<TextAreaMain
						autoFocus={true}
						value={comment}
						onChange={event => {
							changeComment(event.target.value)
						}}
						/>
				</ParticipantsContainer>
				<ButtonsContainer>
					<Button
						colorDesktop={'dark'}
						colorMobile={'darkGray'}
						backgroundColor={'white'}
						onClick={() => closeModalPromptRaw()}
						hoverStyle={'border: 1px solid #000000;'}
						hoverMobile={'border: none;'}
					>
						{t('cancel')}
					</Button>
					<Button
						colorDesktop={'white'}
						colorMobile={'dark'}
						backgroundColor={'newDart'}
						onClick={promptModalRaw}
						hoverStyle={'background-color: #000000'}
						hoverMobile={'background-color: inherit;'}
					>
						Übernehmen
					</Button>
				</ButtonsContainer>
			</ModalContainer>
		</FreeFocusInside>
	);
}
