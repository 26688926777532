import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { de } from 'date-fns/locale';
import {isValid as isValidDate, parse, format as dateFnsFormat} from 'date-fns';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { DefaultModal } from './modal';
import { SelectBox } from '../common/selectBox';
import { ArrowIcon } from '../icons/ArrowIcon';

import { WEEKDAYS_SHORT, WEEKDAYS_LONG, MONTHS, format } from '../../utils/dayPicker';



import { toggleModalEvent, toggleModalInfo } from '../../redux/actions/modals';
import { changeEvent, addEvent, switchEvent } from '../../redux/actions/event';

import icon_calendar from '../../static/assets/icons/icon_calendar.svg';
import { DeleteEventForm } from '../forms/deleteEventForm';
import { ErrorMessage } from '../common/errorMessage';
import { navigateToFace, showSidebar } from '../../redux/actions/sidebar';
import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";

// import DayPickerInput from 'react-day-picker/DayPickerInput';
import {DayPicker} from "react-day-picker";


const Inner = styled.div`
  width: 100%;
  margin-bottom: 80px;
  ${({ theme }) =>
    theme.onMD(`
    margin-bottom: 40px;
  `)}
`;

const InputTitle = styled.div`
  margin-top: 25px;
  font-size: 19px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  ${({ theme }) =>
    theme.onMD(`
    margin-top: 15px;
    font-size: 13.4px;
  `)}
`;

const InputContainer = styled.input`
  text-align: left;
  padding: 0 17px;
  width: ${({ inputWidth }) => inputWidth || '100%'};
  max-width: 100%;
  height: 62px;
  margin-top: 15px;
  border: 2px solid ${({ theme }) => theme.palette.lightGray};
  background-color: ${({ theme }) => theme.palette.white};
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.default};
  &:focus {
    outline: none !important;
    border: 2px solid rgba(128, 180, 171, 0.5);
  }
  ${({ theme }) =>
    theme.onMD(`
    height: 45px;
    font-size: 14px;
    padding: 0 13px;
    border-width: 1px;
    margin-top: 10px;
    &:focus {
      border-width: 1px;
    }
  `)}
  &[type=date] {
    flex-grow: 1;
    flex-shrink: 1;
    width: auto;
    padding: 0 13px;
    margin: 0 0 0 20px;
  }
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-top: 15px;
  position: relative;
  z-index: 0;
  .date-container {
      margin-left: 20px;
      text-align: left;
      padding: 0 17px;
      width: 280px;
      max-width: 100%;
      flex-grow: 1;
      height: 62px;
      border: 2px solid ${({ theme }) => theme.palette.lightGray};
      font-size: 16px;
      font-family: ${({ theme }) => theme.font.default};
      &:focus {
          outline: none !important;
          border: 2px solid rgba(128, 180, 171, 0.5);
      }
    }
  }

  ${({ theme }) =>
    theme.onMD(`
    .date-container {
       height: 45px;
        font-size: 14px;
        padding: 0 13px;
        border-width: 1px;
        flex-shrink: 1;
        width: auto;
        &:focus {
          border-width: 1px;
        }
    }
  `)}
`;

const IconCalendar = styled.img`
  margin-left: 10px;
  width: 40px;
  height: 60px;
  ${({ theme }) =>
    theme.onMD(`
    height: 44px;
    width: 32px;
  `)}
`;

const IconWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 2;
  top: 2px;
  right: 2px;
  font-size: 0;
  background: #ffffff;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 1) 100%
  );

  svg {
    width: 58px;
    height: 58px;
    padding: 10px;
    transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'none')};

    path {
      fill: ${({ opened }) => (opened ? 'rgba(128, 180, 171, 0.5)' : '')};
    }
  }

  ${({ theme }) =>
    theme.onMD(`
    top: 1px;
    right: 1px;

    svg {
      width: 43px;
      height: 43px;
    }
  `)}
`;

const DateInputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  margin-left: 20px;
  overflow: hidden;
`;

const DateInput = styled.input`
  position: absolute;
  top: 0;
  left: -30px;
  width: calc(100% + 30px);
  height: 100%;
  font-size: 300px;
  opacity: 0;
`;

export const EventGenerator = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {isMobile} = useWindowWidth();
  const modalState = useSelector(state => state.modalEvent);
  const eventTypes = useSelector(state => state.eventTypes);
  const [checkedDate, changeCheckedDate] = useState(new Date());
  const [editId, changeEditId] = useState('');
  const [openDayPicker, changeOpenDayPicker] = useState(false);
  const [eventName, changeEventName] = useState('');
  const [eventType, changeEventType] = useState(0);
  const [errorMeassges, setErrorMeassges] = useState({
    eventName: '',
    eventType: '',
    general: '',
  });
  const changeModalState = state => toggleModalEvent(dispatch, state);
  const changeActiveEvent = state => changeEvent(dispatch, state);
  const addActiveEvent = state => addEvent(dispatch, state);
  const switchActiveEvent = state => switchEvent(dispatch, state);
  const changeFace = state => navigateToFace(dispatch, state);
  const changeSidebarState = state => showSidebar(dispatch, state);

  const parseDate = (str, format, locale) => {
    const parsed = parse(str, format,  locale);
    if (isValidDate(parsed)) {
      return parsed;
    }
    return undefined;
  };

  const formatDate = (date, format, locale) => dateFnsFormat(date, format,  {locale});

  const addNewEvent = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}${'api/event/create'}`;
    const data = {
      evt_name: eventName,
      evt_description: 'description_' + eventName,
      evt_event_date: checkedDate,
      evt_type_id: eventType,
    };

    if (isValid()) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          if (data) {
            addActiveEvent(data);

            //switchActiveEvent(data);
            Cookies.set('active_event', data.id);

            changeSidebarState(false);
            changeFace('main');
            changeModalState(false);

            if (!isMobile && document.documentElement.scrollTop) {
              document.documentElement.scrollTop = 0;
            }
          }
        })
        .catch(err => console.log(err));
    }
  };

  const editEvent = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}${'api/event/edit'}`;
    const data = {
      id: editId,
      evt_name: eventName.trim(),
      evt_description: 'description_' + eventName.trim(),
      evt_event_date: checkedDate,
      evt_type_id: eventType,
    };

    if (isValid()) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          if (data) {
            if (data.success) {
              changeActiveEvent(data);
              changeModalState(false);
            } else {
              setErrorMeassges({ ...errorMeassges, general: t(data.error) });
            }
          }
        })
        .catch(err => console.log(err));
    }
  };

  const showDeletePopup = () => {
    toggleModalInfo(dispatch, {
      title: 'ALBUM ENDGÜLTIG LÖSCHEN',
      message: <DeleteEventForm event={modalState ? modalState.data : null} />,
      buttons: false,
    });
  };

  useEffect(() => {
    if (modalState && modalState.data) {
      changeEventName(modalState.data.evt_name || '');
      changeCheckedDate(
        (modalState.data.evt_event_date && new Date(modalState.data.evt_event_date)) || new Date(),
      );
      changeEditId(modalState.data.id || '');
      changeEventType(modalState.data.evt_type_id || 0);
    }

    return () => {
      changeEventName('');
      changeCheckedDate(new Date());
      changeEditId('');
      changeEventType(0);
      setErrorMeassges({
        eventName: '',
        eventType: '',
        general: ''
      });
    }
  }, [modalState]);

  const onEventTypeChange = option => {
    changeEventType(option.value);
    setErrorMeassges({
      ...errorMeassges,
      eventType: '',
    });

    //isValid();
  };

  const isValid = () => {
    const valid = !!eventName.trim() && eventType !== 0;
    setErrorMeassges({ general: '', eventName: !eventName.trim() ? t('event_name_cannot_be_empty') : '', eventType: eventType === 0 ? t('event_type_cannot_be_empty')  : ''});

    return valid;
  }

  return (
    <DefaultModal
      modalState={!!modalState}
      modalLabel="generate_event"
      onRequestClose={() => changeModalState(false)}
      title={modalState && modalState.data ? t('edit_event') : t('new_event')}
      buttons={
        !editId
          ? [{ text: t('create_album'), onClick: addNewEvent }]
          : [
              { text: t('to_save'), onClick: editEvent },
              { text: t('delete_event'), color: 'lightGray', onClick: showDeletePopup },
            ]
      }
    >
      <Inner>
        <InputTitle>{t('event_name')}</InputTitle>
        <InputContainer
          type="text"
          value={eventName}
          onChange={({ target: { value } }) => {
            changeEventName(value);
            setErrorMeassges({ ...errorMeassges, eventName: '' });
            //isValid();
          }
        } onBlur={({ target: { value } }) => isValid('eventName', value)} />
        { errorMeassges.eventName ? <ErrorMessage>{errorMeassges.eventName}</ErrorMessage> : null }
        <InputTitle>{t('event_type')}</InputTitle>
        <SelectBox value={eventType}
                   options={[
                     { value: 0, selected: eventType === 0, label: 'Bitte auswählen', placeholder: true },
                     ...eventTypes.map(evty => {
                       return { value: evty.id, selected: evty === eventType, label: t(evty.evty_name) };
                       }
                     )
                    ]}
                   onValueChange={onEventTypeChange} />
        { errorMeassges.eventType ? <ErrorMessage>{errorMeassges.eventType}</ErrorMessage> : null }
        <InputTitle>{t('date')}</InputTitle>
        <DateContainer>
          <IconCalendar src={icon_calendar} alt="calendar" />
          {!isMobile ? (
            <>
              <input
                type="text"
                value={`${dateFnsFormat(checkedDate, format, {locale: de})}`}
                className={'date-container'}
                onChange={()=> {}}
                onClick={() => {changeOpenDayPicker(!openDayPicker)} }
              />
              <DayPicker
                mode="single"
                selected={checkedDate}
                locale={de}
                weekStartsOn={1}
                initialFocus
                style={{
                  zIndex: 20,
                  display: openDayPicker ? 'block' : 'none',
                  position: 'absolute',
                  top: '-250px',
                  left: '30px',
                  background: 'white',
                  padding: '15px 20px',
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 6px',
                }}
                onSelect= {(date) => {
                  if (date) {
                    changeCheckedDate(new Date(date))
                    isValid()
                  }
                  changeOpenDayPicker(!openDayPicker)
                }}
              />
            </>
            // <DayPickerInput
            //   formatDate={date => formatDate(date, format, de)}
            //   parseDate={date => parseDate(date, format, de)}
            //   format={format}
            //   value={`${dateFnsFormat(checkedDate, format, de)}`}
            //   hideOnDayClick={true}
            //   dayPickerProps={{
            //     onDayClick: date => {
            //       changeCheckedDate(new Date(date));
            //       isValid();
            //     },
            //     selectedDays: checkedDate,
            //     months: MONTHS,
            //     weekdaysLong: WEEKDAYS_LONG,
            //     weekdaysShort: WEEKDAYS_SHORT,
            //     firstDayOfWeek: 1,
            //   }}
            // />
          ) : (
            <DateInputWrapper>
              <DateInput id="date-input-hidden" 
                value={new Date(checkedDate).toISOString().slice(0,10)} 
                type="date" 
                onChange={({ target: { value } }) => {
                  const newDate = new Date(value);
                  changeCheckedDate(isValidDate(newDate) ? newDate : new Date());
                  isValid();
                }}  />
              <InputContainer
                id="date-input-visible"
                inputWidth="100%"
                style={{ marginTop: 0 }}
                value={formatDate(new Date(checkedDate), format, de)}
              />
            </DateInputWrapper>// todo: change format
          )}
          <IconWrapper>
            <ArrowIcon />
          </IconWrapper>
        </DateContainer>
        {errorMeassges.general ? <ErrorMessage>{t(errorMeassges.general)}</ErrorMessage> : null}
      </Inner>
    </DefaultModal>
  );
};
