import React from 'react';
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';

import voucher_icon from '../../../static/assets/icons/voucher.svg';
import sendmoments_logo from '../../../static/assets/images/sendmoments_logo.svg';
import { toggleModalEnhancedRaw } from "../../../redux/actions/modals";
import { VoucherInnerModal } from "../../Modals/voucherRequireModal";
import { currentUser } from "../../../redux/reducers/user";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: ${({ small, innerHeight }) => small ? innerHeight : 235}px;
  cursor: pointer;
  background: #FAF9F7;
	margin-bottom: ${({ small }) => small ? '5px' : '25px'};
  user-select: none;
`;

const Icon = styled.div`
	width: 40px;
	height: 40px;
	margin: ${({ small }) => small ? '17px' : '32px'} auto 0;
`;

const Title = styled.div`
	margin: 13px auto 0;
  font: ${({ small }) => small ? 'normal normal normal 15px/20px Roboto' : 'normal normal normal 22px/29px Roboto'};
  font-family: ${({ theme }) => theme.font.default};
`;

const Description = styled.div`
	margin: 2px auto 0;
  font: ${({ small }) => small ? 'normal normal 300 12px/25px Roboto;' : 'normal normal 300 16px/25px Roboto'};
	text-align: center;
  font-family: ${({ theme }) => theme.font.default};
`;

const Button = styled.div`
	display: ${({ small }) => small ? 'none' : 'flex'};
	justify-content: center;
	align-items: center;
  width: 180px;
  height: 32px;
  margin: 20px auto 0;
  border-radius: 24px;
  font-size: 14px ;
  font: normal normal normal 13px/18px Roboto;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ color, theme: { palette } }) => palette[color]};
  background-color: #3B3B39;
  &:hover {
    background-color: ${({ theme: { palette } }) => palette['dark']};;
  }
`;

const LogoSendmoments = styled.div`
	display: ${({ small }) => small ? 'none' : 'flex'};
  width: 104px;
  height: 14px;
	margin: 16px auto 16px auto;
`;

export const voucherProps = {
	width: 417,
	height: 235,
	isVoucher: true
}

export const voucherSmallProps = {
	width: 180,
	height: 180,
	isVoucher: true
}


export const VoucherContainer = ({ amount, currency, small, innerHeight, removeVoucherFromList }) => {
	const dispatch = useDispatch();
	const currentUser = useSelector(state => state.currentUser);
	const activeEvent = useSelector(state => state.activeEvent);
	const isMenuCard = activeEvent.evt_mode === 'menu';

	const descriptionText = isMenuCard ? 'Gültig für das ganze sendmoments Sortiment' : 'Gültig für alle sendmoments Foto-Produkte';

	const openModalVoucher = () => {
		const email = currentUser && currentUser.cs_email || false;
		toggleModalEnhancedRaw(dispatch, {
			children: (
				<VoucherInnerModal amount={amount} currency={currency} email={email} removeVoucherFromList={removeVoucherFromList} />
			)
		})
	}


	return (
		<Wrapper small={small} onClick={openModalVoucher} innerHeight={innerHeight}>
			<Icon small={small}>
				<img src={voucher_icon} alt={'icon_voucher'} />
			</Icon>
			<Title small={small}>
				{amount || 0} {currency || '€'} Gutschein
			</Title>

			<Description small={small}>
				{!small ? (
					descriptionText
				) : (
					'> Jetzt sichern <'
				)}
			</Description>

			<Button
				small={small}
				color={'white'}
				backgroundColor={'dark'}
			>
				Jetzt Gutschein sichern
			</Button>

			<LogoSendmoments small={small}>
				<img src={sendmoments_logo} alt={'logo'} width={'100%'} height={'auto'} />
			</LogoSendmoments>
		</Wrapper>
	);
}