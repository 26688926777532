import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { toggleModalInfo } from '../../redux/actions/modals';
import { DefaultModal } from './modal';
import { useTranslation } from 'react-i18next';

const InfoText = styled.div`
  width: 100%;
  padding: 20px 10px;
  color: #000000;
  font-size: 18px;
  word-break: break-word;

  p { margin: 20px 0 }
`;

export const InfoModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalInfo = useSelector(state => state.modalInfo);
  const onCloseModal = () => {
    onClose();
    changeModalState(false);
  };
  const { open, title = '', message = '', onClose = () => {}, buttonText = 'OK', buttons = [ { text: buttonText, marginTop: 30, width: "386px", onClick: onCloseModal } ] } = modalInfo;
  const changeModalState = state => toggleModalInfo(dispatch, state);
  const fillWithBr = (str) => str.split('\n').map(s => [s, <br/>]);
  const possibleTranslationKey = typeof message === 'string' && !/\s|\n/.test(message);
  const content = typeof message === 'string' ? fillWithBr(possibleTranslationKey ? t(message) : message) : message;

  return (
    <DefaultModal 
      modalState={modalInfo && open} 
      modalLabel="info_modal"
      onRequestClose={onCloseModal} 
      title={title}
      buttons={buttons}
    >
      <InfoText>{content}</InfoText>
    </DefaultModal>
  );
};
