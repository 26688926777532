import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, {keyframes} from 'styled-components';

import { addEventPicture } from '../../../redux/actions/gallery';
import {uploadContainer, updateContainer, removeContainer} from '../../../redux/actions/upload';

// import add_images_icon from '../../../static/assets/icons/add_images_icon.svg';
import upload_images_desktop from '../../../static/assets/icons/upload-image-desktop.svg';
// import upload_images_mobile from '../../../static/assets/icons/upload-image-mobile.svg';
import loader_icon from '../../../static/assets/icons/loader_icon.svg';

import {toggleModalInfo, toggleModalPromptRaw} from '../../../redux/actions/modals';
import { useTranslation } from 'react-i18next';
import {PromptModalInner} from "../../Modals/promptModal";

export const UploadPhotoButton = ({ toggler, childrenContainer, bottomPositionMobile = 0, includeOpacity = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeEvent = useSelector(state => state.activeEvent);
  const uploadedData = useSelector(state => state.uploadContainer);

  let wrongFormatList = [];
  let tooBigList = [];
  let otherIssueList = [];

  // Loader data
  const eventID = activeEvent.id;
  const data = uploadedData[eventID] || {};

  let uploadedFiles = 0;
  let totalFiles = 0;
  let uploadedAmount = 0;
  let totalAmount = 0;

  // unprocessed part
  let uploadedFilesProgress = 0;
  let totalFilesProgress = 0;
  let uploadedAmountProgress = 0;
  let totalAmountProgress = 0;

  const dataKeys = Object.keys(data);

  if (dataKeys.length) {
    dataKeys.forEach(e => {
      if (e === 'started') return;
      const tempData = data[e];
      if (tempData.done) uploadedFiles += 1;
      uploadedAmount += tempData.uploaded;
      totalFiles += 1;
      totalAmount += tempData.size;
      if (!data[e].processed) {
        if (tempData.done) uploadedFilesProgress += 1;
        uploadedAmountProgress += tempData.uploaded;
        totalFilesProgress += 1;
        totalAmountProgress += tempData.size;
      }
    });
  }

  // const amountFullUploaded = uploadedAmount >= totalAmount;
  const filesFullProcessed = totalFiles === uploadedFiles;


  let percentUploaded = Math.round((uploadedAmountProgress * 100) / totalAmountProgress);


  if (totalFiles && filesFullProcessed) removeContainer(dispatch, eventID);

  // const cancelUpload = () => {
  //   dataKeys.forEach(e => {
  //     if (e !== 'started') data[e].abort && data[e].abort();
  //   });
  //   removeContainer(dispatch, eventID);
  // };

  // Uploading data
  const inputRef = React.createRef();
  const onInputChange = async e => {
    if (inputRef.current) {
      const files = inputRef.current.files;
      const currentUploading = uploadedData[activeEvent.id];
      const newContainer = {};
      const types = [
        'image/jpeg', 'image/gif', 'image/png', 'image/bmp',
        /*'image/heif', 'image/heic', 'image/heif-sequence', 'image/heic-sequence'*/
      ];
      const extensions = [
        'jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'jfi',
        'gif',
        'png',
        'bmp', 'dib', 'rle',
        /*'heif',
        'heifs',
        'heic',
        'heics'*/
      ];

      if (!currentUploading) newContainer['started'] = new Date().getTime();
      const wasFullUploaded = false;

      // removed files counter
      let removed = 0;
      let validFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { type, name, size } = file;
        const extension = name.split('.').pop().toLowerCase();
        let z = i - removed;
        // file limit part
        const maximumSize = 50 * 1024 * 1024;

        if (size > maximumSize) {
          tooBigList.push(name);
          showError();
          removed += 1;
          continue;
        }

        if (type && types.indexOf(type) === -1 || extensions.indexOf(extension) === -1) {
          wrongFormatList.push(name);
          showError();
          removed += 1;
          continue;
        }

        if (currentUploading) z = Object.keys(currentUploading).length - 1 + i;
        newContainer[z] = { uploaded: 0, size, done: wasFullUploaded };
        validFiles.push([file, z]);
        // uploadFiles(file, z);
      }

      if (newContainer['started'] && Object.keys(newContainer).length === 1) return;

      const newFullContainer = {};
      newFullContainer[activeEvent.id] = { ...currentUploading, ...newContainer };

      uploadContainer(dispatch, { ...newFullContainer });

      inputRef.current.value = '';
      let cancelled;

      for (let arrElem of validFiles) {
        if (cancelled === 449) {
          break;
        } else {
          await uploadFiles(arrElem[0], arrElem[1], cancelled)
            .then(resp => resp.json())
            .catch(err => {
              cancelled = cancelled || err.status;
            });
        }
      }
    }
  };

  const uploadFiles = (file, index, cancelled) => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN ||
      '/'}${`api/photo/upload/${activeEvent.id}`}`;
    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.timeout = 0;
    xhr.withCredentials = true;

    if (cancelled) xhr.abort();

    const formData = new FormData();
    formData.append('file', file);
    const filename = file.name;

    let canceling = null;

    updateContainer(dispatch, activeEvent.id, index, 0, false, () => {
      updateContainer(dispatch, activeEvent.id, index, file.size, true, () => false);
      canceling = true;
      xhr.abort();
    });

    if (canceling) return new Promise.reject({ status: 449 });

    return new Promise(function(resolve, reject) {
      xhr.upload.addEventListener(
        'progress',
        data => {
          if (data.lengthComputable && data.loaded) {
            updateContainer(dispatch, activeEvent.id, index, data.loaded, false, () => {
              if (data.loaded < file.size) {
                updateContainer(dispatch, activeEvent.id, index, file.size, true, () => false);
              }
              canceling = true;
              xhr.abort();
            });
          }
        },
        false,
      );

      xhr.onreadystatechange = () => {
        if (xhr.status == 200 && xhr.readyState == 4) {
          const response = JSON.parse(xhr.response);
          const wasFullUploaded = true;
          updateContainer(dispatch, activeEvent.id, index, file.size, wasFullUploaded, () => false);

          dispatch(addEventPicture(activeEvent.id, response));
          resolve({ status: xhr.status, statusText: xhr.statusText });
        } else if (xhr.readyState === 4 && xhr.status !== 0) {
          // not abort
          if (xhr.status === 413) {
            // size too big 413
            tooBigList.push(filename);
          } else if (xhr.status === 415) {
            // wrong format 415
            wrongFormatList.push(filename);
          } else {
            // other issue
            otherIssueList.push(filename);
            // console.log('oh... stop! not good!');
          }

          showError();
          updateContainer(dispatch, activeEvent.id, index, 0, true, () => false);
          reject({
            status: canceling ? 449 : 0,
            statusText: xhr.statusText,
          });
        } else if (xhr.readyState === 4) {
          if (!canceling) {
            promptForRetry(() => uploadFiles(file, index));

            reject({
              status: 0,
              statusText: xhr.statusText,
            });
          }
          reject({
            status: 449,
            statusText: xhr.statusText,
          });
        }
      };

      xhr.onabort = () => {
        canceling = true;
        reject({
          status: 449,
          statusText: xhr.statusText,
        });
      };

      // 0 stay for infinite, for low internet connection it will keep the pool open and don't cancel the request

      xhr.send(formData);
    });
  };

  const showError = errorsList => {
    const issueToShow = wrongFormatList.length ? 'wrongFormat': tooBigList.length ? 'sizeLimit' : 'otherIssue';

    const closeAction = () => {
      toggleModalPromptRaw(dispatch, false);
      wrongFormatList = [];
      tooBigList = [];
      otherIssueList = [];
    }

    const infoData = {
      wrongFormat: {
        title: 'Diese Bilder konnten nicht hochgeladen werden',
        description: <>Es werden nur folgende Bilddateien unterstützt:<br /> .jpg / .png / .gif / .bmp</>,
        list: wrongFormatList.join(' / ')
      },
      sizeLimit: {
        title: 'Hochladen fehlgeschlagen',
        description: <>Die Bildergröße sollte kleiner als 50 MB sein.<br /> Folgende Fotos sind zu groß und können nicht hochgeladen werden:</>,
        list: tooBigList.join(' / ')
      },
      otherIssue: {
        title: 'Tut uns leid. Es gab einen Fehler beim Hochladen.',
        description: 'Folgende Bilder konnten wir leider nicht hochladen.',
        list: otherIssueList.join(' / ')
      }
    }

    toggleModalPromptRaw(dispatch, {
      showCloseButton: false,
      children: (
        <PromptModalInner
          title={infoData[issueToShow].title}
          description={infoData[issueToShow].description}
          rawDescription={(
            <>
              <StyledList>
                {infoData[issueToShow].list}
              </StyledList>
              <OkButton
                onClick={closeAction}
              >
                Ok
              </OkButton>
            </>
          )}
        />
      ),
    });
  };

  const promptForRetry = onAccept => {
    toggleModalPromptRaw(dispatch, {
      showCloseButton: false,
      children: (
        <PromptModalInner
          title={'Sie scheinen offline zu sein'}
          description={'Bitte überprüfen Sie Ihre Internetverbindung und klicken Sie „Erneut versuchen“. Bei „Abbrechen“ werden alle ausstehenden Uploads abgebrochen.'}
          actionToDo={() => {
            toggleModalPromptRaw(dispatch, false);
            onAccept();
          }}
          onClose={() => toggleModalPromptRaw(dispatch, false)}
          textCancel={t('cancel')}
          textAccept={'Erneut versuchen'}
          btnColors={{
            cancel: {
              cancel: 'dark',
              bgColor: 'white',
              hoverStyle: 'border: 1px solid #000000;'
            },
            accept: {
              color: 'white',
              bgColor: 'newDart',
              hoverStyle: 'background-color: #000000;'
            }
          }}
        />
      ),
    });
  };

  const onUnload = e => {
    const message = 'Wollen Sie die Seite neu laden? Eventuell gehen Daten verloren.';
    e.returnText = message;
    return message;
  };
  useEffect(() => {
    if (Object.keys(uploadedData).length) {
      window.onbeforeunload = onUnload;
    }

    return () => (window.onbeforeunload = null);
  }, [uploadedData]);

  const uploadButtonText = 'Fotos hochladen';
  const uploadingInProgressText = 'Fotos werden hochladen';
  const propsToSend = childrenContainer ? {} : {
    toggler,
    dataKeys,
    percentUploaded,
    uploadingInProgressText,
    uploadButtonText,
    bottomPositionMobile,
    includeOpacity
  }

  const InputContainer = childrenContainer ? childrenContainer : DefaultChildren;

  return (
    <InputContainer {...propsToSend}>
      <StyledInput
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        onChange={onInputChange}
        accept="image/jpeg,image/gif,image/png,image/bmp"
        multiple
      />
    </InputContainer>
  );
};

const DefaultChildren = ({
                           toggler, dataKeys, percentUploaded, uploadingInProgressText, uploadButtonText,
                           children, bottomPositionMobile, includeOpacity
}) => {
  return (
    <Wrapper toggler={toggler} bottomPositionMobile={bottomPositionMobile} includeOpacity={includeOpacity} className={!!bottomPositionMobile && 'in-view'}>

      <UploadButton uploading={dataKeys.length}>

        {
          dataKeys.length ? <UploadImageWrapper>
              <LoaderInner>
                <div className="spinner"/>
                <div className="text">{percentUploaded}%</div>
              </LoaderInner>
            </UploadImageWrapper>
            : <UploadImageWrapper>
              <UploadImage alt="Bilder hochladen" draggable="false"/>
            </UploadImageWrapper>
        }
        <UploadText className={'upload-text'}>
          {dataKeys.length ? uploadingInProgressText : uploadButtonText}
        </UploadText>
      </UploadButton>
      {children}

    </Wrapper>
  )
}

const Wrapper = styled.label`
  // margin: ${({ toggler }) => (toggler ? 'auto' : 'auto calc(50% - 300px) auto auto')};
  margin: auto;
  cursor: pointer;
  padding: 10px;
  //border-radius: 50%;
  bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: #ffffff;
  ${({ theme, bottomPositionMobile, includeOpacity }) => {
    
    return theme.onMD(`
    &.in-view {
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }
    width: 100%;
    position: fixed;
    left: 0;
    bottom: ${bottomPositionMobile || 0}px;
    transition: none;
    opacity: ${includeOpacity ? 0 : 1};
    border-radius: 0;
    margin: 0;
    z-index: 3;
    background-color: #ffffff;
    &:hover {
    img {
      transform: none !important;
    }

    .upload-text::after {
      transform: scaleX(0) !important;
    }
  }
  `)
  }}
  
  &:hover {
    img {
      transform: scale(1.12, 1.12);
    }

    .upload-text::after {
      transform: scaleX(1);
      transform-origin: center;
    }
  }
`;

const StyledInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 100px;
  opacity: 0.001;
  cursor: pointer;
`

const UploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({uploading}) => uploading ? 245: 215}px;
  height: 48px;
  text-align: left;
  //font: normal normal normal 14px/19px Roboto;
  font: normal normal normal 16px/21px Roboto;
  font-family: ${({theme}) => theme.font.default};
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  cursor: pointer;
  border-radius: 24px;
  
  }
  ${({ theme }) => theme.onMD(`
    width: 100%;
  `)}
`;

const UploadText = styled.div`
  //margin-left: 10px;
  position: relative;
  background-color: #ffffff;
  padding: 5px 0;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 4px;
    left: 0;
    background-color: #000000;
    transform-origin: center;
    transition: transform 0.25s ease-out;
  }
`;

/**
 * Image for upload button
 * */

const UploadImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #F7F3ED;
  box-shadow: 0 0 0 10px #ffffff;
  margin: 10px 8px 10px 10px;
`;

const UploadImage = styled.img.attrs(props => {
  return ({
    ...props,
    src: upload_images_desktop,

  });
})`
  //margin-bottom: -3px;
  height: 24px;
  user-select: none;
  cursor: pointer;
  transform: scale(1, 1);
  transform-origin: center;
  transition: transform 0.25s;
`;

/**
 * Loader for upload button
 * */

const rotate = keyframes`
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
`;

const Loader = styled.div`
  //width: 40px;
  //height: 40px;
  //display: inline-block;
  //overflow: hidden;
  //border-radius: 50%;
  //box-shadow: 0 0 0 10px #ffffff;
  //margin: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #F7F3ED;
  box-shadow: 0 0 0 10px #ffffff;
  margin: 10px;
`;

const LoaderInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  background-color: #ffffff;
  div.text {
    position: absolute;
    width: 40px;
    display: flex;
    justify-content: center;
    top: 12px;
    left: 2px;
    font-size: 10px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 40px;
    height: 40px;
    animation: ${rotate} 1s linear infinite;
    background: url(${loader_icon}) 0 0 repeat;
  }
`;

const StyledList = styled.div`
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 25px;
      font: normal normal 300 16px/25px Roboto;
      letter-spacing: 0;
      color: #8F8F8F;
    `;

const OkButton = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 86px;
      height: 38px;
      margin: 37px auto 0 auto;
      color: #ffffff;
      background: #3B3B39;
      border-radius: 20px;
      cursor: pointer;
      &:hover {
        background: #000000;
      }
    `;

