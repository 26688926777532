import { useState, useEffect } from 'react';

export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleVisualViewportResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: visualViewport.height,
      });
    };

    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    if (typeof visualViewport != 'undefined') {
      handleVisualViewportResize();

      visualViewport.addEventListener('resize', handleVisualViewportResize);
    } else {
      handleResize();

      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize)
      visualViewport.removeEventListener('resize', handleVisualViewportResize);
    };
  }, []);
  
  return dimensions;
}