import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { socketConnection } from '../../socket';

import { navigateToFace, showSidebar } from '../../redux/actions/sidebar';
import {toggleModalEvent, toggleModalFastLogin} from '../../redux/actions/modals';
import { toggleModalInvitation } from '../../redux/actions/modals';
import { toggleModalInfo } from '../../redux/actions/modals';
import { showFavorites } from '../../redux/actions/gallery';

import { EventTitle } from '../common/eventTitle';
import { ActionButton } from '../common/actionButton';
import { FullBtnWithIcon } from './parts/fullBtnIcon';

import settings_icon from '../../static/assets/icons/settings_icon.svg';
import participants_icon from '../../static/assets/icons/participants.svg';
import share_icon from '../../static/assets/icons/share.svg';
import plus_icon from '../../static/assets/icons/plus_icon.svg';
import leave_event from '../../static/assets/icons/leave_event.svg';
import memory_list from '../../static/assets/icons/memory_list.svg';
import edit_icon from '../../static/assets/icons/edit_icon.svg';
import basket_icon from '../../static/assets/icons/basket.svg';

import { useHistory } from 'react-router';

import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";

const Wrapper = styled.div`
  padding-top: 80px;
  height: 100%;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  ${({ theme }) =>
    theme.onMD(`
    padding-top: 0px;
  `)}
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  margin-top: -8px;
  margin-left: 15px;
  &:hover {
    cursor: pointer;
  }
  ${({ theme }) =>
  theme.onMD(`
    height: 27px;
  `)}
`;

const Icon = styled.img`
  height: ${({ iconProps }) => iconProps.height || 45}px;
  width: ${({ iconProps }) => iconProps.width || 56}px;
  ${({ theme, mobIconProps }) =>
  theme.onMD(`
    height: ${(mobIconProps && mobIconProps.height) || 35}px;
    width: ${(mobIconProps && mobIconProps.width) || 35}px;
  `)}
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 16px;
  ${({ theme }) =>
    theme.onMD(`
    margin-top: 10px;
    margin-bottom: 0px;
  `)}
`;

export const MainFace = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  const activeEvent = useSelector(state => state.activeEvent);
  const currentUser = useSelector(state => state.currentUser);
  const participants = useSelector(state => state.participantsList);

  const {isMobile} = useWindowWidth();

  const isOwner = activeEvent && participants ? activeEvent.evt_owner_id === currentUser.id : false;
  const changeFace = state => navigateToFace(dispatch, state);
  const changeModalEvent = state => toggleModalEvent(dispatch, state);
  const changeModalInvitation = state => toggleModalInvitation(dispatch, state);
  const changeSidebarState = state => showSidebar(dispatch, state);
  const onlyFavorites = useSelector(state => state.showFavorites);

  const isGuest = currentUser ? currentUser.cs_role === 'guest' : false;

  const leaveEvent = event => {
    const leaveEventFetch = () => {
      const url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/event/leave`;

      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventID: event.id }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            window.location.reload();
          } else if (data.error || data.message) {
            toggleModalInfo(dispatch, {
              title: t('there_was_an_error'),
              message: t(data.error || data.message || ''),
            });
          }
        })
        .catch(err => console.log(err));
    };

    toggleModalInfo(dispatch, {
      title: t('leave_event'),
      message: `Möchten Sie wirklich ${event.evt_name} verlassen? Dadurch verlieren Sie den Zugriff auf das Album und​ alle darin hochgeladenen Fotos.​`,
      buttons: [
        { text: 'Verlassen', onClick: leaveEventFetch },
        { text: t('cancel'), color: 'lightGray', onClick: () => toggleModalInfo(dispatch, false) },
      ],
    });
  };

  const openFastLoginPopup = () => {
    toggleModalFastLogin(dispatch, {open: true});
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <EventTitle mb={0} fontSize={15} text={'Aktuelles Album'} />
        {isOwner &&
        <IconContainer onClick={() => changeModalEvent(activeEvent)}>
          <Icon src={edit_icon} iconProps={{ width: 25, height: 25 }} mobIconProps={{ width: 25, height: 25 }} />
        </IconContainer>
        }
      </TitleWrapper>

      <EventTitle fontSize={17} mb={26} mbMobile={10} text={activeEvent && activeEvent.evt_name} />

      <ActionButton
        iconSrc={participants_icon}
        iconProps={{ width: 36, height: 42 }}
        mobIconProps={{ width: 27, height: 31 }}
        text={t('attendees')}
        click={() => {
          changeFace('guests');
          dispatch(showFavorites(false));
        }}
      />

      {isOwner &&
        <ActionButton
          iconSrc={share_icon}
          iconProps={{ width: 36, height: 37 }}
          mobIconProps={{ width: 28, height: 27 }}
          text={t('share_album')}
          click={() => {
            socketConnection().emit(
              'event_share',
              {
                eventID: activeEvent.id,
                customerID: currentUser.id,
                action: 'code_share',
              },
              data => console.log('data', data),
            );
            if (isMobile && navigator.share) {
              navigator.share({
                title: 'ALBUM TEILEN',
                text: `Hallo zusammen,
  für die Fotos unserer Feier haben wir bei sendmoments ein digitales Fotoalbum angelegt, in dem wir alle unsere Bilder hochladen und sammeln können. 
  Über den Link ${ window.location.origin }/auth/join_event?eventcode=${ activeEvent ? activeEvent.evt_code : '' }
  könnt Ihr dem Album ganz einfach beitreten.​ Viele Grüße und Danke im Voraus für Eure Fotos​ Eure`,
                url: `${ window.location.origin }/auth/join_event?eventcode=${ activeEvent ? activeEvent.evt_code : '' }`
              }).catch((err) => {
                console.log(err);
                // changeModalInvitation(true);
              });
            } else {
              changeModalInvitation(true);
            }
          }}
        />

      }
      <ActionButton
        iconSrc={memory_list}
        iconProps={{ width: 45, height: 37 }}
        mobIconProps={{ width: 33, height: 27 }}
        text={t('favorites')}
        selected={onlyFavorites}
        click={() => {
          if (isGuest) {
            openFastLoginPopup();
          } else {
            changeSidebarState(false);
            dispatch(showFavorites(true));
          }

        }}
      />
      {/*<ActionButton*/}
      {/*  iconSrc={basket_icon}*/}
      {/*  iconProps={{ width: 45, height: 37 }}*/}
      {/*  mobIconProps={{ width: 33, height: 27 }}*/}
      {/*  text={t('create_a_photo_product')}*/}
      {/*  selected={/create/.test(window.location.href)}*/}
      {/*  click={() => {*/}
      {/*    changeSidebarState(false);*/}
      {/*    dispatch(showFavorites(false));*/}
      {/*    history.push('/create');*/}
      {/*  }}*/}
      {/*/>*/}

      {!isOwner && !isGuest && (
          <ActionButton
              iconSrc={leave_event}
              iconProps={{ width: 45, height: 37 }}
              mobIconProps={{ width: 33, height: 27 }}
              text={t('leave_event')}
              click={() => {
                  leaveEvent(activeEvent);
                  dispatch(showFavorites(false));
              }}
          />
      )}

      <div style={{ marginTop: 'auto' }} />
      {!isGuest && (
        <FullBtnWithIcon
          margins={'70px 0 20px 0'}
          bgColor="darkGray"
          text="Alle Alben"
          click={() => changeFace('events')}
        />
      )}
      <ActionButton
        hideBorder={true}
        iconSrc={plus_icon}
        iconProps={{ width: 36, height: 36 }}
        mobIconProps={{ width: 26, height: 26 }}
        text={t('create_new_album')}
        click={() => {
            if (isGuest) {
              openFastLoginPopup();
            } else {
              changeModalEvent(true);
            }
          }
        }
      />
      <ActionButton
        hideBorder={true}
        iconSrc={settings_icon}
        iconProps={{ width: 36, height: 36 }}
        mobIconProps={{ width: 26, height: 26 }}
        text={t('settings')}
        click={() => {
          changeFace('customer');
          dispatch(showFavorites(false));
        }}
      />
    </Wrapper>
  );
};
