import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {toggleModalEnhancedRaw} from '../../redux/actions/modals';
import { DefaultModal } from './modal';



export const EnhancedRawModal = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const modalState = useSelector(state => state.modalEnhanced);
	const changeModalState = state => toggleModalEnhancedRaw(dispatch, state);
	const {children, showCloseButton, fullOnMobile} = modalState;

	return (
		<DefaultModal
			modalState={!!modalState}
			removeCloseBtn={showCloseButton}
			modalLabel="enhanced_raw_modal"
			onRequestClose={() => changeModalState(false)}
			fullOnMobile={fullOnMobile}
		>
			{children}
		</DefaultModal>
	);
};
