import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { showSidebar, navigateToFace } from '../redux/actions/sidebar';

import { SidebarPages } from '../components/Sidebar/animatedFaces';
// import { GuestSidebarPage } from '../components/Sidebar/guestSidebarPage';

// import logo from '../static/assets/icons/logo.svg';
import delete_grey from '../static/assets/icons/delete_grey.svg';
import go_back_grey from '../static/assets/icons/go_back_grey.svg';
import sm_logo from '../static/assets/images/sendmoments.png';
import { useHistory } from 'react-router';

import useWindowWidth from "../utils/useWindowWidth/useWindowWidth";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  width: 469px;
  min-height: 100vh;
  z-index: 2;
  overflow: hidden;
  background-color: ${({ theme: { palette } }) => palette.white};
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.05);
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  ${({ theme, show }) =>
    theme.onMD(`
    width: ${show ? '469px' : '0'};
    max-width: 90%;
    height: calc(100% + 60px);
    padding-bottom: 60px;
    min-height: unset;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    z-index: 5;
    transition: width .25s;
  `)};
`;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { sidebar } }) =>
    `${sidebar.innerPadding.vertical}px ${sidebar.innerPadding.horizontal}px`};
  ${({ theme }) =>
    theme.onMD(`
    flex-grow: 1;
    min-height: unset;
    padding: ${`${theme.sidebar.mobilePadding.vertical}px ${theme.sidebar.mobilePadding.horizontal}px`};
  `)}
`;

const LogoContainer = styled.div`
  height: 50px;
  width: 100%;
  ${({ theme }) =>
    theme.onMD(`
    display: none;
  `)}
`;

const Logo = styled.img`
  max-width: 254px;
  width: 85%;
  margin: auto auto auto ${({ ml }) => typeof ml !== 'undefined' ? ml : 'auto'};
  ${({ hidden }) => hidden ? 'display: none;' : ''}
`;

const MobileNavigation = styled.div`
  display: none;
  //background-color: ;
  ${({ theme }) =>
    theme.onMD(`
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 15px 10px 0;
    position: sticky;
    top: 0;
    z-index: 15;
    background-color: ${theme.palette.white};
    background: linear-gradient(0deg, rgba(255,255,255,95%) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
  `)}
`;

const Icon = styled.img`
  width: 56px;
  height: 56px;
  &:hover {
    filter: invert(100%);
  }
  ${({ theme }) =>
    theme.onMD(`
    width: 26px;
    height: 26px;
  `)}
`;

const NavigationButton = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  justify-content: center;
  align-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: auto;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
    margin-left: 10px;
  }
`;

const BackLayer = styled.div`
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 60px);
  z-index: 4;
  display: none;

  ${({ theme, show }) =>
    theme.onMD(`
    display: ${ show ? 'block' : 'none' };
  `)}
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: ${({ left }) => left }px;
  bottom: 0;
  z-index: 5;
  width: 469px;
  max-width: 90%;
  display: block;
  background-color: rgba(255,255,255,0.5);

  ${({ theme, show }) =>
    theme.onMD(`
    width: ${show ? '469px' : '0'};
    transition: width .25s;
  `)};
`;

export const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {isMobile} = useWindowWidth();

  const sidebarStatus = useSelector(state => state.sidebarStatus);
  const sidebarFace = useSelector(state => state.sidebarFace);
  const currentUser = useSelector(state => state.currentUser);
  // const isGuest = currentUser ? currentUser.cs_role === 'guest' : false;
  const participants = useSelector(state => state.participantsList);
  const currentParticipant = participants && currentUser ? participants.find(p => p.id === currentUser.id) : null;
  const isManager = currentParticipant ? currentParticipant.evp_role === 'manager' : false;

  const changeSidebarState = state => showSidebar(dispatch, state);
  const changeFace = state => navigateToFace(dispatch, state);
  const activeEvent = useSelector(state => state.activeEvent);
  const [showOverlay, setOverlayVisibility] = useState(/create/.test(history.location.pathname));
  const removeSelection = () => {
    const eventLocation = isManager ? `/event/${activeEvent.evt_code}` : '/event';

    if (/create/.test(history.location.pathname)) {
      history.push(eventLocation);
    }
  };

  useEffect(() => {
    const eventLocation = isManager ? `/event/${activeEvent.evt_code}` : '/event';

    if (history.location.pathname !== eventLocation) {
      history.replace(eventLocation);
    }
  }, [isManager, activeEvent]);

  useEffect(() => {
    setOverlayVisibility(/create/.test(history.location.pathname));
  }, [history.location]);

  return (
    <>
      <Wrapper show={sidebarStatus}>
        <MobileNavigation>
          <NavigationButton hidden={sidebarFace === 'main'} onClick={() => changeFace('main')}>
            <Icon src={go_back_grey} />
          </NavigationButton>
          <Logo hidden={sidebarFace !== 'main'} ml={0} src={sm_logo} alt="sidebar_logo" />
          <NavigationButton onClick={() => changeSidebarState(false)}>
            <Icon src={delete_grey} />
          </NavigationButton>
        </MobileNavigation>

        <Container>
          <LogoContainer>
            <Logo src={sm_logo} alt="sidebar_logo" />
          </LogoContainer>

          {/*{ isGuest ?*/}
          {/*  <GuestSidebarPage />*/}
          {/*  : <SidebarPages />*/}
          {/*}*/}

          <SidebarPages />
          
        </Container>
      </Wrapper>
      {showOverlay ?
          <Overlay
              show={!isMobile || sidebarStatus}
              onClick={removeSelection}
              left={Math.max((window.innerWidth - 1920) / 2, 0)}
          />
          : null }
      <BackLayer show={sidebarStatus} onClick={() => changeSidebarState(false)} />
    </>
  );
};
