import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 84px;
  padding-left: 22px;
  font-family: ${({ theme }) => theme.font.default};
  font-size: 21px;
  color: ${({ theme: { palette } }) => palette.dark};
  border-bottom: ${({ hideBorder }) => (!hideBorder ? '1px solid rgba(138, 132, 128, 0.6)' : 'none')};
  ${({ theme }) =>
    theme.onSM(`
    height: 50px;
    font-size: 16px;
  `)}
`;

const Span = styled.span``;

const Icon = styled.img`
  margin: 0 4% 0 auto;
  height: 31px;
  width: 31px;
  flex-shrink: 0;
  cursor: pointer;
`;

export const TextHead = ({ hideBorder, text, onClick, iconSrc }) => {
  return (
    <Container hideBorder={hideBorder} onClick={onClick}>
      <Span>{text}</Span>
      { iconSrc ? <Icon src={iconSrc} /> : null}
    </Container>
  );
};
