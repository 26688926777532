import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  color: ${({ theme: { palette } }) => palette.dark};
  border-bottom: ${({ hideBorder }) => (!hideBorder ? '1px solid rgba(138, 132, 128, 0.6)' : 'none')};
  background-color: ${({ theme: { palette }, selected }) => selected ? palette.middleGray : 'none'};
  
  ${({ hasClick, theme: { palette }, selected }) => hasClick ? `
    cursor: pointer;
    &:hover {
      background-color: ${selected ? palette.middleGray : palette.lotionGray};
    };
  ` : null}
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  padding-left: ${({ hasIcon }) => hasIcon ? 0 : 22 }px;
  flex-grow: 1;
  overflow: hidden;
  flex-shrink: 1;


${({ hasCounter }) => hasCounter ? `
  width: calc(100% - 40px);
` : ''}

  ${({ theme }) =>
    theme.onMD(`
    height: 54px;
  `)}

  ${({ theme, hasCounter }) =>
    theme.onSM(`
    ${hasCounter ? `
      width: calc(100% - 30px);
    ` : ''}
  `)}

`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;

  width: ${({ iconWidth }) => iconWidth || 77}px;
  height: 80px;
  margin: 0 5px 0 0;
  ${({ theme }) =>
    theme.onMD(`
    height: 54px;
    width: 53px;
    padding-left: 12px;
  `)}
  ${({ theme }) =>
    theme.onSM(`
    width: 47px;
  `)}
`;

const Icon = styled.img`
  height: ${({ iconProps }) => (iconProps && iconProps.height) || 31}px;
  width: ${({ iconProps }) => (iconProps && iconProps.width) || 31}px;
  flex-shrink: 0;
  cursor: ${({ cursorType }) => cursorType || 'default'};
  ${({ hoverInvert }) =>
    hoverInvert &&
    ` &:hover {
    filter: invert(100%);
  }`}
${({ theme }) =>
    theme.onMD(`
    height: 30px;
    margin-bottom: 7px;
  `)}
  ${({ theme, mobIconProps }) =>
    theme.onSM(`
    height: ${(mobIconProps && mobIconProps.height) || 20}px;
    width: ${(mobIconProps && mobIconProps.width) || 20}px;
  `)}
`;

const Text = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.font.default};
  font-size: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 2em;
  ${({ theme }) =>
    theme.onSM(`
    font-size: 16px;
  `)}
`;

const Counter = styled.div`
  width: 40px;
  font-family: ${({ theme }) => theme.font.default};
  font-size: 18px;
  text-align: center;
  flex-shrink: 0;

  ${({ theme }) =>
    theme.onSM(`
    font-size: 14px;
      width: 30px;
  `)}
`;

export const UserButton = ({ hideBorder, iconSrc, iconProps, text, click, actionClick, actionIcon, mobIconProps, selected, counter }) => {
  return (
    <Container hideBorder={hideBorder} hasClick={click} selected={selected} hasCounter={!!counter}>
      <LeftContainer onClick={click} hasIcon={!!iconSrc} hasCounter={!!counter}>
       { iconSrc ? 
          <IconContainer>
            <Icon src={iconSrc} iconProps={iconProps} />
          </IconContainer> 
          : null 
        }
        <Text>{text}</Text>
      </LeftContainer>
      { actionIcon ? 
        <IconContainer iconWidth={52}>
          <Icon
            src={actionIcon}
            hoverInvert={true}
            mobIconProps={mobIconProps}
            cursorType="pointer"
            onClick={actionClick}
          />
        </IconContainer>
      : null }
      { typeof counter === 'number' ? <Counter>{counter}</Counter> : null}
    </Container>
  );
};
