import {
  GALLERY_SMALL,
  GALLERY_BIG,
  EVENT_PICTURES,
  REQUEST_EVENT_PICTURES,
  ADD_EVENT_PICTURE,
  DELETE_EVENT_PICTURE,
  NEW_EVENT_PICTURES,
  LOAD_EVENT_PICTURE,
  ERROR_EVENT_PICTURE,
  FAVORITE_EVENT_PICTURE,
  SHOW_FAVORITES,
  FAVORITE_PICTURES,
  ADD_FAVORITE_EVENT_PICTURE,
  ADD_FAVORITE_EVENT_PICTURES,
  REQUEST_FAVORITE_PICTURES,
  DELETE_EVENT_PICTURES,
  INCREASE_FAVORITE_COUNT,
  DECREASE_FAVORITE_COUNT,
  INCREMENT_COMMENTS_COUNT,
  DECREASE_COMMENTS_COUNT,
  TOGGLE_SELECT_MODE,
  SELECT_IMAGE,
  UNSELECT_IMAGE,
  CLEAR_SELECT, ARCHIVE_PHOTO, SELECT_ALL_IMAGES, SELECT_IMAGE_RANGE, SET_DRAG, SET_DRAG_PHOTOS
} from '../types';

const setSmallSize = { type: GALLERY_SMALL, payload: 'small' };
const setBigSize = { type: GALLERY_BIG, payload: 'big' };

export const toggleSize = (dispatch, state) => {
  if (state === 'small') {
    dispatch(setSmallSize);
  } else {
    dispatch(setBigSize);
  }
};

export const eventPictures = (dispatch, getState) => {
  if (typeof getState === 'object') {
    dispatch({ type: EVENT_PICTURES, payload: getState });
  }
};

const requestEventPictures = (eventId, favorites) => {
  return {
    type: favorites ? REQUEST_FAVORITE_PICTURES : REQUEST_EVENT_PICTURES,
    payload: {
      eventId,
      isFetching: true,
    },
  };
};

const receiveEventPictures = (eventId, { totalAmount, pictures }, favorites) => {
  return {
    type: favorites ? FAVORITE_PICTURES : EVENT_PICTURES,
    payload: {
      eventId,
      totalAmount,
      isFetching: false,
      pictures,
    },
  };
};

const receiveNewEventPictures = (eventId, pictures) => {
  return {
    type: NEW_EVENT_PICTURES,
    payload: {
      eventId,
      isFetching: false,
      pictures,
    },
  };
};

const fetchEventPictures = (getState, eventId, favorites, all = false, callback, force) => {

  return async dispatch => {
    dispatch(requestEventPictures(eventId, favorites));

    const { eventPictures, favoritePictures, fetchAmount } = getState();
    const currentPicturesObject = favorites
      ? favoritePictures && favoritePictures[eventId]
      : eventPictures && eventPictures[eventId];
    const currentPictures = currentPicturesObject ? currentPicturesObject.pictures : [];
    const currentAmount = currentPictures instanceof Array ? currentPictures.length : 0;
    const take =
      all && currentPicturesObject
        ? currentPicturesObject.totalAmount - currentAmount + 128000000
        : fetchAmount;

    const picturesUrl = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}${`api/event/${
      favorites ? 'favorite' : 'photos'
    }/${eventId}`}`;
    const result = await fetch(picturesUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ from: force ? 0 : currentAmount, take }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          dispatch(
            receiveEventPictures(
              eventId,
              {
                totalAmount: data.total,
                pictures: data.photos,
              },
              favorites,
            ),
          );
          return data.photos
        } else {
          throw new Error('Fetch was not successful');
        }
      })
      .then((photos) => (callback ? callback(photos) : null))
      .catch(err => console.log(err));

    return result;
  };
};

// const fetchNewlyAddedPictures = (getState, eventId) => {
//   return async dispatch => {
//     dispatch(requestEventPictures(eventId));
//
//     const { eventPictures } = getState();
//     const currentPictures =
//       eventPictures && eventPictures[eventId] && eventPictures[eventId].pictures;
//     const lastAddedPicture = currentPictures ? currentPictures[0] : null;
//
//     const newPicturesUrl = `${process.env.REACT_APP_BACKEND_DOMAIN ||
//       '/'}${`api/event/new_photos/${eventId}`}`;
//     const result = await fetch(newPicturesUrl, {
//       method: 'POST',
//       credentials: 'include',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ lastID: lastAddedPicture ? lastAddedPicture.id : 0 }),
//     })
//       .then(response => response.json())
//       .then(data => {
//         if (data.success && data.photos && data.photos.length) {
//           dispatch(receiveNewEventPictures(eventId, data.photos));
//         }
//       })
//       .catch(err => console.log(err));
//
//     return result;
//   };
// };

// export const checkNewlyAddedPictures = (eventId, callback) => {
  // return (dispatch, getState) => dispatch(fetchNewlyAddedPictures(getState, eventId, callback));
// };

const shouldFetchEventPictures = (state, eventId, favorites) => {
  const picturesObj = favorites
    ? state.favoritePictures && state.favoritePictures[eventId]
    : state.eventPictures && state.eventPictures[eventId];
  if (
    picturesObj &&
    (picturesObj.isFetching || picturesObj.totalAmount <= picturesObj.pictures.length)
  ) {
    return false;
  } else {
    return true;
  }
};

export const fetchEventPicturesIfNeeded = (eventId, favorites, all = false, callback, force = false) => {
  return (dispatch, getState) => {
    if (shouldFetchEventPictures(getState(), eventId, favorites) || force) {
      return dispatch(fetchEventPictures(getState, eventId, favorites, all, callback, force));
    } else if (callback) {
      callback();
    }
  };
};

export const addEventPicture = (eventId, json) => {
  return {
    type: ADD_EVENT_PICTURE,
    payload: {
      eventId,
      picture: json.image,
    },
  };
};

export const deleteEventPicture = (eventId, pictureId) => {
  return {
    type: DELETE_EVENT_PICTURE,
    payload: {
      eventId,
      pictureId,
    },
  };
};

export const deleteEventPictures = (eventId, pictureIds) => {
  return {
    type: DELETE_EVENT_PICTURES,
    payload: {
      eventId,
      pictureIds,
    },
  };
};

export const loadEventPicture = (eventId, pictureId) => {
  return {
    type: LOAD_EVENT_PICTURE,
    payload: {
      eventId,
      pictureId,
    },
  };
};

export const errorEventPicture = (eventId, pictureId) => {
  return {
    type: ERROR_EVENT_PICTURE,
    payload: {
      eventId,
      pictureId,
    },
  };
};

export const setFavoriteEventPicture = (eventId, pictureId, favorite = false) => {
  return {
    type: FAVORITE_EVENT_PICTURE,
    payload: {
      eventId,
      pictureId,
      favorite,
    },
  };
};

export const showFavorites = (payload = false) => {
  return {
    type: SHOW_FAVORITES,
    payload,
  };
};

export const addFavoriteEventPicture = (eventId, picture) => {
  return {
    type: ADD_FAVORITE_EVENT_PICTURE,
    payload: {
      eventId,
      picture,
    },
  };
};

export const addFavoriteEventPictures = (eventId, pictures) => {
  return {
    type: ADD_FAVORITE_EVENT_PICTURES,
    payload: {
      eventId,
      pictures,
    },
  };
};

export const increaseFavoriteCounter = (eventId, photoID, amount, isCurrentCustomer) => {
  return {
    type: INCREASE_FAVORITE_COUNT,
    payload: {
      eventId,
      photoID,
      amount,
      isCurrentCustomer
    }
  }
}

export const decreaseFavoriteCounter = (eventId, photoID, amount, isCurrentCustomer) =>{
  return {
    type: DECREASE_FAVORITE_COUNT,
    payload: {
      eventId,
      photoID,
      amount,
      isCurrentCustomer
    }
  }
}

export const increaseCommentsCounter = (eventId, photoID, amount, isCurrentCustomer) => {

  return {
    type: INCREMENT_COMMENTS_COUNT,
    payload: {
      eventId,
      photoID,
      amount,
      isCurrentCustomer
    }
  }
}

export const decreaseCommentsCounter = (eventId, photoID, amount, isCurrentCustomer) => {

  return {
    type: DECREASE_COMMENTS_COUNT,
    payload: {
      eventId,
      photoID,
      amount,
      isCurrentCustomer
    }
  }
}

export const toggleSelectMode = () => {
  return {
    type: TOGGLE_SELECT_MODE
  }
}

export const selectImage = (id, index) => ({
  type: SELECT_IMAGE,
  payload: {id, index},
});

export const selectRange = (ids, lastSelectedIndex) => ({
  type: SELECT_IMAGE_RANGE,
  payload: { ids, lastSelectedIndex },
});

export const selectAllImages = (images) => ({
  type: SELECT_ALL_IMAGES,
  payload: images,
});

export const unselectImage = (id) => ({
  type: UNSELECT_IMAGE,
  payload: id,
});

export const clearSelection = () => ({
  type: CLEAR_SELECT,
});

export const archiveSelection = (payload) => {
  return {
    type: ARCHIVE_PHOTO,
    payload
  }
}

export const changeDrag = (payload) => {
  return {
    type: SET_DRAG,
    payload
  }
}

export const changeDragPhotos = (payload) => {
  return {
    type: SET_DRAG_PHOTOS,
    payload
  }
}
