import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { FullButton } from './fullBtn';

import sm_logo from '../../static/assets/icons/logo.svg';
import { addEvent, switchEvent } from '../../redux/actions/event';
import { toggleModalCodeActivated, toggleModalInfo } from '../../redux/actions/modals';
import { CodeActivatedModal } from '../Modals/codeActivated';
import { getCurrentUser } from '../../redux/actions/log';
import { InputContainer } from '../forms/parts/inputContainer';

const Logo = styled.img`
  width: 20rem;
  margin-bottom: 40px;
  ${({ theme }) =>
    theme.onSM(`
    margin-bottom: 40%;
    width: 80%;
  `)}
`;

const InvitationDescriptor = styled.div`
${({ hidden }) => hidden ? 'display: none;' : ''};
  margin-top: ${({ hasLogo }) => hasLogo ? '12vh' : '30px'};
  font-size: 1.1em;
  font-family: ${({ theme }) => theme.font.roboto};
  ${({ theme }) =>
    theme.onSM(`
    font-size: 13px;
  `)}
`;

const StyledInputContainer = styled(InputContainer)`
  input {
    text-align: center;
    width: 386px;
    max-width: 100%;
    height: 77px;
    margin-top: 20px;
    font-size: 22px;
    flex-shrink: 0; 
  }
  ${({ theme }) =>
    theme.onSM(`
      input {
        text-align: center;
        height: 56px;
        margin-top: 15px;
        font-size: 14px;
      }
  `)}
`;

const ErrorContainer = styled.div`
  margin-top: 16px;
  color: ${({ theme }) => theme.palette.error};
  font-size: 1.4em;
  font-family: ${({ theme }) => theme.font.roboto};
`;

const ModalHead = styled.div`
  margin: 30px 0;
  font-size: 21px;
  text-align: center;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.roboto};
  color: ${({ theme }) => theme.palette.dark};
  ${({ theme }) =>
    theme.onMD(`
    margin: 25px 0 20px !important;
    font-size: 18px;
  `)}
`;

const InfoMessage = styled.div`
  text-align: center;
`;

export const joinEventCode = (code, onSuccess, onError, loggedIn = true) => {
  const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}${loggedIn ? 'api/event/join' : 'auth/join'}`;
  const data = {
    code: code.trim()
  };

  fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(data => {
      if (data && data.success && data.event) {
        if (typeof onSuccess === 'function') {
          onSuccess(data);
        }
      } else {
        onError(data.message);
      }
    })
    .catch(err => console.log(err));
}

export const ActivateCode = ({ callback, logo = true, info = true }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [insertedCode, changeCode] = useState('');
  const [temporaryEvent, setTemporaryEvent] = useState('');
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const participants = useSelector(state => state.participantsList);
  const currentParticipant = participants && currentUser ? participants.find(p => p.id === currentUser.id) : null;
  const isManager = currentParticipant ? currentParticipant.evp_role === 'manager' : false;
  const addActiveEvent = state => addEvent(dispatch, state);
  const loggedIn = currentUser && !currentUser.error;

  const checkCode = code => {
    joinEventCode(
      code, 
      (data) => {
        if (loggedIn) {
          addActiveEvent(data.event);
          Cookies.set('active_event', data.event.id);

          history.push(isManager ? `/event/${data.event.evt_code}` : '/event');
        } else {
          setTemporaryEvent(data.event);
          Cookies.set('temporary_event', code, {
            expires: new Date(new Date().getTime() + 5 * 60 * 1000)
          });
        }

        if (typeof callback === 'function') {
          callback();
        }

        if (!loggedIn) {
          changeModalCodeActivated(true);
        }
      }, 
      (message) => {
        setErrorMessage(t(message) || '')
      },
     loggedIn
    );
  };

  const loginAsGuest = code => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}auth/join_event`;
    
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventCode: code.trim()
      })
    }).then(() => {
        window.location.reload();
      })
      .catch(err => console.log(err));
  };

  const joinAsGuest = code => {
    joinEventCode(
      code, 
      (data) => {
        if (data && data.event) {
          toggleModalInfo(dispatch, { 
            title: '', 
            message: <>
              <Logo src={sm_logo} />
              <InfoMessage>{`Sie sind dabei die Fotosammlung zu folgendem Event zu betreten:`}</InfoMessage>
              <ModalHead>{`${data.event.name} am ${new Date(data.event.date).toISOString().slice(0, 10)}`}</ModalHead>
            </>,
            buttons: [ 
              { text: t('continue'), color: "mintGreen", marginTop: 20, width: "386px", onClick: () => loginAsGuest(insertedCode) },
              { text: t('cancel'), color: "lightGray", marginTop: 20, width: "386px", onClick: () => toggleModalInfo(dispatch, false) }
            ]
          });
        } else {
          setErrorMessage(t(data.error) || '')
        }
      }, 
      (message) => {
        setErrorMessage(t(message) || '')
      },
     loggedIn
    );
  };

  const cancelErrorMessage = () => setErrorMessage('');
  const changeModalCodeActivated = state => toggleModalCodeActivated(dispatch, state);

  return (
    <>
      {logo ? <Logo src={sm_logo} alt="logo" draggable={false} /> : null}
      <InvitationDescriptor hidden={!info} hasLogo={logo}>{t('insert_invitation')}:</InvitationDescriptor>
      <StyledInputContainer
        value={insertedCode}
        placeholder={t('insert_code')}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            checkCode(insertedCode);
          }
        }}
        onChange={({ target: { value } }) => {
          cancelErrorMessage();
          if (value.length < 9) {
            changeCode(value.toUpperCase());
          }
        }}
        inputWidth="386px"
        onClear={() => changeCode('')}
      />
      {!!errorMessage.length && <ErrorContainer>{t(errorMessage)}</ErrorContainer>}
      {/* { !loggedIn ? 
        <FullButton
          genWith="386px"
          mt={20}
          btnColor="mintGreen"
          text={t('join_as_guset')}
          click={() => joinAsGuest(insertedCode)}
        />
        : null} */}
      <FullButton
        genWith="386px"
        mt={20}
        btnColor="mintGreen"
        text={t('join_event')}
        click={() => checkCode(insertedCode)}
      />
      <CodeActivatedModal event={temporaryEvent} />
    </>
  );
};
