import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 346px;
  max-width: 100%;
  text-align: center;
  color: #636363;
  align-items: center;
  margin: 25px 0;
  font-size: 15px;

  &::before,
  &::after
   { 
      display: inline-block;
      content: '';
      height: 1px;
      background-color: rgba(138, 132, 128, 0.6);
      flex-grow: 1;
      margin: 0 10px 0 0;
    }
  &::after
   { 
      margin: 0 0 0 10px;
    }

  ${({ empty }) => empty ? `
        &::before,
        &::after
        { 
            margin: 0;
        }
    ` : ''
  }
`;

export const Separator = ({ children, style, className }) => {
  const empty = !children;
  return (
    <Container empty={empty} style={style} className={className}>{children}</Container>
  );
};
