import { combineReducers } from 'redux';
import { currentUser, authType } from './user';
import { sidebarStatus, sidebarFace } from './sidebarStatus';
import {
  galleryStatus,
  eventPictures,
  favoritePictures,
  fetchAmount, showFavorites, selectionReducer,
  dragReducer
} from './gallery';
import { activeEvent, eventList, eventTypes } from './event';
import { participantsList, currentParticipant } from './participants';
import {
  modalJoin,
  modalEvent,
  modalInvitation,
  modalForgotPassword,
  modalCodeActivated,
  modalInfo,
  modalGuest,
  modalFastLogin,
  modalImagesSelected,
  modalVisible,
  modalEnhanced,
  promptEnhanced
} from './modals';
import { uploadContainer } from './upload';

export default combineReducers({
  currentUser,
  authType,
  participantsList,
  currentParticipant,
  sidebarStatus,
  sidebarFace,
  galleryStatus,
  eventPictures,
  favoritePictures,
  fetchAmount,
  showFavorites,
  activeEvent,
  eventList,
  eventTypes,
  modalJoin,
  modalGuest,
  modalEnhanced,
  promptEnhanced,
  modalEvent,
  modalFastLogin,
  modalInvitation,
  modalForgotPassword,
  modalCodeActivated,
  modalInfo,
  modalImagesSelected,
  modalVisible,
  uploadContainer,
  selectionReducer,
  dragReducer
});
