import React from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";


// thank_card apply only for thank_you cards for guests
import empty_gallery_thank_card from "../../static/assets/images/empty_gallery_thank_guest.svg";
import empty_gallery_up from "../../static/assets/images/empty_gallery_up.svg";
import empty_gallery_lock from "../../static/assets/images/empty_gallery_lock.svg";
import {UploadPhotoButton} from "./parts/uploadPhotoButton";


export const NothingToShow = ({showFavorites, heart}) => {
	const activeEvent = useSelector(state => state.activeEvent);
	const participant = useSelector(state => state.participantsList);
	const currentUser = useSelector(state => state.currentUser);

	const parentOrigin = window.location.origin.replace('up', 'www').replace('www-dev', 'www');

	const isUpEvent = activeEvent && (activeEvent.evt_mode === 'up');
	const isMenuCard = activeEvent.evt_mode === 'menu';
	const isThankYouCard = activeEvent.evt_mode === 'thank';
	const isHost = currentUser && currentUser.id === activeEvent.evt_owner_id;
	const userFullName = `${currentUser.cs_first_name + (currentUser.cs_last_name ?  ' ' + currentUser.cs_last_name : '')}`;

	const owner = participant.find(e => e.id === activeEvent.evt_owner_id)
	const ownerNickname = owner && `${owner.cs_first_name + (owner.cs_last_name ?  ' ' + owner.cs_last_name : '')}`;

	const general = (
		<UploadPhotoButton bottomPositionMobile={0} childrenContainer={({children}) => (
			<>
			<label>
				<ImageContainer style={{cursor: 'pointer'}}>
					<Image src={empty_gallery_up } alt="no_photo"/>
				</ImageContainer>
				<Title style={{cursor: 'pointer'}}>
					Hier klicken & Fotos hochladen
				</Title>
				{children}
			</label>
				<Description style={{cursor: 'pointer'}}>
					Es befinden sich noch keine Fotos in diesem Album
				</Description>
			</>
		)}
		/>
	);

	const thankYouChildren = (
		<>
			<ImageContainer>
				<Image src={empty_gallery_thank_card} alt="empty_photo"/>
			</ImageContainer>
			<Title>
				Das digitale Fotoalbum ist leer
			</Title>
			<Description>
				{ownerNickname} hat noch keine Bilder in das Album geladen
			</Description>
		</>
	);

	const lockedEventGuest = (
		<>
			<ImageContainer>
				<Image src={empty_gallery_lock} alt="lock_photo"/>
			</ImageContainer>
			<Title>
				 Kein Zugang möglich
			</Title>
			<Description>
				Das digitale Fotoalbum - {activeEvent.evt_name} - wurde leider geschlossen. <br/>
				Möchtest Du <a href={`${parentOrigin}/ueber-digitale-fotoalben`}>mehr über digitale Fotoalben</a> erfahren?
			</Description>
		</>
	);

	return (
		<NothingToShowWrapper id={'gallery-wrapper'}>
			{((activeEvent.evt_lock && !isHost) || activeEvent.evt_cancel) ? lockedEventGuest : (!isHost && isThankYouCard) ? thankYouChildren :  general}
		</NothingToShowWrapper>
	)
}

const NothingToShowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
	height: 100%;
`;

const ImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 160px;
	height: 160px;
	background-color: #faf9f7;
	border-radius: 50%;
`;

const Image = styled.img`
	width: auto;
	height: 130px;
`

const Title = styled.div`
  width: 160px;
	margin-top: 16px;
  text-align: center;
  font: normal normal normal 16px/21px Roboto;
`;

const Description = styled.div`
  width: 433px;
	max-width: 90%;
	margin-top: 38px;
  text-align: center;
  font: normal normal 300 16px/21px Roboto;
  a {
    text-decoration: underline;
    &:hover {
      color: #8f8f8f;
    }
  }
`;