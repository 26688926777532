import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  fetchEventPicturesIfNeeded,
  setFavoriteEventPicture,
  addFavoriteEventPicture,
  toggleSize
} from '../../redux/actions/gallery';

import heart from '../../static/assets/icons/heart.svg';

import { useWindowDimensions } from '../../utils/useWindowDimensions';

import { ScrollToTop } from '../common/scrollToTop';
import { PhotoListTopbar } from './PhotoListTopbar';
import { CustomCarousel } from './parts/customCarousel';
import { GalleryWrapper } from './parts/galleryWrapper';

import { useTranslation } from 'react-i18next';
import {ThankAndMenuTopBar} from "./ThankAndMenuTopBar";
import {NothingToShow} from './NothingToShow';
import {parentPostMessage, messageKeys} from "../../utils/postMessage";
import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";

const { dispatch_modal_open, dispatch_modal_close } = messageKeys;

export const PhotoList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const galleryStatus = useSelector(state => state.galleryStatus);
  const {windowWidth} = useWindowWidth();

  const disableScroll = useSelector(
    state => state.modalVisible || (state.sidebarStatus && windowWidth <= 992),
  );


  const currentUser = useSelector(state => state.currentUser);
  const activeEvent = useSelector(state => state.activeEvent);
  const currentParticipant = useSelector(state => state.currentParticipant);

  const showFavorites = useSelector(state => state.showFavorites);
  const allEventPictures = useSelector(state => state.eventPictures);
  const allFavoritePictures = useSelector(state => state.favoritePictures);
  const {selectMode} = useSelector(state => state.selectionReducer);
  const {draggable} = useSelector(state => state.dragReducer);

  const eventPicturesObj = showFavorites
    ? allFavoritePictures && allFavoritePictures[activeEvent.id]
    : allEventPictures && allEventPictures[activeEvent.id];

  const eventPictures =
    eventPicturesObj &&
    (currentParticipant && eventPicturesObj.pictures
      ? eventPicturesObj.pictures.filter(p => p.ph_customer_id === currentParticipant.id)
      : eventPicturesObj.pictures);

  const isFetching = eventPicturesObj && eventPicturesObj.isFetching;

  const noFetchContent =
    eventPicturesObj &&
    ((eventPicturesObj.pictures
      ? eventPicturesObj.pictures.length >= eventPicturesObj.totalAmount
      : true) ||
      isFetching);
  const hasPictures = eventPictures && eventPictures.length;
  const isGallerySmall = galleryStatus === 'small' || selectMode || draggable;

  const galleryWrapperRef = React.createRef();

  const isUpEvent = activeEvent && (activeEvent.evt_mode === 'up');
  const isMenuCard = activeEvent.evt_mode === 'menu';
  const isHost = currentUser && currentUser.id === activeEvent.evt_owner_id;

  const onToggleFavorite = useCallback(
    (eventID, photoID, favorite) => {
      if (!favorite) {
        const picture =
          allEventPictures &&
          allEventPictures[activeEvent.id] &&
          allEventPictures[activeEvent.id].pictures.find(ep => ep.id === photoID);
        dispatch(addFavoriteEventPicture(eventID, picture));
      }

      dispatch(setFavoriteEventPicture(eventID, photoID, !favorite));
    },
    [allEventPictures, showFavorites, activeEvent],
  );

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);

    setViewerIsOpen(true);

    parentPostMessage(dispatch_modal_open, 'gallery');
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);

    parentPostMessage(dispatch_modal_close, 'gallery');
  };

  const onScroll = () => {
    if (!noFetchContent) {
      const scrolledElement = document.documentElement;
      if (
        scrolledElement &&
        scrolledElement.scrollTop + scrolledElement.clientHeight / 2 >
          scrolledElement.scrollHeight - scrolledElement.clientHeight
      ) {
        dispatch(fetchEventPicturesIfNeeded(activeEvent.id, showFavorites));
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    // set default minimum height to 100px for init loading if is Iframe
    const documentClientHeight = document.documentElement.clientHeight * 1.5 || 100;

    if (
      galleryWrapperRef.current &&
      !noFetchContent &&
      galleryWrapperRef.current.scrollHeight < documentClientHeight
    ) {
      dispatch(fetchEventPicturesIfNeeded(activeEvent.id, showFavorites));
    }

    return () => window.removeEventListener('scroll', onScroll);
  }, [activeEvent.id, isFetching, galleryStatus]);

  useEffect(() => {
    if (showFavorites) {
      dispatch(fetchEventPicturesIfNeeded(activeEvent.id, showFavorites));
    }
  }, [showFavorites, allFavoritePictures && allFavoritePictures[activeEvent.id], activeEvent.id]);

  // const check = useCallback(() => {
  //   if (
  //     activeEvent &&
  //     allEventPictures &&
  //     allEventPictures[activeEvent.id] &&
  //     !allEventPictures[activeEvent.id].isFetching
  //   ) {
  //     dispatch(checkNewlyAddedPictures(activeEvent.id));
  //   }
  // }, [
  //   activeEvent,
  //   allEventPictures,
  //   allEventPictures &&
  //     allEventPictures[activeEvent.id] &&
  //     !allEventPictures[activeEvent.id].isFetching,
  // ]);

  // useEffect(() => {
  //   const checkIntervalRef = setInterval(() => {
  //     check();
  //   }, 30 * 1000);
  //
  //   return () => clearInterval(checkIntervalRef);
  // }, [check]);

  // prevent body scrolling on modal visible
  const { height } = useWindowDimensions();

  useEffect(() => {
    Object.assign(
      document.body.style,
      disableScroll ? { overflow: 'hidden', height: `${height}px` } : { overflow: '', height: '' },
    );

    return () => Object.assign(document.body.style, { overflow: '', height: '' });
  }, [height, disableScroll]);

  useEffect(() => {
    if (isHost && !isUpEvent) {
      toggleSize(dispatch, 'small');
    }
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <Wrapper  empty={!hasPictures} onScroll={onScroll} >
        {isUpEvent ? <PhotoListTopbar sizeToggler={hasPictures} /> :  <ThankAndMenuTopBar />}

        <ScrollToTop bottomBar={false} />

        {hasPictures && eventPictures[currentImage] && !activeEvent.evt_cancel ? (
          <>

              <GalleryWrapper
                small={isGallerySmall}
                pictures={eventPictures}
                isUpEvent={isUpEvent}
                isHost={isHost}
                isMenuCard={isMenuCard}
                onClick={openLightbox}
                eventId={activeEvent.id}
                filterByOwner={false}
                onToggleFavorite={onToggleFavorite}
                forwardRef={galleryWrapperRef}
              />

            <CustomCarousel
              viewerIsOpen={viewerIsOpen}
              setViewerIsOpen={setViewerIsOpen}
              pictures={eventPictures}
              currentIndex={currentImage}
              eventId={activeEvent.id}
              onClose={closeLightbox}
              onDelete={closeLightbox}
              onEndOfList={() => {
                dispatch(fetchEventPicturesIfNeeded(activeEvent.id))
              }}
              totalAmount={eventPicturesObj.totalAmount}
              onToggleFavorite={onToggleFavorite}
            />
          </>
        ) : (
          <NothingToShow showFavorites={showFavorites} heart={heart} />
        )}
      </Wrapper>
    </DndProvider>
  );
};



const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  max-height: ${({ empty }) => (empty ? '100%' : 'unset')};
  user-select: none;
`;
