import React, { useState, isValidElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { toggleModalForgotPassword } from '../../redux/actions/modals';
import { DefaultModal } from './modal';
// import { FullButton } from '../common/fullBtn';
import { validate } from '../../utils/validateForms';
//
// const ModalHead = styled.div`
//   margin-bottom: 20px;
//   font-size: 21px;
//   text-transform: uppercase;
//   font-family: ${({ theme }) => theme.font.default};
//   color: ${({ theme }) => theme.palette.dark};
//   ${({ theme }) =>
//     theme.onMD(`
//     margin: 25px 0 20px !important;
//     font-size: 18px;
//   `)}
// `;

const InvitationDescriptor = styled.div`
  width: 100%;
  margin-top: ${({ mt }) => mt || 20}px;
  font-size: 1.1em;
  font-family: ${({ theme }) => theme.font.default};
  ${({ theme }) =>
    theme.onSM(`
    margin-top: 30px;
    font-size: 14px;
  `)}
`;

const InputContainer = styled.input`
  text-align: center;
  width: 386px;
  max-width: 100%;
  height: 77px;
  margin-top: 20px;
  border: 2px solid ${({ theme }) => theme.palette.lightGray};
  font-size: 22px;
  font-family: ${({ theme }) => theme.font.default};
  &:focus {
    outline: none !important;
    border: 2px solid rgba(128, 180, 171, 0.5);
  }
  ${({ theme }) =>
    theme.onSM(`
    height: 56px;
    margin-top: 15px;
    font-size: 14px;
    &:focus {
      border-width: 1px;
    }
  `)}
`;

const ErrorContainer = styled.div`
  margin-top: 16px;
  color: ${({ theme }) => theme.palette.error};
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.default};
  ${({ theme }) =>
    theme.onMD(`
    font-size: 14px;
  `)}
`;

const InfoContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  color: #000000;
  font-size: 18px;
`;

export const ForgotPasswordModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const modalState = useSelector(state => state.modalForgotPassword);
  const changeModalState = state => toggleModalForgotPassword(dispatch, state);
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [email, setEmail] = useState('');


  const isValid = (key, value) => {
    let valid = true;
    let message = '';

    const result = validate({[key]: value});

    if (result[key]) {
      valid = result[key].success;
      message = t(result[key].error) || '';
    }

    setErrorMessage(message);

    return valid;
  };

  const restoreReq = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'auth/service'}`;

    if (isValid('email', email)) { 
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
              setInfoMessage(t(data.message));
          } else {
              setErrorMessage(t(data.message));
          }
        })
        .catch(err => console.log(err));
    }
  };

  const onCloseModal = () => {
    setInfoMessage('');
    setEmail('');
    setErrorMessage('');
    changeModalState(false);
  };

  return (
    <DefaultModal 
      modalState={modalState} 
      modalLabel="forgot-password modal" 
      onRequestClose={onCloseModal}
      title={infoMessage ? 'Vielen Dank!' : t('forgot_password')}
      buttons={infoMessage ?  [
        { 
          text: "OK", 
          width: "386px", 
          marginTop: 30, 
          onClick: onCloseModal 
        }
      ] : [{ text: "Passwort jetzt anfordern", with: "386px", marginTop: 30, onClick: restoreReq }] }
    >
      { infoMessage ? 
        <>
          <InfoContainer>{t(infoMessage)}</InfoContainer> 
        </>
        : 
        <>
            <InvitationDescriptor mt={20}>Bitte geben Sie eine gültige E-Mail Adresse ein. *</InvitationDescriptor>
            <InputContainer
                type="text"
                name="email"
                key="email"
                value={email}
                onChange={
                  ({ target: { value } }) => { 
                    setEmail(value);
                    isValid('email', value);
                  }
                }
                placeholder="E-Mail"
            />

            {!!errorMessage.length && <ErrorContainer>{t(errorMessage)}</ErrorContainer>}
        </>
      }   
    </DefaultModal>
  );
};
