import React from 'react';
import {useSelector, useDispatch, batch} from 'react-redux';
import styled, {keyframes} from 'styled-components';

import trash_can from '../../../static/assets/icons/trash.svg';
import download from '../../../static/assets/icons/download_new.svg';
import cross from '../../../static/assets/icons/cross_new.svg';
import loader_icon from '../../../static/assets/icons/loader_icon.svg';

import { useTranslation } from 'react-i18next';
import {archiveSelection, clearSelection, toggleSelectMode, unselectImage} from "../../../redux/actions/gallery";
import {toggleModalEnhancedRaw} from "../../../redux/actions/modals";

import useWindowWidth from "../../../utils/useWindowWidth/useWindowWidth";

export const ActionInnerModal = ({actionRequest, closeModalEnhancedRaw, header, descriptionTextRows, cancelText, onText }) => {
	const { t } = useTranslation();
	const {isMobile} = useWindowWidth();

	return (
		<>
			<DescriptionHead>{header}</DescriptionHead>
			{descriptionTextRows.map(text => (
				<DescriptionText key={text}>
					{text}
				</DescriptionText>
			))}
			<ButtonsContainer isMobile={isMobile}>
				<Button
					color={'dark'}
					backgroundColor={'white'}
					onClick={closeModalEnhancedRaw}
					hoverStyle={'border: 1px solid #000000;'}
				>
					{cancelText}
				</Button>
				<Button
					color={'white'}
					backgroundColor={'newDart'}
					onClick={actionRequest}
					hoverStyle={'background-color: #000000'}
				>
					{onText}
				</Button>
			</ButtonsContainer>
		</>
	);
}

export const SelectImagesActions = ({bottomPositionMobile, includeOpacity}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const activeEvent = useSelector(state => state.activeEvent);
	const currentUser = useSelector(state => state.currentUser);
	const allEventPictures = useSelector(state => state.eventPictures);

	const { selectedImages, archive } = useSelector(state => state.selectionReducer);

	const isOwner = currentUser.id == activeEvent.evt_owner_id;

	const photos = allEventPictures[activeEvent.id] && allEventPictures[activeEvent.id].pictures || [];

	const selectedImagesIds = photos.reduce((acc, photo) => {
		if (photo.order) {
			if (selectedImages.includes(photo.order))acc.push(photo.id);
		} else if (selectedImages.includes(photo.id)) {
			acc.push(photo.id);
		}
		return acc;
	}, []);

	console.log(selectedImagesIds)
	const multipleDelete = async () => {
		if (!selectedImagesIds.length) {
			return;
		}

		toggleModalEnhancedRaw(dispatch, {
			children: (
				<ActionInnerModal
					actionRequest={async () => {
						const removed = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/multi_delete`, {
							method: 'POST',
							credentials: 'include',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								eventID: activeEvent.id,
								ids: selectedImagesIds
							})
						}).then(resp => resp.json()).catch(e => console.log('error on multi_delete: ', JSON.stringify(e)));

						if (removed && removed.success && removed.ids) {
							const {ids} = removed;
							batch(() => {
								ids.forEach(id => {
									dispatch(unselectImage(id));
								})
							})
						}
						dispatch(clearSelection())
						toggleModalEnhancedRaw(dispatch, false)
					}}
					closeModalEnhancedRaw={() => toggleModalEnhancedRaw(dispatch, false)}
					header={'Foto löschen'}
					descriptionTextRows={[`Möchtest Du wirklich ${selectedImagesIds.length} Foto(s) entfernen?`]}
					cancelText={t('cancel')}
					onText={t('delete')}
				/>
			)
		})
	}

	const downloadPhotos = () => {

		if (!selectedImagesIds.length) {
			return;
		}

		toggleModalEnhancedRaw(dispatch, {
			children: (
				<ActionInnerModal
					actionRequest={async () => {
						const link = document.createElement('a');
						link.href = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/archive/${activeEvent.id}?photo=${selectedImagesIds.join(',')}`;

						document.body.appendChild(link);
						link.click();

						document.body.removeChild(link);

						toggleModalEnhancedRaw(dispatch, false)

						dispatch(archiveSelection(
						{upload: 0, totalPhotos: selectedImagesIds.length}
						))
					}}
					closeModalEnhancedRaw={() => toggleModalEnhancedRaw(dispatch, false)}
					header={'Fotos herunterladenn'}
					descriptionTextRows={[`Möchtest Du jetzt ${selectedImagesIds.length} Foto(s) downloaden?`]}
					cancelText={t('cancel')}
					onText={'Herunterladen'}
				/>
			)
		})
	}

	const discardSelect = () => {
		const discard = () => {
			dispatch(clearSelection())
			dispatch(toggleSelectMode())
		}

		if (!selectedImagesIds.length) {
			discard();
			return;
		}

		toggleModalEnhancedRaw(dispatch, {
			children: (
				<ActionInnerModal
					actionRequest={async () => {
						discard();

						toggleModalEnhancedRaw(dispatch, false)
					}}
					closeModalEnhancedRaw={() => toggleModalEnhancedRaw(dispatch, false)}
					header={'Auswahl zurücksetzen'}
					descriptionTextRows={[`Deine Auswahl geht verloren.`, `Du gelangst zurück zum Fotoalbum.`]}
					cancelText={t('cancel')}
					onText={'Zurücksetzen'}
				/>
			)
		})
	}

	return (
		<Wrapper bottomPositionMobile={bottomPositionMobile} includeOpacity={includeOpacity} className={!!bottomPositionMobile && 'in-view'}>

			{archive && archive.totalPhotos ? (
				<div style={{display: 'flex', margin: '0 auto'}}>
					<ActionButton uploading={0}>
						<UploadImageWrapper bg={false} width={'40px'}>
							<LoaderInner>
								<div className="spinner"/>
								<div className="text">{Math.round((archive.upload / archive.totalPhotos) * 100)}%</div>
							</LoaderInner>
						</UploadImageWrapper>
					</ActionButton>
					<ActionButton uploading={0}>
						<UploadImageWrapper bg={false}>
							<TextData>{archive.upload} von {archive.totalPhotos} Fotos heruntergeladen</TextData>
						</UploadImageWrapper>
					</ActionButton>
				</div>
			) : (
				<>
					<div>
						<ActionButton uploading={0}>
							<UploadImageWrapper bg={false}>
								<TextData>{selectedImagesIds.length} ausgewählt</TextData>
							</UploadImageWrapper>
						</ActionButton>
					</div>

					<div style={{display: 'flex'}}>
						<ActionButton onClick={() => downloadPhotos()}>

							<UploadImageWrapper bg={true} width={'40px'}>
								<ActionImage src={download} alt="download" draggable="false"/>
							</UploadImageWrapper>
						</ActionButton>

						{isOwner && (
							<ActionButton uploading={0} onClick={() => multipleDelete()}>
								<UploadImageWrapper bg={true} width={'40px'}>
									<ActionImage src={trash_can} height={28} alt="delete" draggable="false"/>
								</UploadImageWrapper>
							</ActionButton>
						)}


						<ActionButton onClick={() => discardSelect()}>
							<UploadImageWrapper bg={true} width={'40px'}>
								<ActionImage src={cross} height={34} alt="delete" draggable="false"/>
							</UploadImageWrapper>
						</ActionButton>
					</div>
				</>
				)}
		</Wrapper>
	);
};


const Wrapper = styled.label`
  margin: auto;
  cursor: pointer;
  padding: 10px;
  //border-radius: 50%;
  bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: #ffffff;
  ${({ theme, bottomPositionMobile, includeOpacity }) => {

    return theme.onMD(`
    &.in-view {
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }
    width: 100%;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: ${bottomPositionMobile || 0}px;
    transition: none;
    opacity: ${includeOpacity ? 0 : 1};
    border-radius: 0;
    margin: 0;
    z-index: 3;
    background-color: #ffffff;
  `)
  }}
`;

const rotate = keyframes`
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
`;

const LoaderInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  background-color: #ffffff;
  div.text {
    position: absolute;
    width: 40px;
    display: flex;
    justify-content: center;
    top: 12px;
    left: 2px;
    font-size: 10px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 40px;
    height: 40px;
    animation: ${rotate} 1s linear infinite;
    background: url(${loader_icon}) 0 0 repeat;
  }
`;

const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 48px;
  text-align: left;
  //font: normal normal normal 14px/19px Roboto;
  font: normal normal normal 16px/21px Roboto;
  font-family: ${({theme}) => theme.font.default};
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  cursor: pointer;
  border-radius: 24px;
  &:hover {
    img {
      transform: scale(1.12, 1.12);
    }

    .upload-text::after {
      transform: scaleX(1);
      transform-origin: center;
    }
  }
`;

/**
 * Image for upload button
 * */

const UploadImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({width}) => width ? width : 'auto'};
  height: 40px;
  border-radius: 20px;
  background-color: ${({bg}) => bg ? '#F7F3ED' : 'none'} ;
  box-shadow: 0 0 0 10px #ffffff;
  margin: 10px 8px 10px 10px;
`;

const ActionImage = styled.img`
  //margin-bottom: -3px;
  height: ${({height}) => height || 24}px;
  user-select: none;
  cursor: pointer;
  transform: scale(1, 1);
  transform-origin: center;
  transition: transform 0.25s;
`;

const TextData = styled.div`
	display: flex;
	align-items: center;
  height: 40px;
  user-select: none;
  cursor: pointer;
	background-color: #fff;
  font: normal normal 300 14px/19px Roboto;
  transform: scale(1, 1);
  transform-origin: center;
  transition: transform 0.25s;
`;

/**
 * Loader for upload button
 * */

const DescriptionHead = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: ${({theme}) => theme.font.default};
  color: ${({theme}) => theme.palette.dark};
  ${({theme}) =>
          theme.onMD(`
    margin: 35px 0 25px !important;
    font-size: 18px;
  `)}
`;

const DescriptionText = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal 300 16px/25px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;


const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({isMobile}) => isMobile ? 'column': 'row'};
  width: 355px;
  margin: 35px auto 0 auto;
  ${({theme}) =>
	theme.onMD(`
      width: 100%;
      height: 111px;
  `)}
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 160px;
  height: 38px;
  line-height: 36px;
  overflow: hidden;
  vertical-align: center;
  border: 1px solid #D1D3D4;
  border-radius: 19px;
  font-size: 14px ;
  font-family: ${({theme}) => theme.font.default};
  color: ${({ color, theme: {palette}}) => palette[color]};
  background-color: ${({ backgroundColor, theme: {palette} }) => palette[backgroundColor]};
  cursor: pointer;
  &:hover {
    ${({hoverStyle}) => hoverStyle};
  }
  
  ${({theme}) =>
	theme.onMD(`
      width: 100%;
      height: 48px;
      line-height: 46px;
      border-radius: 24px;
  `)}
`;
