import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {  toggleModalEnhancedRaw } from '../../../redux/actions/modals';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {HeartIconSVG} from "../../icons/GalleryPhotoIcons";
import participantIcon from '../../../static/assets/icons/album_participant.svg'
import useWindowWidth from "../../../utils/useWindowWidth/useWindowWidth";

const ModalContainer = styled.div`
	width: 100%;
	height: 100%;
  overflow-x: hidden;
`;

const HeaderContainer = styled.div`
	background-color: #ffffff;
	${({theme, isMobile}) => isMobile && theme.onMD(`
		width: 100%; 
		position: fixed; 
		top:0; left:0; 
		border-bottom: 1px solid #D1D3D4;
		overflow: hidden;
	`) }
`;

const DescriptionHead = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: ${({theme}) => theme.font.default};
  color: ${({theme}) => theme.palette.dark};
  ${({theme}) =>
	theme.onMD(`
    margin: 9px 17px;
    font-size:14px;
    text-align: left;
  `)}
`;

const MobileCloseBtn = styled.div`
	display: none;
	${({theme, isMobile}) => isMobile && theme.onMD(`
		display: block;
		position: fixed;
		top: 0;
		right: 0;
		padding: 9px 17px;
		font-size: 14px;
		color: #8F8F8F;
		background-color: transparent;
	`)}
`;

const InnerContainer = styled.div`
${({theme}) =>
theme.onMD(`
        margin-top:  37px;
  `)}
`

const DescriptionText = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal 300 16px/25px Roboto;
  font-family: ${({theme}) => theme.font.default};
  ${({theme}) =>
          theme.onMD(`
				font-size: 14px;
				text-align: left;
  `)}
`;



const ParticipantsContainer = styled.div`
	width: 100%;
	max-width: 355px;
	max-height: 395px;
	display: flex;
	flex-direction: column;
	margin: 15px auto 20px;
	${({theme}) => theme.onMD(`
		max-height: calc(100% - 50px - 30px - 45px);
	`)}
`;

const HeaderList = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
  width: 100%;
  height: 50px;
  background-color: #F7F3ED;
`;

const TitleColumn = styled.div`
	height: 50px;
	line-height: 50px;
  text-align: center;
`;

const TitleText = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: auto 10px auto;
  font: normal normal normal 14px/20px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;

const IconContainer = styled.div`
	width: 24px;
	height: 24px;
	margin: auto 13px;
`;


const ParticipantsItemContainer = styled.div`
	width: 100%;
	overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
	${({theme}) => theme.onMD(`
		overflow-y: scroll;
	`)}
`;
const ParticipantListWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 70px;
	justify-content: left;
	align-items: center;
	${({isLast}) => !isLast && 'border-bottom: 1px solid #d1d3d4;' }
`;

const ParticipantIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
  width: 40px;
  height: 40px;
	margin: 0 10px;
  border-radius: 50%;
	background-color: #FAF9F7;
`;

const ParticipantNickName = styled.div`
  font: normal normal normal 14px/19px Roboto;
  font-family: ${({theme}) => theme.font.default};
	margin-left: 20px;
`;

export const ParticipantsFavoriteModal = ({eventID, photoID}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {windowWidth, isMobile} = useWindowWidth();
	const [isLoading, changeIsLoading] = useState(true);
	const [participantsList, changeParticipantsList] = useState([])

	useEffect(() => {
		changeIsLoading(true);

		const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/get_photo_participants_favorite?eventID=${eventID}&photoID=${photoID}`;

		fetch(url, {
			method: 'GET',
			credentials: "include",
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(response => response.json())
			.then(resp => {
				if (resp.success) {
					changeParticipantsList(resp.data)
				}
			})
			.catch(err => console.log(err));
	},[])


	const closeModalEnhancedRaw = () => {
		toggleModalEnhancedRaw(dispatch, false);
	}

	return (
		<ModalContainer>
			<HeaderContainer windowWidth={windowWidth} isMobile={isMobile}>
				<DescriptionHead>Gefällt mir {participantsList && participantsList.length && `${'(' + participantsList.length + ')'}` || 0}</DescriptionHead>
				<MobileCloseBtn onClick={closeModalEnhancedRaw} isMobile={isMobile}>Abbrechen</MobileCloseBtn>
			</HeaderContainer>
			<InnerContainer>
				<DescriptionText>
					Die Liste der Personen, denen dieses Bild gefällt, wird ausschließlich Dir angezeigt.
				</DescriptionText>
			</InnerContainer>
		<ParticipantsContainer>
			{participantsList && participantsList.length && (
				<>
				<HeaderList>

					<TitleColumn><TitleText>Name</TitleText></TitleColumn>
					<IconContainer>
						<HeartIconSVG />
					</IconContainer>
				</HeaderList>
					<ParticipantsItemContainer>
						{participantsList.length && participantsList.map((participant, i) => {
							const isLast = i === participantsList.length - 1;
							return (
								<ParticipantListWrapper isLast={isLast} key={i}>
									<ParticipantIcon>
										<img src={participantIcon} alt='participant-icon'/>
									</ParticipantIcon>
									<ParticipantNickName>
										{participant.customer.cs_first_name} {participant.customer.cs_last_name}
									</ParticipantNickName>
								</ParticipantListWrapper>
							);
						})}
					</ParticipantsItemContainer>
				</>
			)}
		</ParticipantsContainer>
		</ModalContainer>
	);
}
