import Cookies from "js-cookie";

let modalData = {
	gallery: false,
	info_modal: false,
	event_invitation: false,
	generate_event: false,
	guest_nickname_modal: false,
	fast_login_modal: false,
	enhanced_raw_modal: false,
	prompt_enhanced: false
}

let prevCountedHeight = 0;

export const getInnerHeight = () => {
	// const body = document.body;
	// const html = document.documentElement;

	const galleryWrapperHeight = document.getElementById('gallery-wrapper');
	const additionalHeight = 130;

	const galleryHeight = galleryWrapperHeight ?  galleryWrapperHeight.getBoundingClientRect().height : 0;

	// const maxBody = Math.max(body.scrollHeight, body.offsetHeight,
	// 	html.clientHeight, html.scrollHeight, html.offsetHeight, 700);


	// return Math.min(maxBody, );
	return galleryHeight + additionalHeight;
}

const messages = {
	dispatch_modal_open: () => ({
		type: 'dispatch_modal_open'
	}),
	dispatch_modal_close: () => ({
		type: 'dispatch_modal_close'
	}),
	dispatch_reload_page: () => ({
		type: 'dispatch_reload_page',
	}),
	dispatch_gallery_height: (reqSetTrueHeight) => {
		let maxHeight = reqSetTrueHeight ? getInnerHeight() : '100%';
		prevCountedHeight = maxHeight;

		return {
			type: 'dispatch_gallery_height',
			data: {
				height: maxHeight,
				reqVisualViewPort: reqSetTrueHeight
			}
		}
	},
}

export const modalTypes = Object.keys(modalData).reduce((acc, e) => {
	acc[e] = e
	return acc;
},{});

export const messageKeys = Object.keys(messages).reduce((acc, e) => {
	acc[e] = e
	return acc;
},{});

export const parentPostMessage = async (message, modal, lastCall, reqSetTrueHeight, isHost, data) => {

	const originToSend = Cookies.get('ref');

	const isIframe = window !== window.top && originToSend;

	if (!isIframe) return;

	let messageToSend;

	if (modal && modalTypes[modal]) {
		const isOpen = message === messageKeys.dispatch_modal_open;
		const includeOpenModalBefore = Object.values(modalData).includes(true);

		modalData = {
			...modalData,
			[modal]: isOpen
		}

		const includeOpenModalAfter = Object.values(modalData).includes(true)

		if (includeOpenModalBefore !== includeOpenModalAfter)  messageToSend = !!messages[message] && messages[message]();

	} else if (message === messageKeys.dispatch_gallery_height) {
		const heightRequireUpdate  = getInnerHeight() !== prevCountedHeight;

		if ((reqSetTrueHeight && !isHost) || isHost) {
			messageToSend = !!messages[message] && messages[message](true);
		} else  {

			messageToSend = !!messages[message] && messages[message](false);
		}

		if (!lastCall) delayedResizeHeight(reqSetTrueHeight, isHost)

		// messageToSend = !!messages[message] && messages[message]();
	} else if (message === messageKeys.dispatch_modal_close) {

		messageToSend = !!messages[message] && messages[message]();
	} else if (message === 'dispatch_scroll_top_button_position') {
		messageToSend = {
			type: 'dispatch_scroll_top_button_position',
			data: {
				hide: data
			}
		}
	} else if (message === 'dispatch_gallery_sort_mode') {
		messageToSend = {
			type: 'dispatch_gallery_sort_mode',
			data: {
				enable: !!data
			}
		}
	} else {
		messageToSend = !!messages[message] && messages[message]();
	}

	messageToSend && parent.postMessage(JSON.stringify(messageToSend), `${originToSend}/`)
}

const delayedResizeHeight = (reqSetTrueHeight, isHost) => {
	setTimeout(() => {
		parentPostMessage(messageKeys.dispatch_gallery_height, false, true, reqSetTrueHeight, isHost);
	}, 100)
}