import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import { FullButton } from '../components/common/fullBtn';
import { ErrorMessage } from '../components/common/errorMessage';
import { InfoMessage } from '../components/common/infoMessage';

import sm_logo from '../static/assets/icons/logo.svg';
import { toggleModalInfo } from '../redux/actions/modals';

const Logo = styled.img`
  width: 20rem;
  ${({ theme }) =>
    theme.onSM(`
    margin-bottom: 40%;
    width: 80%;
  `)}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 386px;
  max-width: 100%;
  min-height: 100%;
  padding: 20px 0;
  ${({ theme }) =>
    theme.onSM(`
    justify-content: flex-end;
    padding: 40px;
  `)}
`;

const InvitationDescriptor = styled.div`
  width: 100%;
  margin-top: ${({ mt }) => mt || 60}px;
  font-size: 1.1em;
  font-family: ${({ theme }) => theme.font.default};
  ${({ theme }) =>
    theme.onSM(`
    margin-top: 30px;
    font-size: 14px;
  `)}
`;

const InputContainer = styled.input`
  text-align: center;
  width: 386px;
  max-width: 100%;
  height: 77px;
  margin-top: 20px;
  border: 2px solid ${({ theme }) => theme.palette.lightGray};
  font-size: 22px;
  font-family: ${({ theme }) => theme.font.default};
  &:focus {
    outline: none !important;
    border: 2px solid rgba(128, 180, 171, 0.5);
  }
  ${({ theme }) =>
    theme.onSM(`
    height: 56px;
    margin-top: 15px;
    font-size: 14px;
    &:focus {
      border-width: 1px;
    }
  `)}
`;

export const RestorePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [passwordCopy, changePasswordCopy] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [copyError, setCopyError] = useState('');
  const [data, setData] = useState({
    email: '',
    hash: '',
    password: ''
  });
  

  const changeData = (name, value) => {
    const newData = {
      ...data,
    };
    newData[name] = value;
    setData(newData);
  };

  const checkCopy = () => {
    let error = '';

    if (passwordCopy && data.password !== passwordCopy) {
      error = 'Passwörter stimmen nicht überein.';
    }

    setCopyError(error);

    return data.password === passwordCopy;
  };

  const redirectToLogin = () => history.push('/login');

  const changePassReq = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'auth/restore'}`;

    if (checkCopy()) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            toggleModalInfo(dispatch, {
              title: 'Passwort wurde erfolgreich geändert',
              message: 'Jetzt können Sie sich mit einem neuen Passwort anmelden',
              onClose: redirectToLogin
            });
          } else if (data.error || data.message) {
            if (data.error === 'incorrect_data_format') {
              setCopyError('Falsche Daten. Möglicherweise müssen Sie den Wiederherstellungslink aus Ihrer E-Mail erneut verwenden.');
            } else if (data.message === 'invalid_change_password_hash') {
              setCopyError('Falsche Daten. Möglicherweise ist der Wiederherstellungslink aus Ihrer E-Mail bereits veraltet.');
            } else {
              setCopyError(t(data.error || data.message));
            }
          }
        })
        .catch(err => console.log(err));
    }
  };

  useEffect(() => {
    const email = Cookies.get('restore_email');
    const hash = Cookies.get('restore_hash');

    setData({ ...data, email, hash });
  }, []);

  return (
      <Wrapper>
        <Logo src={sm_logo} alt="logo" draggable={false} />

        <InfoMessage>{data.email}</InfoMessage>

        <InvitationDescriptor mt={20}>Neues Passwort*</InvitationDescriptor>
        <InputContainer
            type="password"
            name="password"
            key="password"
            value={data.password}
            onChange={({ target: { value } }) => changeData('password', value)}
            onBlur={() => checkCopy()}
            placeholder="Passwort"
        />

        {!!passwordError.length && <ErrorMessage>{t(passwordError)}</ErrorMessage>}

        <InvitationDescriptor mt={20}>Neues Passwort wiederholen*</InvitationDescriptor>
        <InputContainer
            type="password"
            name="password_copy"
            key="password_copy"
            value={passwordCopy}
            onChange={({ target: { value } }) => changePasswordCopy(value)}
            onBlur={() => checkCopy()}
            placeholder="Neues Passwort"
        />

      {!!copyError.length && <ErrorMessage>{t(copyError)}</ErrorMessage>}

        <FullButton
        genWith="386px"
        mt={30}
        btnColor="mintGreen"
        click={() => changePassReq()}
        text={t('absenden')}
      />
      </Wrapper>
  );
};
