import React from 'react';
import styled from 'styled-components';

import google_logo from '../../static/assets/icons/googleLogo.svg';
import facebook_logo from '../../static/assets/icons/facebookLogo.svg';
import { useSelector } from 'react-redux';

const SocialIcon = styled.img`
    width: 20px;
    vertical-align: sub;
    height: 20px;
    margin: 2px 5px;
`;

export const SocialName = ({ user, className }) => {
    const authType = useSelector(state => state.authType);
    const useGoogle = user.accounts.google && authType === 'google';
    const useFaceBook = user.accounts.facebook && authType === 'facebook';;

    return (
        useGoogle || useFaceBook ?
            <span className={className}>
                <SocialIcon src={useGoogle ? google_logo : facebook_logo} />
                {`${user.cs_first_name || ''} ${user.cs_last_name || ''}`}
            </span>
            : null
    );
}