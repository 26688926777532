import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {toggleModalEnhancedRaw, toggleModalPromptRaw} from '../../../redux/actions/modals';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LabelIconSVG} from "../../icons/GalleryPhotoIcons";
import participantIcon from '../../../static/assets/icons/album_participant.svg'
import deleteIcon from '../../../static/assets/icons/gray_delete.svg';

import {PromptModalInner} from "../../Modals/promptModal";
import useWindowWidth from "../../../utils/useWindowWidth/useWindowWidth";

const ModalContainer = styled.div`
	width: 100%;
	height: 100%;
  overflow-x: hidden;
`;

const HeaderContainer = styled.div`
	background-color: #ffffff;
	${({theme, isMobile}) => isMobile && theme.onMD(`
		width: 100%; 
		position: fixed; 
		top:0; left:0; 
		border-bottom: 1px solid #D1D3D4;
		overflow: hidden;
	`) }
`;

const DescriptionHead = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: ${({theme}) => theme.font.default};
  color: ${({theme}) => theme.palette.dark};
  ${({theme}) =>
	theme.onMD(`
    margin: 9px 17px;
    font-size:14px;
    text-align: left;
  `)}
`;

const MobileCloseBtn = styled.div`
	display: none;
	${({theme, isMobile}) => isMobile && theme.onMD(`
		display: block;
		position: fixed;
		top: 0;
		right: 0;
		padding: 9px 17px;
		font-size: 14px;
		color: #8F8F8F;
		background-color: transparent;
	`)}
`;

const InnerContainer = styled.div`
${({theme}) =>
	theme.onMD(`
        margin-top:  37px;
  `)}
`

const DescriptionText = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal 300 16px/25px Roboto;
  font-family: ${({theme}) => theme.font.default};
  ${({theme}) =>
	theme.onMD(`
				font-size: 14px;
				text-align: left;
  `)}
`;



const ParticipantsContainer = styled.div`
	width: 100%;
	max-width: 355px;
	max-height: 395px;
	display: flex;
	flex-direction: column;
	margin: 15px auto 20px;
	${({theme}) => theme.onMD(`
		max-height: calc(100% - 50px - 30px - 45px);
	`)}
`;

const HeaderList = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
  width: 100%;
  height: 50px;
  background-color: #F7F3ED;
`;

const TitleColumn = styled.div`
	height: 50px;
	line-height: 50px;
  text-align: center;
`;

const TitleText = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: auto 10px auto;
  font: normal normal normal 14px/20px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;

const IconContainer = styled.div`
	width: 24px;
	height: 24px;
	margin: auto 13px;
`;


const ParticipantsItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
	${({theme}) => theme.onMD(`
		overflow-y: scroll;
	`)}
`;
const ParticipantListWrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: 100%;
	justify-content: space-between;
	//align-items: center;
	${({isLast}) => !isLast && 'border-bottom: 1px solid #d1d3d4;' }
`;

const ParticipantListIconContainer= styled.div`
	display: flex;
	height: 70px;
	justify-content: left;
	align-items: center;
`;

const LeftSideWrapper = styled.div`
	display: flex;
`;

const ParticipantIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #FAF9F7;
`;

const CommentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin: 18px 0;
`;

const ParticipantNickName = styled.div`
	margin-bottom: 2px;
	font: normal normal normal 14px/19px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;

const Comment = styled.div`
	display: flex;
  text-align: left;
  font: normal normal 300 14px/19px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;

const DeleteIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
	cursor: pointer;
  width: 24px;
  height: 40px;
	margin: 24px 8px;
`;

export const ParticipantsCommentsModal = ({eventID, photoID}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {isMobile} = useWindowWidth();
	const [isLoading, changeIsLoading] = useState(true);
	const [commentsList, changeCommentsList] = useState([])

	const closeModalEnhancedRaw = () => {
		toggleModalEnhancedRaw(dispatch, false);
	}

	useEffect(() => {
		changeIsLoading(true);

		const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/comments/${photoID}`;

		fetch(url, {
			method: 'POST',
			credentials: "include",
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(response => response.json())
			.then(resp => {
				if (resp && resp.length) {
					changeCommentsList(resp)
				}
			})
			.catch(err => console.log(err));
	},[])

	const deleteComment = (commentID, commentOwnerID, eventID, photoID) => {
		const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/remove_comment_host`;
		fetch(url, {
			method: 'POST',
			credentials: "include",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				commentID,
				commentOwnerID,
				eventID,
				photoID,
			})
		}).then(response => response.json())
			.then(resp => {
				if (resp && resp.success) {
					const newList = commentsList.filter(comment => comment.commentID !== commentID)
					changeCommentsList(newList);
					closeModalPromptRaw();
					if (!newList.length) closeModalEnhancedRaw()
				}
			})
			.catch(err => console.log(err));
	}

	const closeModalPromptRaw = () => {
		toggleModalPromptRaw(dispatch, false);
	};

	const promptModalRaw = (nickname, commentID, commentOwnerID, eventID, photoID) => {
		toggleModalPromptRaw(dispatch, {
			children: (
				<PromptModalInner
					title={'Kommentar löschen'}
					description={`Möchtest Du wirklich den Kommentar von ${nickname} unwiderruflich löschen möchten. Der Kommentar ist nur für Dich und ${nickname} sichtbar.`}
					actionToDo={() => deleteComment(commentID, commentOwnerID, eventID, photoID)}
					onClose={closeModalPromptRaw}
					textCancel={'Abbrechen'}
					textAccept={'Löschen'}
					btnColors={{
						cancel: {
							cancel: 'dark',
							bgColor: 'white',
							hoverStyle: 'border: 1px solid #000000;'
						},
						accept: {
							color: 'white',
							bgColor: 'newDart',
							hoverStyle: 'background-color: #000000;'
						}
					}}
				/>
			),
		});
	}

	return (
		<ModalContainer>
			<HeaderContainer  isMobile={isMobile}>
				<DescriptionHead>Kommentare {commentsList && commentsList.length && `${'(' + commentsList.length + ')'}` || null}</DescriptionHead>
				<MobileCloseBtn onClick={closeModalEnhancedRaw} isMobile={isMobile}>Abbrechen</MobileCloseBtn>
			</HeaderContainer>
			<InnerContainer>
				<DescriptionText>
					Die Kommentare können ausschließlich von Dir und vom Verfasser gelesen werden.
				</DescriptionText>
			</InnerContainer>
			<ParticipantsContainer>
				{commentsList && commentsList.length && (
					<>
						<HeaderList>

							<TitleColumn><TitleText>Name</TitleText></TitleColumn>
							<IconContainer>
								<LabelIconSVG labelExist={false} />
							</IconContainer>
						</HeaderList>
						<ParticipantsItemContainer>
							{commentsList.length && commentsList.map((comment, i) => {
								const isLast = i === commentsList.length - 1;
								const nickname = `${comment.customer.cs_first_name || ''}` +  ` ${comment.customer.cs_last_name || ''}`;
								return (
									<ParticipantListWrapper isLast={isLast} key={comment.commentID}>
									<LeftSideWrapper>
										<ParticipantListIconContainer>
											<ParticipantIcon>
												<img src={participantIcon} alt='participant-icon'/>
											</ParticipantIcon>
										</ParticipantListIconContainer>

										<CommentWrapper>
											<ParticipantNickName>
												{nickname}
											</ParticipantNickName>
											<Comment>
												{comment.text}
											</Comment>
										</CommentWrapper>
									</LeftSideWrapper>
										<DeleteIconWrapper onClick={() => promptModalRaw(nickname, comment.commentID, comment.customer.id, comment.eventID, comment.photoID)}>
											<img src={deleteIcon} height='24px' width='24px' alt={'delete_icon'}/>
										</DeleteIconWrapper>
									</ParticipantListWrapper>
								);
							})}
						</ParticipantsItemContainer>
					</>
				)}
			</ParticipantsContainer>
		</ModalContainer>
	);
}
