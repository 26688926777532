import React from 'react';
import styled from 'styled-components';

import { FullScreenExitIcon } from '../../icons/FullScreenExitIcon';
import { FullScreenEnterIcon } from '../../icons/FullScreenEnterIcon';
import { CloseIcon } from '../../icons/CloseIcon';

import person from '../../../static/assets/icons/person.svg';
import participantIconWhite from '../../../static/assets/icons/album_participant_white.svg'
import crown_white_icon from "../../../static/assets/icons/crown_white_icon.svg";
import {useSelector} from "react-redux";

export const CustomHeader = (props) => {
  const {
    components,
    getStyles,
    getCloseLabel,
    getFullscreenLabel,
    innerProps,
    isModal,
    modalProps,
    currentView: { ownerName, imageDate, ownerID }
  } = props;
  const { innerRef, ...currentProps } = innerProps;

  if (!isModal) return null;
  const activeEvent = useSelector(state => state.activeEvent);

  const {
    allowFullscreen,
    isFullscreen,
    onClose,
    toggleFullscreen,
  } = modalProps;
  const FsIcon = isFullscreen ? FullScreenExitIcon : FullScreenEnterIcon;
  const { CloseButton, FullscreenButton } = components;
  const state = { isFullscreen, isModal };

  const nickNameToShow = () => {
    if (activeEvent.evt_mode === 'thank') return ''
    else if (activeEvent.evt_owner_id === ownerID) return <HostNicknameContainer> <img src={crown_white_icon} alt={'admin_crown'}/> Admin</HostNicknameContainer>
    else return <Owner><img src={ participantIconWhite } alt={'host_icon'}/>{ownerName}</Owner>
  }


  return (
    <div
      className={`header ${state.isFullscreen ? 'isFullscreen' : ''} ${state.isModal ? 'isModal' : ''}`}
      style={{
        background: 'linear-gradient(rgba(0,0,0,0.33), rgba(0,0,0,0))',
        ...getStyles('header', props)
      }}
      {...currentProps}
      ref={innerRef}
    >
      <ImageInfo>
        {nickNameToShow()}
        {/*{imageDate ? <Date>{imageDate}</Date> : null}*/}
      </ImageInfo>
      <span style={{ whiteSpace: 'nowrap' }}>
        {allowFullscreen ? (
          <FullscreenButton
            getStyles={getStyles}
            innerProps={{
              onClick: toggleFullscreen,
              title: getFullscreenLabel(state),
            }}
          >
            <FsIcon size={32} />
          </FullscreenButton>
        ) : null}
        <CloseButton
          getStyles={getStyles}
          innerProps={{
            onClick: onClose,
            title: getCloseLabel(state),
          }}
        >
          <CloseIcon size={32} />
        </CloseButton>
      </span>
    </div>
  );
};

const ImageInfo = styled.div`
    display: inline-flex;
    flex-flow: column;
    height: 50px;
    justify-content: center;
    flex-shrink: 1;
    max-width: 100%;
    word-break: break-word;
`;

const HostNicknameContainer = styled.div`
  display: flex;
  padding: 0 0 0 20px;
  color: #FFFFFF;
  align-items: center;
  max-height: 37px;
  img {
    width: 40px;
    height: 40px;
  }
`;

const Owner = styled.div`
    display: inline-flex;
    padding: 0 0 0 20px;
    color: #FFFFFF;
    align-items: flex-start;
    max-height: 37px;
    overflow: hidden;
    font: normal normal normal 16px/21px Roboto;
    font-family: ${({theme}) => theme.font.default};
    img { width: 24px; height: 24px; margin-right: 10px; }
`;

const Date = styled.div`
    display: inline-flex;
    padding: 0 0 0 54px;
    align-items: center;
    color: #FFFFFF;
    font-size: 12px;
`;
