import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { navigateToFace } from '../../../redux/actions/sidebar';

import go_back_grey from '../../../static/assets/icons/go_back_grey.svg';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  margin-top: 20px;
  margin-bottom: ${({ mb }) => (mb ? mb : '0')}px;
  ${({ theme }) =>
    theme.onMD(`
    display: none;
  `)}
`;

const Icon = styled.img`
  width: 38px;
  height: 38px;
  cursor: pointer;
  &:hover {
    filter: invert(100%);
  }
`;

export const BackToMain = ({ mb, onClick = () => {} }) => {
  const dispatch = useDispatch();
  const changeFace = state => navigateToFace(dispatch, state);

  return (
    <Container mb={mb}>
      <Icon src={go_back_grey} alt="go_amin" draggable={false} onClick={
        () => { 
          changeFace('main');
          onClick();
        }
      } />
    </Container>
  );
};
