import React from 'react';
import {PromptModalInner} from "./promptModal";

import styled from "styled-components";
import {toggleModalPromptRaw} from "../../redux/actions/modals";
import {useDispatch} from "react-redux";

const WrapperRaw = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 38px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 76px;
  height: 38px;
  line-height: 36px;
  overflow: hidden;
  vertical-align: center;
  border: 1px solid #D1D3D4;
  border-radius: 19px;
  font-size: 14px ;
  font-family: ${({theme}) => theme.font.default};
  color: ${({ theme: {palette}}) => palette['white']};
  background-color: ${({ theme: {palette} }) => palette['newDart']};
  cursor: pointer;
  &:hover {
    border: 1px solid #000000;
  }
`;

const BottomPart = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 24px;
  font: normal normal normal 14px/19px Roboto;
`;

const Anchor = styled.a`
	margin: 0 10px;
`;

export const UnknownErrorPrompt = () => {
	const dispatch = useDispatch();

	return (
		<PromptModalInner
			title={'Tut uns leid. Es ist ein Fehler aufgetreten.'}
			description={'Tut uns leid. Es ist ein Fehler aufgetreten. Bitte aktualisiere Deine Seite und versuche es erneut. Sollte das Problem andauern, hilft Dir gerne unser Kundenservice-Team.'}
			rawDescription={
				<WrapperRaw>
					<Button onClick={() => toggleModalPromptRaw(dispatch, false)}>Ok</Button>
					<BottomPart>
						<Anchor href="mailto:kontakt@sendmoments.de">kontakt@sendmoments.de</Anchor> | <Anchor href="tel:00498921544175">0049 89 215 44 175</Anchor>
					</BottomPart>
				</WrapperRaw>
			}
		/>
	)
}