import checkmark_material from "../../../static/assets/icons/checkmark_material.svg";
import React from "react";
import styled from "styled-components";

export const CheckMark = ({isActive, glow}) => {

	return (
		<SelectionMark isActive={isActive} glow={glow}>
			{isActive ? (
				<div style={{height: '100%', width: '100%', backgroundColor: '#FFB689', borderRadius: '50%'}}>
					<img src={checkmark_material}/>
				</div>
			)  : null}
		</SelectionMark>
	);
}

const SelectionMark = styled.div`
  position: absolute;
  left: 6px;
  top: 8px;
  width: 19px;
  height: 19px;
  
  border-radius: 50%;
  background: transparent;
	border: ${({ isActive }) => isActive ? 0: 1}px solid #707070;
  box-shadow: ${({glow}) => glow ? '2px 4px 14px rgba(255, 255, 255, 0.8)' : '0 0 1px 0 rgba(0, 0, 0, 0.5)'};
  
  img { position: absolute; top: 0; left: 0;}

` ;