import React from 'react';
import { Icon } from '../icons/Icon';

const colors = ['currentColor'];
const hoverColors = ['currentColor'];

const paths = [ 'M15.984 8.016h3v1.969h-4.969v-4.969h1.969v3zM14.016 18.984v-4.969h4.969v1.969h-3v3h-1.969zM8.016 8.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 15.984v-1.969h4.969v4.969h-1.969v-3h-3z' ];

export const FullScreenExitIcon = (props) => {
    return (
        <Icon viewBox="0 0 24 24" colors={colors} hoverColors={hoverColors} {...props} paths={paths}/>
    )
};