import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '../../common/errorMessage';

const Container = styled.div`
  width: ${({ width }) => width || '100%'};
  max-width: 100%;
  flex-shrink: 0;
  position: relative;
`;

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${({ mt }) => typeof mt !== 'undefined' ? mt : 20}px;
  font-family: ${({ theme }) => theme.font.default};
  color: #000000;
  align-items: baseline;
  ${({ theme, mt }) =>
    theme.onSM(`
    margin-top: ${typeof mt !== 'undefined' ? mt : 30}px;
  `)}
`;

const Label = styled.label`
  display: block;
  flex-grow: 1;
  text-align: left;
  font-size: 22px;
  ${({ theme }) =>
    theme.onSM(`
    font-size: 14px;
  `)}
`;

const Input = styled.input`
  text-align: left;
  padding: 0 17px;
  width: 100%;
  max-width: 100%;
  height: 62px;
  margin-top: 15px;
  border: 2px solid ${({ theme }) => theme.palette.lightGray};
  background-color: ${({ theme }) => theme.palette.white};
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.default};
  &:focus {
    outline: none !important;
    border: 2px solid rgba(128, 180, 171, 0.5);
  }
  ${({ theme }) =>
    theme.onMD(`
    height: 45px;
    font-size: 14px;
    padding: 0 13px;
    border-width: 1px;
    margin-top: 10px;
    &:focus {
      border-width: 1px;
    }
  `)}
  &[type=date] { 
      flex-grow: 1;
      flex-shrink: 1;
      width: auto;
      padding: 0 13px;
      margin: 0 0 0 20px;
  }
`;

const Clear = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: absolute;
  bottom: 12px;
  width: 50px;
  height: 50px;
  right: 12px;
  background: #FFFFFF;
  background: linear-gradient(90deg, transparent 0%, #FFFFFF 20%);

  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    flex-shrink: 0;
    width: 30px;
    font-size: 0;
    background: ${({ theme }) => theme.palette.lightGray};
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }

  ${({ theme }) =>
    theme.onMD(`
      bottom: 4px;
  `)}
`;

export const InputContainer = (props) => {
  const { 
    label = '', 
    name, 
    placeholder = '', 
    value = '', 
    onChange = () => {}, 
    onBlur = () => {}, 
    onKeyPress = () => {}, 
    onClear = null, 
    error = '', 
    type = 'text', 
    inputWidth = null, 
    style = {}, 
    className,
    mt = 20,
    labelLink = null
  } = props;
  return (
    <Container width={inputWidth} className={className}>
      {label ? <LabelWrapper mt={mt}><Label htmlFor={name}>{label}</Label> {labelLink ? labelLink : null}</LabelWrapper> : null}
      <Input
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        id={name}
        name={name}
        key={name}
        placeholder={placeholder}
        error={!!error}
        style={style}
      />
      { error ? <ErrorMessage>{ error }</ErrorMessage> : null }
      { onClear && value ? <Clear onClick={onClear}/> : null }
    </Container>
  );
};