import { css } from 'styled-components';

import '../static/fonts/Roboto-Regular.ttf';
import '../static/fonts/Roboto-Light.ttf';
import '../static/fonts/Roboto-Bold.ttf';

const onXS = styles => css`
  @media screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    ${styles}
  }
`;

const onSM = styles => css`
  @media screen and (max-width: 768px) {
    ${styles}
  }
`;

const onMD = styles => css`
  @media screen and (max-width: 992px) {
    ${styles}
  }
`;

const onLG = styles => css`
  @media screen and (max-width: 1440px) {
    ${styles}
  }
`;

const onMidXL = styles => css`
  @media screen and (max-width: 1780px) {
    ${styles}
  }
`;

const onXL = styles => css`
  @media screen and (max-width: 1920px) {
    ${styles}
  }
`;

const container = {
  maxWidth: 1920,
};

const sidebar = {
  innerPadding: {
    vertical: 51,
    horizontal: 39,
  },
  mobilePadding: {
    vertical: 20,
    horizontal: 10,
  },
};

const palette = {
  error: 'rgb(159,42,73)',
  white: 'rgb(255,255,255)',
  mintGreen: 'rgb(128, 180, 171)',
  green: 'rgb(38, 188, 106)',
  lotionGray: 'rgb(250, 250, 250)',
  lightGray: 'rgb(138, 132, 128)',
  darkGray: 'rgb(144,141,136)',
  newGray: 'rgb(143, 143, 143)',
  middleGray: 'rgb(235,235,235)',
  lightDark: 'rgb(30,30,30)',
  paleDark: 'rgb(17,17,17)',
  dark: 'rgb(0,0,0)',
  newDart: 'rgb(59, 59, 57)',
  red: '#ff6c65',
  darkRed: '#a02b49',
  paleGray: 'rgb(209,211,212)'
};

const font = {
  roboto: 'Roboto',
  default: 'Roboto, sans-serif',
  openSans: 'Open Sans',
  playfairDisplay: 'Playfair Display',
};

export default {
  container,
  sidebar,
  onXS,
  onSM,
  onMD,
  onLG,
  onMidXL,
  onXL,
  palette,
  font,
};
