import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '../../common/errorMessage';
import useWindowWidth from "../../../utils/useWindowWidth/useWindowWidth";

const Container = styled.div`
  width: ${({ width }) => width || '100%'};
	padding-top: 5px;
  max-width: 100%;
  flex-shrink: 0;
  position: relative;
  .input-root {
    position: relative;
  }

  .input-input{
    padding: 16px 0 8px;
    border: none;
    border-bottom: 1px solid #000;
    font: normal normal normal 14px/19px Roboto;
    width: 100%;
  }

  .input-input:focus {
    border-bottom: 2px solid #000;
    outline: none;
  }

  .form-label-root {
    position: absolute;
    top: 20px;
    left: 0;
    font: normal normal normal 14px/19px Roboto;
    font-family: ${({theme}) => theme.font.default};
    color: #8F8F8F;
    transition: top 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .input-input:focus + .form-label-root {
    top: -5px;
  }

  .input-input:disabled {
		background-color: #ffffff;
		cursor: not-allowed;
	}

  .input-input:not(:placeholder-shown) + .form-label-root {
    top: -5px;
  }
  & > *:nth-child(2) {
		margin-top: 2px;
  }
    text-align: left;
  	font: normal normal normal 14px/19px Roboto;
    ${({ theme }) =>
            theme.onMD(`
            font: normal normal normal 14px/19px Roboto;
     				text-align: left;
  `)}
`;


export const UnderlineInputContainer = (props) => {
	const {
		label = '',
		value = '',
		onChange = () => {},
		onBlur = () => {},
		onKeyPress = (e) => e,
		error = '',
		inputWidth = null,
		className,
		readonly = false,
		type = 'text',
		holderID = 'nickname-holder'
	} = props;

	const {isMobile} = useWindowWidth();

	const focusCheckReqScroll = () => {
		if (isMobile) {
			const element = document.getElementById(holderID);
			element.scrollIntoView({ behavior: 'smooth' });
		}
	}

	return (
		<Container width={inputWidth} className={className} id={holderID}>
			<div className="input-root">
				<input className="input-input" type={type} disabled={readonly} onKeyPress={onKeyPress} onFocus={focusCheckReqScroll} value={value} onChange={onChange} onBlur={onBlur} id="input-id" />
					<label className="form-label-root" htmlFor="input-id">{label}</label>
			</div>
			{ error ? <ErrorMessage>{ error }</ErrorMessage> : null }
		</Container>
	);
};