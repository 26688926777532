import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { validate } from '../../utils/validateForms';
import { InputContainer } from './parts/inputContainer';
import { FullButton } from '../common/fullBtn';
import { toggleModalInfo } from '../../redux/actions/modals';
import { ErrorMessage } from '../common/errorMessage';

const Title = styled.div`  
  margin-bottom: 40px;
  font-size: 21px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  ${({ theme }) =>
    theme.onMD(`
    font-size: 15px;
  `)}
`;

export const EditEmailForm = ({ email }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: '',
    emailCopy: ''
  });
  const [errorMessages, setErrorMessages] = useState({
    email: '',
    emailCopy: '',
    general: ''
  });
  const changeData = (name, value) => {
    const newData = {
      ...data,
    };
    newData[name] = value;
    setData(newData);
  };

  const isValid = (key, value) => {
    let valid = true;

    const result = key ? validate({[key]: value.trim()}) :  validate({ email: data.email.trim() });
    const messages = { ...errorMessages };
    const keysToChek = key ? [key] : Object.keys(data);

    keysToChek.forEach(key => {
      if (result[key]) {
        valid = valid && result[key].success;
        messages[key] = t(result[key].error) || '';
      }
    });

    setErrorMessages({ ...messages, general: ''});

    return valid;
  }

  const checkEmailCopy = (value) => {
    const copy = typeof value !== 'undefined' ? value : data.emailCopy;

    setErrorMessages({ 
        ...errorMessages, 
        emailCopy: copy === data.email ? '' : 'Die von Ihnen eingegebenen E-Mails sind nicht identisch'
    });

    return copy === data.email;
  }

  const submitForm = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'auth/change_email'}`;

    if (data.email === email) {
        setErrorMessages({ ...errorMessages, general: 'Die von Ihnen eingegebene E-Mail-Adresse entspricht der aktuellen'});
    } else if (checkEmailCopy() && isValid('email', data.email)) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          newEmail: data.email.trim(),
          oldEmail: email
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            onChangeSuccess();
          } else {
            setErrorMessages({ ...errorMessages, general: t(data.error) });
            //todo: set error translation to 'Bitte überprüfen Sie die Angabe Ihrer E-Mail Adresse. Die E-Mail ist entweder falsch oder es handelt sich um Ihre bestehende E-Mail Adresse'
          }
        })
        .catch(err => console.log(err));
    }
  };

  const onChangeSuccess = () => {
    toggleModalInfo(dispatch, {
      title: 'Vielen Dank!',
      message: `Wir haben Ihnen eine Bestätigungsemail geschickt an Ihre E-Mail-Adresse ${data.email}. Bitte prüfen Sie Ihren Posteingang und bestätien Sie die Änderung durch Klick auf den Aktivierungslink.`
    });
  }

  return (
    <>
      <Title>{'Bitte geben Sie hier Ihre neue E-Mail Adresse ein'}</Title>

      <InputContainer 
        name="email" 
        label="Neue E-Mail *" 
        value={data.email}
        onChange={({ target: { value } }) => { 
            changeData('email', value); 
            checkEmailCopy(); 
        }}
        onBlur={ ({ target: { value } }) => isValid('email', value) }
        error={errorMessages.email}
      />

      <InputContainer 
        name="email_copy" 
        label="Neue E-Mail bestätigen *" 
        value={data.emailCopy}
        onChange={({ target: { value } }) => changeData('emailCopy', value) }
        //onBlur={ ({ target: { value } }) => checkEmailCopy(value) }
        error={errorMessages.emailCopy}
      />

      { errorMessages.general ? <ErrorMessage>{ errorMessages.general }</ErrorMessage> : null }

      <FullButton genWith="386px"
        mt={30}
        btnColor="mintGreen"
        text={t('save')}
        click={submitForm}></FullButton>
    </>
  );
};