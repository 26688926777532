import React from 'react';
import { Icon } from '../icons/Icon';

const colors = ['currentColor'];
const hoverColors = ['currentColor'];

const paths = [ 'M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z' ];

export const CloseIcon = (props) => {
    return (
        <Icon viewBox="0 0 24 24" colors={colors} hoverColors={hoverColors} {...props} paths={paths}/>
    )
};