const validateRegexs = {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\w{6,50}$/,
    password: /^.{6,50}$/,
    firstname: /^.+$/,
    lastname: /^.+$/,
    company: /^.+$/,
  };

export const validate = (data) => {
    const keys = Object.keys(data);

    let result = {  };

    keys.forEach(key => {
        if (validateRegexs[key]) { 
            result[key] = { };
            result[key].success = validateRegexs[key].test(data[key]);

            if (!result[key].success) {
                result[key].error = getError(key, data[key]);
            }
        }
    });

    return result;
};

const getError = (key, value) => {
    let error = 'there_was_an_issue';

    if (!value) {
        if (key === 'email') { 
            error = 'email_cannot_be_empty';
        } else if (key === 'password') {
            error = 'password_cannot_be_empty';
        } else if (key === 'firstname') { 
            error = 'first_name_cannot_be_empty';
        } else if (key === 'lastname') {
            error = 'last_name_cannot_be_empty';
        } else if (key === 'company') {
            error = 'company_name_cannot_be_empty';
        }
    } else {
        if (key === 'email') { 
            error = 'email_is_wrong';
        } else if (key === 'password') {
            error = 'password_is_wrong';
        }
    }

    return error;
};
