import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from 'react-redux';

import { toggleSize } from '../../redux/actions/gallery';

import gallery_big_icon from '../../static/assets/icons/view_modus_big.svg';
import gallery_small_icon from '../../static/assets/icons/view_modus_small.svg';

export const PhotoSizeToggler = ({sizeSmall, sizeBig, ml}) => {
    const dispatch = useDispatch();
    const galleryStatus = useSelector(state => state.galleryStatus);
    const activeEvent = useSelector(state => state.activeEvent);

    const eventPictures = useSelector(state => state.eventPictures)

    const haveImages = eventPictures[activeEvent.id] && eventPictures[activeEvent.id].pictures && eventPictures[activeEvent.id].pictures.length;
  
    const changeGalleryState = state => toggleSize(dispatch, state);
  
    const isGallerySmall = galleryStatus === 'small';

    if (!haveImages) return null;

    return (
      <ImageViewSizeToggler onClick={() => changeGalleryState(isGallerySmall ? 'big' : 'small')} sizeSmall={sizeSmall} sizeBig={sizeBig}>
          { isGallerySmall ? <img src={gallery_big_icon} alt='Show big images' title='Show big images' /> : <img src={gallery_small_icon} alt='Show small images' title='Show small images' /> }
      </ImageViewSizeToggler>
    );
}

const ImageViewSizeToggler = styled.div`
  display: flex;
  cursor: pointer;
  img { width: ${({sizeBig}) =>  sizeBig || 36}px; height: ${({sizeBig}) =>  sizeBig || 36}px; }
  margin-right: 3px;
  flex-shrink: 0;
  ${({ theme, sizeSmall }) =>
    theme.onMD(`
    margin: 0 10px 0 0;
    img { width: ${sizeSmall || 45}px; height: ${sizeSmall || 45}px; }
  `)}

  ${({ theme, sizeSmall }) =>
    theme.onXS(`
    img { width: ${sizeSmall || 35}px; height: ${sizeSmall || 35}px; }
  `)}
`;