import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {toggleModalFastLogin, toggleModalGuest, toggleModalInfo} from '../../redux/actions/modals';
import { ErrorMessage } from '../common/errorMessage';
import {getParticipants} from "../../redux/actions/participants";
import {DefaultModal} from "./modal";
import {RegisterForm} from "../forms/registerForm";
import {UnderlineInputContainer} from "../forms/parts/underlineInput";



const ModalContainer = styled.div`
	width: 100%;
	height: 100%;
  overflow-x: hidden;
`;

const DescriptionHead = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: ${({theme}) => theme.font.default};
  color: ${({theme}) => theme.palette.dark};
  ${({theme}) =>
  theme.onMD(`
    margin: 25px auto;
  `)}
`;

const InnerContainer = styled.div`
${({theme}) =>
  theme.onMD(`
        margin-top:  37px;
  `)}
`

const DescriptionText = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal 300 16px/25px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 295px;
  margin: 35px auto 0 auto;
  ${({theme}) =>
  theme.onMD(`
      width: 293px;
      max-width: 100%;
      height: 111px;
  `)}
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 240px;
  max-width: 100%;
  height: 38px;
  line-height: 36px;
  overflow: hidden;
  vertical-align: center;
  border: 1px solid #D1D3D4;
  border-radius: 19px;
  font-size: 14px ;
  font-family: ${({theme}) => theme.font.default};
  color: ${({ color, theme: {palette}}) => palette[color]};
  background-color: ${({ backgroundColor, theme: {palette} }) => palette[backgroundColor]};
  cursor: pointer;
  &:hover {
    ${({hoverStyle}) => hoverStyle};
  }
  
  ${({theme}) =>
  theme.onMD(`
      width: 293px;
      max-width: 100%;
      height: 48px;
      line-height: 46px;
      border-radius: 24px;
  `)}
`;

const InputWrapper= styled.div`
  width: 293px;
  max-width: 100%;
  margin: 51px auto 0 auto;
`;

const UnderDescription = styled.div`
  text-align: center;
  padding: 10px 0;
  div {
    font: normal normal 300 12px/12px Roboto;
    font-family: ${({theme}) => theme.font.default};
    a {
      text-decoration: underline;
      &:hover {
        color: #8f8f8f;
      }
    }
  }
`;

export const GuestNickName = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalGuest = useSelector(state => state.modalGuest);
  const activeEvent = useSelector(state => state.activeEvent);

  const currentUser = useSelector(state => state.currentUser);
  const isGuest = currentUser ? currentUser.cs_role === 'guest' : false;
  const parentOrigin = window.location.origin.replace('up', 'www').replace('www-dev', 'www');
  useEffect(() => {
    // const albumRequireNickname = activeEvent && activeEvent.evt_mode === 'up';
    // const triggerModal = isGuest && !currentUser.cs_first_name && albumRequireNickname;
    const isEventLock = activeEvent && activeEvent.evt_lock === 1;
    const triggerModal = isGuest && !currentUser.cs_first_name;
    if (triggerModal && !isEventLock) {
      toggleModalGuest(dispatch, {open: true});
    }
  }, []);

  const changeModalState = state => toggleModalGuest(dispatch, state);

  const onCloseModal = () => {
    changeModalState(false);
  };

  const { open } = modalGuest;

  const [data, setData] = useState({
    nickname: '',
  });

  const [errorMessages, setErrorMessages] = useState({
    general: '',
  });


  const changeData = (value) => {
    if (value && value.length > 64) return;

    const tmpValue = value.match(/[a-zA-Z\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00dfáàâãåçéèêëíìîïñóòôõúùûýÿæœÁÀÂÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÕÚÙÛÝŸÆŒ .-]/g);

    if (tmpValue || value === "") {
      if (errorMessages) setErrorMessages({general: ''});

      const newData = {
        nickname: tmpValue ?  tmpValue.join('').trimStart() : value
      };
      setData(newData);
    }
  };

  const isValid = () => {

    const trimValue = data.nickname;

    if (trimValue && trimValue.length < 64 && trimValue.length >= 3) {
      return true;
    } else {
      setErrorMessages({ general: 'invalid_nickname'});

      return false;
    }
  };

  const onChangeSuccess = () => {
    dispatch(getParticipants(activeEvent.id));
    onCloseModal();
  };

  const openFastLoginPopup = () => {
    changeModalState(false);
    toggleModalFastLogin(dispatch, {open: true, onClose: () => changeModalState(true)});
  };

  const openRegisterPopup = () => {
    changeModalState(false);
    toggleModalInfo(dispatch, {
      title: '',
      message: <RegisterForm isGuest={true} />,
      onClose: () => {
        changeModalState(true);
      },
      buttons: false
    });
  };

  const submitForm = (nickname, defaultNick) => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'auth/guest_nickname'}`;

    if (isValid() || defaultNick) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nickname: data.nickname.trim(),
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            onChangeSuccess();
          } else {
            setErrorMessages({ ...errorMessages, general: t(data.error)});
          }
        })
        .catch(err => console.log(err));
    }
  };

  if (!activeEvent) return null;


  const baseContent = (
    <ModalContainer>
      <DescriptionHead>
        {activeEvent.evt_name}
      </DescriptionHead>

      <InnerContainer>
        <DescriptionText>
          Bitte gib Deinen Namen ein, um dem Album beizutreten. <br />
          Hochgeladene Fotos können von allen Teilnehmern angesehen und heruntergeladen werden.
        </DescriptionText>
      </InnerContainer>

      <InputWrapper>
        <UnderlineInputContainer
           label='Name *'
           name="nickname"
           value={data.nickname}
           onChange={({ target: { value } }) => changeData(value) }
           onBlur={ ({ target: { value } }) => isValid(value) }
           onKeyPress={(e) => {
             let {key, charCode} = e;
             if (charCode === 13 || key === "Enter") submitForm(null, null)
           }}
           placeholder={`${t('Ihr Name')}`}
           error={``}
          />
        {errorMessages.general ? <ErrorMessage mt={2} font={'font: normal normal normal 14px/19px Roboto, sans-serif;'}>
          { t(errorMessages.general) }
        </ErrorMessage> : null}
        </InputWrapper>
      <ButtonsContainer>
        <Button
          color={'white'}
          backgroundColor={data.nickname && data.nickname.trim().length >= 3 ? 'newDart' : 'paleGray'}
          hoverStyle={data.nickname && data.nickname.trim().length >= 3 ? 'border: 1px solid #000000;': 'cursor: not-allowed;'}
          onClick={() => submitForm(null, null)}
        >
          Album beitreten
        </Button>
      </ButtonsContainer>

      <UnderDescription>
        <div>
          Mit dem Beitritt zum Album erkennen Sie unsere <a target="_blank" href={`${parentOrigin}/agb.php`}>AGB</a> und <a target="_blank" href={`${parentOrigin}/datenschutz.php`}>Datenschutzbestimmungen</a> an.
        </div>
      </UnderDescription>

    </ModalContainer>
  );

  return (
    <DefaultModal
      modalState={modalGuest && open}
      modalLabel="guest_nickname_modal"
      removeCloseBtn={true}
    >
      <>{baseContent}</>
    </DefaultModal>
  );
};