import { PARTICIPANTS_LIST, REMOVE_PARTICIPANT, ADD_PARTICIPANT_GUEST, ADD_PARTICIPANT_MANAGER, CURRENT_PARTICIPANT } from '../types';

export const participantsList = (state = [], action) => {
  let inList = false;
  switch (action.type) {
    case PARTICIPANTS_LIST:
      //compare if we need to update
      const isEqual =
          !!state && state.length === action.payload.length
          && action.payload.find(p => {
            const cp = state.find(cp => cp.id === p.id);

            return cp && JSON.stringify(cp) === JSON.stringify(p);
          });

      return isEqual ? state : action.payload;
    case REMOVE_PARTICIPANT:
      return state.filter(e => e.id !== action.payload.id);
    case ADD_PARTICIPANT_MANAGER:
    case ADD_PARTICIPANT_GUEST:
      inList = !!state && state.find(e => e.id === action.payload.id);
      if (inList) {
        state.splice(state.indexOf(inList), 1, action.payload);
        return [ ...state ];
      }
      return [...state, action.payload];
    default:
      return state;
  }
};

export const currentParticipant = (state = null, action) => {
  const isSame = !!state && !!action.payload && state.id === action.payload.id;
  switch (action.type) {
    case CURRENT_PARTICIPANT:
      return action.payload;
    case REMOVE_PARTICIPANT:
      if (isSame) return null;
    case ADD_PARTICIPANT_MANAGER:
    case ADD_PARTICIPANT_GUEST:
      if (isSame) return action.payload;
    default:
      return state;
  }
};
