import React from 'react';
import styled from 'styled-components';

const FullBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${({ genWith }) => genWith || '100%'};
  max-width: 100%;
  height: 77px;
  margin-top: ${({ mt }) => typeof mt !== 'undefined' ? mt : 10}px;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme, textColor }) => textColor? theme.palette[textColor] : theme.palette.white};
  background-color: ${({ theme, btnColor }) => theme.palette[btnColor]};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.dark};
    color: ${({ theme, textHover }) => textHover? theme.palette[textHover] : theme.palette.white};
  }
  ${({ theme, mt }) =>
    theme.onSM(`
    height: 56px;
    margin-top: ${typeof mt !== 'undefined' ? mt : 10}px;
    font-size: 14px;
  `)}
`;

export const FullButton = ({ text, btnColor, click, genWith, mt, href, textColor, textHover }) => (
  <FullBtn
    as={href ? 'a' : 'div'}
    href={href}
    genWith={genWith}
    mt={mt}
    btnColor={btnColor}
    onClick={() => click()}
    textColor={textColor}
    textHover={textHover}
  >
    {text}
  </FullBtn>
);
