import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  font-size: ${({fontSize}) => fontSize || 17}px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  margin-bottom: ${({ mb }) => typeof mb === 'number' ? mb : 26}px;
  flex-shrink: 0;
  ${({ theme, mbMobile }) =>
    theme.onMD(`
     margin-bottom: ${typeof mbMobile === 'number' ? mbMobile : 15}px;
  `)}
`;

export const EventTitle = ({ text, fontSize, mb, mbMobile }) => (
  <Container
    fontSize={fontSize}
    mb={mb}
    mbMobile={mbMobile}
  >
    {text && `${text}`}
  </Container>
);
