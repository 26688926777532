import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import { navigateToFace } from '../../redux/actions/sidebar';
import { toggleModalInfo } from '../../redux/actions/modals';

import { EventTitle } from '../common/eventTitle';
import { BackToMain } from './parts/backToMain';
// import { TextHead } from './parts/textHead';
import { FullButton } from '../common/fullBtn';
import { EditUserForm } from '../forms/editUserForm';
import { EditEmailForm } from '../forms/editEmailForm';
import { EditPasswordForm } from '../forms/editPasswordForm';

import about_us from '../../static/assets/icons/about_us.svg';
import imprint from '../../static/assets/icons/imprint.svg';
import privacy from '../../static/assets/icons/privacy.svg';
import { getSalutationLabel } from '../../utils/salutation';
import { UserButton } from './parts/userButton';
import { SocialName } from '../common/socialName';
import { Separator } from '../common/separator';
import {RegisterForm} from "../forms/registerForm";
import {LoginForm} from "../forms/loginForm";

// const ImageContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   margin-top: 60px;
//   margin-bottom: 60px;
// `;
//
// const Image = styled.img`
//   width: 180px;
//   height: 180px;
// `;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
`;

const LinksList = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 10px 0 30px;
  flex-shrink: 0;
  ${({ theme }) =>
    theme.onMD(`
    margin: 10px 0 5px;
  `)}
`;

const Link = styled.a`
  width: calc(50% - 5px);
  font-size: 21px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;

  &:nth-child(2n) {
    margin-left: 10px;
  }

  img { width: 32px; flex-shrink: 0; margin-right: 10px; }

  ${({ theme }) =>
    theme.onMD(`
    font-size: 15px;

    img { width: 26px; }
  `)}
`;

const StyledSocialName = styled(SocialName)`
    font-size: 19px;
    display: flex;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
      margin: 0 10px 0 0;
    }
`;

export const logOut = () => {
  const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}${'auth/logout'}`;

  fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        window.location.replace(`${window.location.origin}/login`);
      } else if (data.message === 'redirect') {
        window.location.replace(data.data.redirect_url);
      } else {
        throw new Error('Fetch was not successful');
      }
    })
    .catch(err => console.log(err));
};

export const CustormerFace = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const authType = useSelector(state => state.authType);
  // const changeFace = state => navigateToFace(dispatch, state);
  const parentOrigin = window.location.origin.replace('up', 'www').replace('www-dev', 'www');
  const useSendmomentData = authType === 'sendmoments' && currentUser.accounts.sendmoments;
  const isGuest = currentUser ? currentUser.cs_role === 'guest' : false;
  const editUserData = () => {
    toggleModalInfo(dispatch, {
      title: 'Änderung Kundendaten',
      message: <EditUserForm
          salutation={currentUser.cs_salutation}
          firstname={currentUser.cs_first_name}
          lastname={currentUser.cs_last_name}
          company={currentUser.cs_firmname}
      />,
      buttons: false,
    });
  };
  const editUserEmail = () => {
    toggleModalInfo(dispatch, {
      title: 'Änderung E-Mail Adresse',
      message: <EditEmailForm email={currentUser.cs_email} />,
      buttons: false,
    });
  };
  const editUserPassword = () => {
    toggleModalInfo(dispatch, {
      title: 'Passwort ändern',
      message: <EditPasswordForm />,
      buttons: false,
    });
  };

  const openRegisterPopup = () => {
    toggleModalInfo(dispatch, {
      title: '',
      message: <RegisterForm isGuest={true} onSuccess={logOut} />,
      buttons: false
    });
  };
  const openLoginPopup = () => {
    toggleModalInfo(dispatch, {
      title: '',
      message: <LoginForm isGuest={true} />,
      buttons: false
    });
  };

  const settingComponent = () => {
    if (useSendmomentData) {
      return (
        <>
          <EventTitle text={t('settings')} />
          <UserButton text={ getSalutationLabel(currentUser.cs_salutation) } click={editUserData} />
          { currentUser.cs_salutation === 'firma' ?
            <UserButton text={currentUser.cs_firmname || ''} click={editUserData} />
            : null
          }
          <UserButton text={currentUser.cs_first_name || ''} click={editUserData} />
          <UserButton text={currentUser.cs_last_name || ''} click={editUserData} />
          <UserButton text={currentUser.cs_email || ''} click={editUserEmail} />
          <UserButton hideBorder={true} text="Passwort ändern" click={editUserPassword} />
        </>
      );
    } else if (isGuest) {
        return (
          <>
            <FullButton text={'Vollen Funktionsumfang erhalten'} btnColor="mintGreen" click={openRegisterPopup} />
            <FullButton text={'Sie haben bereits ein Konto'} btnColor="mintGreen" click={openLoginPopup} />
          </>
        );
    } else {
      return (
        <>
          <EventTitle text={t('connected_accounts')} />
          <StyledSocialName user={currentUser} />
        </>
      );
    }
  };

  return (
    <Wrapper>
      <BackToMain mb={30} />
      {
        settingComponent()
      }

      <Separator style={{ marginTop: 'auto' }} />
      <LinksList>
        <Link href={`${parentOrigin}/ueber-sendmoments.php`} target="_blank"><img src={about_us} />Über uns</Link>
        <Link href={`${parentOrigin}/impressum.php`} target="_blank"><img src={imprint} />Impressum</Link>
        <Link href={`${parentOrigin}/agb.php`} target="_blank"><img src={imprint} />AGB</Link>
        <Link href={`${parentOrigin}/datenschutz.php`} target="_blank"><img src={privacy} />Datenschutz</Link>
      </LinksList>
      {!isGuest && <FullButton text={t('logout')} btnColor="mintGreen" click={logOut} />}
    </Wrapper>
  );
};
