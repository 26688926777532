import { LOG_USER, LOGGED_USER, USER_FAILURE, EVENT_LIST, EVENT_TYPES, MODAL_INFO, AUTH_TYPE } from '../types';
import { switchEvent, addEvent } from './event';
import Cookies from 'js-cookie';
import { joinEventCode } from '../../components/common/activateCode';
import {isIframe} from "../../utils/isIframe/isIframe";

const userLoading = () => ({ type: LOG_USER, payload: null });
const userLoaded = user => ({ type: LOGGED_USER, payload: user });
const userLoadError = () => ({ type: USER_FAILURE, payload: { error: true, value: 'text_error' } });
const authType = authType => ({ type: AUTH_TYPE, payload: authType });

const successfullyJoinedEvent = event => ({ type: MODAL_INFO, payload: { open: true, title: 'BEITRITT ERFOLGREICH', message: `Sie sind erfolgreich folgendem Event beigetreten:\n\n${event.evt_name} am ${event ? new Date(event.evt_event_date).toISOString().slice(0, 10) : ''}`, buttonText: 'Weiter zum Event' }});
const unableToJoinEvent = message => ({ type: MODAL_INFO, payload: { open: true, title: 'Event kann nicht angelegt werden.', message }});
const alreadyJoinedEvent = event => ({ type: MODAL_INFO, payload: { open: true, title: 'BEREITS BEIGETRETEN', message: `Diesem Album sind Sie bereits beigetreten:\n\n${event ? event.evt_name : ''} am ${event ? new Date(event.evt_event_date).toISOString().slice(0, 10) : ''}`, buttonText: 'Weiter zum Event' }});

const userEvents = events => ({ type: EVENT_LIST, payload: events });
const eventTypes = events => ({ type: EVENT_TYPES, payload: events });
export const getCurrentUser = async (dispatch, code) => {
  dispatch(userLoading);

  const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}${'api/user/currentuser'}`;

  const data = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    crossDomain:true,
    dataType : 'jsonp',
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        return data;
      } else if (data.message === 'redirect') {
        window.location.replace(data.data.redirect_url);
      } else {
        throw new Error('Fetch was not successful');
      }
    })
    .catch(err => {
      console.log(err);
      // window.location.replace('https://post.005.engine.sendmoments.com/up');
      }
    );

  if (data) {
    let eventsForUser = false;

    if (!!data.eventList) eventsForUser = isIframe ? data.eventList.filter(event => event.evt_mode !== 'up') : data.eventList.filter(event => event.evt_mode === 'up');

    dispatch(authType(data.authType));
    dispatch(userLoaded(data.currentUser));

    dispatch(userEvents(eventsForUser));
    dispatch(eventTypes(data.eventTypes));

    let activeEventId = Cookies.get('active_event');
    let activeEvent = eventsForUser ? eventsForUser.filter(e => String(e.id) === activeEventId)[0] || eventsForUser[0] : false;

    const temporaryEventCode = Cookies.get('temporary_event');
    if (temporaryEventCode) {
      const inList = eventsForUser ? eventsForUser.filter(e => String(e.evt_code) === temporaryEventCode)[0] : false;

      if (inList) {
        dispatch(alreadyJoinedEvent(inList));

        switchEvent(dispatch, inList);
        Cookies.set('active_event', inList.id);
      } else {
        joinEventCode(
          temporaryEventCode, 
          (data) => {
            Cookies.set('active_event', data.event.id);

            addEvent(dispatch, data.event);
            //switchEvent(dispatch, data.event);

            dispatch(successfullyJoinedEvent(data.event));
          }, 
          (message) => {
            if (message === 'already_exist' || message === 'already_exists') {
              const inList = eventsForUser ? eventsForUser.filter(e => String(e.evt_code) === temporaryEventCode)[0] : false;
              dispatch(alreadyJoinedEvent(inList));
              activeEvent = inList || activeEvent;
            } else {
              dispatch(unableToJoinEvent(message))
            }

            if (activeEvent) {
              switchEvent(dispatch, activeEvent);
            }
          }
        );
      }

      Cookies.remove('temporary_event');
    } else if (activeEvent) {
      switchEvent(dispatch, activeEvent);
    }

  } else {
    dispatch(userLoadError());
  }

  return data;
};
