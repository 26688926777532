import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 100%;
`;

const NavButton = styled(NavLink)`
  margin-top: 50px;
  padding: 21px 49px;
  border-radius: 90px;
  color: #fff;
  font-family: ${({ theme }) => theme.font.default};
  letter-spacing: 3px;
  background-color: ${({ theme }) => theme.palette.mintGreen};
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 901px) {
    font-size: 13px;
  }
`;

const ErrorName = styled.div`
  font-size: 134px;
  height: 300px;
  align-items: center;
  display: flex;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.mintGreen};
  font-family: ${({ theme }) => theme.font.default};
  ${({ theme }) =>
    theme.onMD(`
     font-size: 90px;
     height: 200px;
  `)}
`;

const ErrorDescription = styled.div`
  margin-bottom: 15px;
  font-size: 25px;
  font-family: ${({ theme }) => theme.font.default};
  text-transform: uppercase;
  letter-spacing: 0.25px;
  ${({ theme }) =>
    theme.onMD(`
      font-size: 25px;
      text-align: center;
  `)}
`;

export const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <BodyContainer>
        <ErrorName> {t('error_page_text')} </ErrorName>
        <ErrorDescription>{t('error_page_description')}</ErrorDescription>
        <NavButton to="/">{t('error_page_btn')}</NavButton>
      </BodyContainer>
    </>
  );
};
