import React, {useEffect} from 'react';
import styled from 'styled-components';

import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";

const DescriptionHead = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: ${({theme}) => theme.font.default};
  color: ${({theme}) => theme.palette.dark};
  ${({theme}) =>
	theme.onMD(`
    margin: 35px 0 25px !important;
    font-size: 18px;
  `)}
`;

const DescriptionText = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal 300 16px/25px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;


const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({isMobile}) => isMobile ? 'column': 'row'};
  width: ${({btnWidthDesktop}) => btnWidthDesktop ? btnWidthDesktop * 2 + 15: 355}px;
  margin: 35px auto 0 auto;
  ${({theme}) =>
	theme.onMD(`
      width: 100%;
      height: 111px;
  `)}
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  width: ${({btnWidthDesktop}) => btnWidthDesktop || 160}px;
  height: 38px;
  line-height: 36px;
  overflow: hidden;
  vertical-align: center;
  border: 1px solid #D1D3D4;
  border-radius: 19px;
  font-size: 14px ;
  font-family: ${({theme}) => theme.font.default};
  color: ${({ color, theme: {palette}}) => palette[color]};
  background-color: ${({ backgroundColor, theme: {palette} }) => palette[backgroundColor]};
  cursor: pointer;
  &:hover {
    ${({hoverStyle}) => hoverStyle};
  }
  
  ${({theme}) =>
	theme.onMD(`
      width: 100%;
      height: 48px;
      line-height: 46px;
      border-radius: 24px;
  `)}
`;

export const PromptModalInner = ({ actionToDo, onClose, title, description, textCancel, textAccept, btnColors, btnWidthDesktop, timerAction, timerMS, rawDescription }) => {
	const {isMobile} = useWindowWidth();

	useEffect(() => {
		if (timerAction && timerMS) {
			setTimeout(timerAction, timerMS)
		}
	}, [timerAction, timerMS])

	const defaultBtnColors = {
		cancel: {
			color: 'dark',
			bgColor: 'white',
			hoverStyle: 'border: 1px solid #000000;'
		},
		accept: {
			color: 'white',
			bgColor: 'newDart',
			hoverStyle: 'background-color: #000000;'
		}
	}

	let btns = defaultBtnColors;

	if (btnColors) {
		btns = btnColors
	}

	return (
		<>
			{title && <DescriptionHead>{title}</DescriptionHead>}
			{description && <DescriptionText>
				{description}
			</DescriptionText>}
			{!!rawDescription && rawDescription}
			{!!btnColors && (
				<ButtonsContainer btnWidthDesktop={btnWidthDesktop} isMobile={isMobile}>
					<Button
						color={btns.cancel.color}
						backgroundColor={btns.cancel.bgColor}
						onClick={onClose}
						hoverStyle={btns.cancel.hoverStyle}
						btnWidthDesktop={btnWidthDesktop}
					>
						{textCancel}
					</Button>
					<Button
						color={btns.accept.color}
						backgroundColor={btns.accept.bgColor}
						onClick={actionToDo}
						hoverStyle={btns.accept.hoverStyle}
						btnWidthDesktop={btnWidthDesktop}
					>
						{textAccept}
					</Button>
				</ButtonsContainer>
				)
			}
		</>
	)
};
