import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InputContainer } from './parts/inputContainer';
import { FullButton } from '../common/fullBtn';
import { toggleModalInfo, toggleModalEvent } from '../../redux/actions/modals';
import { deleteEvent, switchEvent } from '../../redux/actions/event';

const Title = styled.div`  
  margin-bottom: 40px;
  font-size: 21px;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  ${({ theme }) =>
    theme.onMD(`
    font-size: 15px;
    line-height: 19px;
  `)}
`;

export const DeleteEventForm = ({ event }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const eventList = useSelector(state => state.eventList);
  const activeEvent = useSelector(state => state.activeEvent);
  const switchActiveEvent = state => switchEvent(dispatch, state);
  const [safeWord, setSafeWord] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const isValid = (value, noError = false) => {
      const valid = (value || safeWord || '').trim() === 'LÖSCHEN';
      const message = valid ? '' : 'Tragen Sie LÖSCHEN ein.';

      if (!noError && errorMessage !== message) {
        setErrorMessage(message)
      }

    return valid;
  }

  const submitForm = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'api/event/delete'}`;

    if (isValid()) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: event.id, 
          delete: safeWord.trim()
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            closePopup();
            toggleModalEvent(dispatch, false);

            if (activeEvent === event && eventList.length > 1) {
              switchActiveEvent(eventList.filter(e => e !== event)[0]);
            }
            
            deleteEvent(dispatch, event)

          } else {
            setErrorMessage(t(data.error));
          }
        })
        .catch(err => console.log(err));
    }
  }

  const closePopup = () => {
    toggleModalInfo(dispatch, false);
  }

  return (
    <>
      <Title>
        ACHTUNG: Das Album  und alle Bilder werden unwiederruflch entfernt.<br />
        Danach ist der Zugang für niemanden mehr möglich. <br />
        <br />
        Bitte tragen Sie aus Sicherheitsgründen LÖSCHEN in das untere Feld ein.
      </Title>

      <InputContainer 
        name="safe_word" 
        value={safeWord}
        onChange={({ target: { value } }) => setSafeWord(value) }
        onBlur={ ({ target: { value } }) => isValid(value) }
        error={errorMessage}
      />

      <FullButton genWith="386px"
        mt={30}
        btnColor={ isValid(null, true) ? "mintGreen" : "darkRed"}
        text={ t('delete_event') }
      click={submitForm}></FullButton>

      <FullButton genWith="386px"
        mt={20}
        btnColor="lightGray"
        text={t('cancel')}
        click={closePopup}></FullButton>
    </>
  );
};