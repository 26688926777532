import React from 'react';

export const CustomCount = (props) => {
  const { currentIndex, getStyles, totalAmount } = props;
  const activeView = currentIndex + 1;

  if (!activeView || !totalAmount) return null;

  return (
    <span style={getStyles('footerCount', props)} className='footer__count'>
      {activeView} von {totalAmount}
    </span>
  );
};