import React, {useState, useEffect, useCallback, useRef} from 'react';
import styled from 'styled-components';
import {LabelIcon, HeartIcon, DownloadIconBottom, DeleteIcon} from "../icons/GalleryPhotoIcons";
import { useDoubleTap } from 'use-double-tap';
import {useDispatch, useSelector} from "react-redux";

import { useDrag, useDrop } from 'react-dnd';

import {selectImage, selectRange, toggleSize, unselectImage} from "../../redux/actions/gallery";
import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";
import {usePostMessageIFrame} from "../../utils/usePostMessageIFrame";
import {CheckMark} from "./parts/checkMark";


import crown_icon from '../../static/assets/icons/crown_icon.svg';
import favoriteWhiteFill from '../../static/assets/icons/favorite_white_fill.svg';
import commentWhiteFill from '../../static/assets/icons/comment_white_fill.svg';
import hover_cross from '../../static/assets/icons/hover_cross.svg';



export const Photo = props => {
  const {
    index,
    photo,
    renderPhoto,
    margin,
    top,
    left,
    alt,
    download,
    square,
    onClick,
    eventId,
    onToggleFavorite,
    movePhoto,
    hoverOn,
    changeIsHover
  } = props;

  const { thumbnailBig, thumbnailSmall, id, ph_aws_id, selected, favorite, ownerName, favorite_count, ownerID, comments, comment, order, ...imgProps} = photo;

  const dispatch = useDispatch();
  const [src, setSrc] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [hoverStyles, changeHoverStyles] = useState({});
  const { offsetTop } = usePostMessageIFrame();
  const ref = useRef();
  const imageRef = useRef();
  const {isMobile} = useWindowWidth();
  const activeEvent = useSelector(state => state.activeEvent);
  const allEventPictures = useSelector(state => state.eventPictures);
  const currentUser = useSelector(state => state.currentUser);
  const {selectMode, selectedImages, lastSelectedIndex} = useSelector(state => state.selectionReducer);
  const {draggable} = useSelector(state => state.dragReducer);

  const photos = allEventPictures[activeEvent.id] ?  allEventPictures[activeEvent.id].pictures : [];

  useEffect(() => {
    if (hoverOn && hoverOn.id == photo.id) {
      if (hoverOn.on === 'left') {
        changeHoverStyles({paddingLeft: '20px', transform: 'rotate(1deg)'});
      } else {
        changeHoverStyles({marginLeft: '-20px', transform: 'rotate(-1deg)'});
      }

    } else {
      changeHoverStyles({});
    }
  }, [hoverOn])

  const [{isDragging}, drag] = useDrag(() => ({
    type: 'photo',
    item: { id: photo.id, index },
    canDrag:() => draggable,
    collect: (monitor) => {

      return {
        isDragging: !!monitor.isDragging()
      }
    },
    end: (item, monitor) => {
      changeIsHover({id: 0, on: 'left'})
    }
  }), [draggable]);

  const [, drop] = useDrop({
    accept: 'photo',
    drop: (item, monitor) => {

      if (!ref.current) {
        return;
      }
      const dragIndex = item.id;
      const hoverIndex = photo.id;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.x - hoverBoundingRect.right;

      let data = {};
      if (hoverClientY < hoverMiddleY) {
        data = {left: dragIndex, right: hoverIndex, pointer: hoverIndex, current: dragIndex}

        movePhoto(data);
      } else if (hoverClientY > hoverMiddleY) {
        data = {left: hoverIndex, right:  dragIndex, pointer: hoverIndex, current: dragIndex}
        movePhoto(data);
      }


      return;
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.id;
      const hoverIndex = photo.id;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.x - hoverBoundingRect.right;

      if (hoverClientY < hoverMiddleY) {
        changeIsHover({id: photo.id, on: 'left'});
      } else if (hoverClientY > hoverMiddleY) {
        changeIsHover({id: photo.id, on: 'right'});
      }

      return false;
    },
  });

  const onLoad = () => {
      setLoaded(true);
  }

  const isPhotoOwner = currentUser.id === ownerID;

  const [bindClick, setBindClick] = useState({
    onClick: (e) => {
      onClick(e, props);
    }}
  )

  const doubleClick = useDoubleTap(e => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN || '/'}api/photo/${favorite ? 'remove_favorite' : 'add_favorite'}/${photo.id}`;

    fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          onToggleFavorite(eventId, photo.id, favorite);
        }
      })
      .catch(err => console.log(err));
    }
  )

  useEffect(() => {
    if (isMobile) {
      if (square && !isHost) {
        setBindClick({...{
            onClick: async  (e) => {
              toggleSize(dispatch,'big');
              await new Promise((res) => {


                setTimeout(() => {
                  res(true);
                }, 150)
              });

              const element = document.getElementById(ph_aws_id);

              setTimeout(() => {
                const rect = element.getBoundingClientRect();
                const topPosition = rect.top + window.scrollY - 45;

                window.scrollTo({
                  top: topPosition,
                  behavior: 'smooth'
                });
              }, 0);
            }
          }})

      } else if (isHost) {
        setBindClick({...{
          onClick: (e) => {
            onClick(e, props);
          }}})
      } else {
        setBindClick({
          ...doubleClick
        })
      }
    }
  }, [square, favorite])

  useEffect(() => {
    const onScroll = () => {
      if (!src && imageRef.current) {
        const bbox = imageRef.current.getBoundingClientRect();
        const clientHeight = window.innerHeight + (offsetTop || 0);

        let bboxTop = bbox.top + (offsetTop || 0);

        if (bboxTop + bbox.height > - clientHeight * 0.5 && bboxTop < clientHeight * 1.5) {
          window.removeEventListener('scroll', onScroll);

          setSrc(square ? thumbnailSmall : thumbnailBig);
        }
      }
    };

    window.addEventListener('scroll', onScroll);

    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, [offsetTop, square, photo]);

  useEffect(() => {
    if (src) {
      setSrc(square ? thumbnailSmall : thumbnailBig);
    }
  }, [src, square, photo]);

  const {height, width} =  renderPhoto;
  const isUpEvent = activeEvent && (activeEvent.evt_mode === 'up');
  const isHost = activeEvent.evt_owner_id === currentUser.id;

  const nickNameToShow = () => {
    if (activeEvent.evt_mode === 'thank') return ''
    else if (activeEvent.evt_owner_id === ownerID) return <HostNicknameContainer> <img src={crown_icon} alt={'admin_crown'}/> Admin</HostNicknameContainer>
    else return ownerName
  }

  const squareLabels = useCallback(() => {
    if (square) {
      const showCommentLabel = isHost && comments ? true : !!comment;
      const showFavoriteLabel = isHost && favorite_count ? true : !!favorite;

      return (
        <SquareLabelWrapper>

          {showCommentLabel && <SquareLabel style={{width: '20px'}} draggable={false} src={commentWhiteFill} alt={'comment_white_fill'}/>}

          {showFavoriteLabel && <SquareLabel style={{width: '20px'}} draggable={false} src={favoriteWhiteFill} alt={'favorite_white_fill'}/>}

        </SquareLabelWrapper>
      );
    } else return null;

  }, [square, favorite_count, favorite, comment, comments]);

  const handleImageClick = (id, index, event) => {
    if (event.shiftKey && lastSelectedIndex !== null) {
      event.preventDefault();

      const start = Math.min(lastSelectedIndex, id);
      const end = Math.max(lastSelectedIndex, id);

      const selectedRange = photos.reduce((acc, img) => {
        if (img.order && (img.order >= start) && (img.order <= end)) return [...acc, img.order]
        else if ((img.id >= start) && (img.id <= end)) return [...acc, img.id]
        return acc;
      }, [])

      dispatch(selectRange(selectedRange, null));
    } else {
      // Select a single image
      dispatch(selectImage(id, id));
    }

  }

  drag(drop(ref));

  return (
    <div ref={ref}>
      {
        (isDragging || (hoverStyles && !!Object.values(hoverStyles).length)) && (
          <DraggingPlaceholder
            left={left}
            top={top}
            width={width}
            height={height + 40}
          />
        )
      }
      <Wrapper
        id={ph_aws_id}
        isDragging={isDragging}
        style={{ opacity: isDragging ? 0.0 : 1, background: "transparent",...hoverStyles }}
      >
        <Container
          className="photo"
          margin={margin}
          left={left}
          top={top}
          width={width}
          height={height + 40}
          square={square}
          download={download}
          loaded={loaded}
        >
          <img
            ref={imageRef}
            {...imgProps}
            height={height}
            width={width}
            src={src}
            alt={alt || ''}
            onLoad={onLoad}
            {...bindClick}
            draggable={false}
          />
          { squareLabels() }

          {
            selectMode ? (
              <SelectMarkContainer
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()

                  if (selectedImages.includes(order || id)) {
                    dispatch(unselectImage(order || id))
                  } else {
                    handleImageClick(order || id, index, e)
                  }
                }}
              >
                <CheckMark isActive={selectedImages.includes(order || id)} glow={lastSelectedIndex === (order || id)} />

              </SelectMarkContainer>
            ) : null
              // (<div style={{position: 'absolute', top: 10, left: 10}}>{id}</div>)
          }

          {
            !square && (
              <BottomContainer>
                <NicknameContainer>
                  {nickNameToShow()}
                </NicknameContainer>
                <ButtonsWrapper>
                  <HeartIcon
                    selected={true}
                    photoID={photo.id}
                    eventID={eventId}
                    isFavorite={favorite}
                    isHost={isHost}
                    onToggle={onToggleFavorite}
                    count={favorite_count}
                  />
                  {!isUpEvent && <LabelIcon
                                    isHost={isHost}
                                    ownerName={ownerName}
                                    photoID={photo.id}
                                    eventID={eventId}
                                    thumbnail={thumbnailSmall}
                                    count={comments}
                                    myComment={comment}
                                    labelExist={null}
                                  />
                  }
                  <DownloadIconBottom
                    awsID={ph_aws_id}
                    eventID={eventId}
                  />

                  {(isHost || isPhotoOwner) && (
                   <DeleteIcon eventID={eventId} photoID={ph_aws_id}/>
                  )}
                </ButtonsWrapper>
              </BottomContainer>
            )
          }
        </Container>
        {draggable && <HoverCross src={hover_cross} className='hover-cross'/>}
      </Wrapper>
    </div>
  );
};


const Wrapper = styled.div`
  display: flex;
  flex-wrap:wrap;
  flex-direction: column;
  position: relative;
  &:hover .hover-cross {
      opacity: 1;
  }
`;

const DraggingPlaceholder = styled.div`
    position: absolute;
    left: ${({left}) => left}px;
    top: ${({top}) => top}px;
    width: ${({width}) => width}px;
    height: ${({width}) => width}px;
    background-color: #d8d8d8;
    border: 1px dashed #C3BBBB;
    border-radius: 5px;
`;

const HoverCross = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px; 
    height: 50px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
`;

const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  cursor: default;
`;

const NicknameContainer = styled.div`
  font: normal normal normal 16px/21px Roboto;
  font-family: ${({theme}) => theme.font.default};
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HostNicknameContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  height: 100%;
`;


const Container = styled(({ left, top, width, height, margin, download, square, loaded, ...props }) => (
  <div {...props} />
))`
  display: inline-flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  background: linear-gradient(-45deg, #FFFFFF, #EEEEEE, #FFFFFF, #EEEEEE);
  background-size: 400% 400%;
  animation: gradient ${ 1 + Math.random() * 6 }s ease infinite;
  margin-bottom: ${({square}) => square ? '5px': '20px' };
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  img { opacity: 1; transition: opacity .3s; }

  ${({ left, top, width, height, margin, square, loaded}) => `
    position: relative;
    left: ${square ? 0 : left}px;
    top: ${square ? 0 : top}px;
    width: ${width}px; 
    height: ${square ? width : height}px;

    ${
      !loaded ? `
      img { opacity: 0; }
      ` : ''
    }
    
    ${
      square
        ? `
      img { 
        ${width > height ? 'height' : 'width'}: 100%; 
        ${width > height ? 'width' : 'height'}: auto;
       }`
        : ''
    }
  `}
  ${props =>
    props.download
      ? `opacity: .7;
    &:hover { opacity: 1; }
    `
      : ''}
`;

const DownloadIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30% !important;
  height: auto;
  transform: translate(-50%, -50%);
  cursor: pointer;
  -webkit-user-drag: none;
`;

const SelectMarkContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const SquareLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 32%;
  padding-top: 6px;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent linear-gradient(180deg, #65656591 0%, #8F8F8F3C 61%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
`;

const SquareLabel = styled.img`
  width: 16px;
  height: auto;
  margin-right: 10px;
  user-select: none;
`;