import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { formatLong } from '../../utils/dateFns';

import { showSidebar } from '../../redux/actions/sidebar';
import { switchEvent } from '../../redux/actions/event';
import { toggleModalJoin } from '../../redux/actions/modals';
import { toggleModalEvent } from '../../redux/actions/modals';
import { navigateToFace } from '../../redux/actions/sidebar';

import { FullBtnWithIcon } from './parts/fullBtnIcon';
import { BackToMain } from './parts/backToMain';
import { ActionButton } from '../common/actionButton';
import { TextHead } from './parts/textHead';
import { EventElement } from './parts/eventElement';

import settings_icon from '../../static/assets/icons/settings_icon.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;

export const EventFace = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const events = useSelector(state => state.eventList);
  const activeEvent = useSelector(state => state.activeEvent);

  const changeFace = state => navigateToFace(dispatch, state);
  const changeActiveEvent = event => switchEvent(dispatch, event);
  const changeModalJoin = state => toggleModalJoin(dispatch, state);
  // const changeModalEvent = state => toggleModalEvent(dispatch, state);
  const changeSidebarState = state => showSidebar(dispatch, state);

  const propEvents = events.filter(e => e.evt_mode === 'up').map(e => (
    <EventElement
      key={e.id}
      active={e.id === activeEvent.id}
      title={e.evt_name || 'none'}
      date={(!!e.evt_event_date && formatLong(e.evt_event_date)) || 'none'}
      click={() => {
        changeActiveEvent(e);
        Cookies.set('active_event', e.id);
        changeSidebarState(false);
        changeFace('main');
      }}
    />
  ));
  return (
    <Wrapper>
      <BackToMain />
      <TextHead text={t('events')} />
      {propEvents}
      <div style={{ marginTop: 'auto' }} />
      {/*<FullBtnWithIcon*/}
      {/*  margins={'70px 0 0 0'}*/}
      {/*  bgColor="mintGreen"*/}
      {/*  text={t('create_new_event')}*/}
      {/*  click={() => changeModalEvent(true)}*/}
      {/*/>*/}
      {/*<FullBtnWithIcon*/}
      {/*  margins={'15px 0 20px 0'}*/}
      {/*  bgColor="mintGreen"*/}
      {/*  text={t('join_event')}*/}
      {/*  click={() => changeModalJoin(true)}*/}
      {/*/>*/}
      {/*<ActionButton*/}
      {/*  hideBorder={true}*/}
      {/*  iconSrc={settings_icon}*/}
      {/*  iconProps={{ width: 36, height: 36 }}*/}
      {/*  mobIconProps={{ width: 26, height: 26 }}*/}
      {/*  text={t('settings')}*/}
      {/*  click={() => { */}
      {/*    changeFace('customer');*/}

      {/*    if (!isMobile && document.documentElement.scrollTop) {*/}
      {/*      document.documentElement.scrollTop = 0;*/}
      {/*    }*/}
      {/*  }}*/}
      {/*/>*/}
    </Wrapper>
  );
};
