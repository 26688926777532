import React from 'react';
import { Icon } from '../icons/Icon';

const colors = ['currentColor'];
const hoverColors = ['currentColor'];

const paths = [ 'M14.016 5.016h4.969v4.969h-1.969v-3h-3v-1.969zM17.016 17.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 9.984v-4.969h4.969v1.969h-3v3h-1.969zM6.984 14.016v3h3v1.969h-4.969v-4.969h1.969z' ];

export const FullScreenEnterIcon = (props) => {
    return (
        <Icon viewBox="0 0 24 24" colors={colors} hoverColors={hoverColors} {...props} paths={paths}/>
    )
};