import { SIDEBAR_OPEN, SIDEBAR_CLOSE, FACE_MAIN, FACE_EVENTS, FACE_GUESTS, FACE_CUSTOMER } from '../types';

export const sidebarStatus = (state = false, action) => {
  switch (action.type) {
    case SIDEBAR_OPEN:
      return action.payload;
    case SIDEBAR_CLOSE:
      return action.payload;
    default:
      return state;
  }
};

export const sidebarFace = (state = 'main', action) => {
  switch (action.type) {
    case FACE_MAIN:
      return action.payload;
    case FACE_EVENTS:
      return action.payload;
    case FACE_GUESTS:
      return action.payload;
    case FACE_CUSTOMER:
      return action.payload;
    default:
      return state;
  }
};
