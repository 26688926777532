import React from 'react';
import styled from 'styled-components';

import google_logo from '../../static/assets/icons/googleLogo.svg';
import facebook_logo from '../../static/assets/icons/facebookLogo.svg';

const Link = styled(({ google, facebook, children, noMB, ...props }) => <a {...props}>{children}</a>)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  width: 280px;
  max-width: 100%;
  font-family: ${({ theme }) => theme.font.default};
  background-color: ${({ google, theme }) => google ? '#FFFFFF' : '#1877f3'};
  font-size: 20px;
  border-radius: 3px;
  border: 1px solid ${({ google, theme }) => google ? theme.palette.lightGray : '#1877f3'};
  color: ${({ google, theme }) => google ? theme.palette.lightGray : '#FFFFFF'};
  margin-bottom: ${({noMB}) => noMB ? 0 : 20}px;

  img { width: 27px; height: ${({ google }) => google ? 23 : 27}px; margin-right: 10px;}

  &:hover {
    color: ${({ google, theme }) => google ? theme.palette.lightGray : '#FFFFFF'};
    background-color: ${({ google, theme }) => google ? '#ececec' : '#0067ef'};
  }

  ${({ theme, google }) =>
    theme.onSM(`
    font-size: 16px;
    padding: 8px;

    img { width: 23px; height: ${ google ? 21 : 27}px; margin-right: 10px;}
  `)}
`;

export const SocialLink = ({ google = false, facebook = false, noMB = false, children, ...props }) => (
    <Link google={google} facebook={facebook} noMB={noMB} {...props}>
        <img src={ google? google_logo : facebook_logo } />
        { children }
    </Link>
);
