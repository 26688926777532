import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import styled from 'styled-components';

import trash_white from '../../../static/assets/icons/trash_white.svg';
import download_white from '../../../static/assets/icons/download_white.svg';
import label_white_empty from '../../../static/assets/icons/comment_white_border.svg';
import label_white_filled from '../../../static/assets/icons/comment_white_fill.svg';

import favorite_white_border from '../../../static/assets/icons/favorite_white_border.svg';
import favorite_white_fill from '../../../static/assets/icons/favorite_white_fill.svg';

import {DeleteIcon, HeartIcon, LabelIcon} from "../../icons/GalleryPhotoIcons";


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 240px;
`;

const IconWrapper = styled.div`
  max-width: 40px;
  height: auto;
  margin-left: 32px;
  margin-bottom: ${({mb}) => mb? mb: 0}px;
  user-select: none;
  cursor: ${({disableCursor}) => disableCursor ? 'not-allowed': 'pointer' };
  opacity: ${({disableCursor}) => disableCursor ? '0.3': '1' };
`;

const CountWrapper = styled.div`
  color: #FFFFFF;
  font: normal normal normal 12px/16px Roboto;
  font-family: ${({theme}) => theme.font.default};
  margin-top: 5px;
  margin-left: 3px;
`;

export const CustomCaption = (props) => {
  const { eventId, currentIndex, currentView: {
    id, awsId, onDelete, imageSize, error, isFavorite, toggleFavorite,
    favorite,  favorite_count, comments, comment, thumbnail, ownerName, isHost
  }} = props;

  const activeEvent = useSelector(state => state.activeEvent);
  const isUpEvent = activeEvent && (activeEvent.evt_mode === 'up');

  const url = `${process.env.REACT_APP_BACKEND_DOMAIN ||
  '/'}api/photo/download/${eventId}/${awsId}`;

  return (
    <Wrapper>
      <Container>
        {/*like section*/}

        <IconWrapper disableCursor={!favorite_count && isHost} mb={2}>
          <HeartIcon
            count={favorite_count}
            isHost={isHost}
            photoID={id}
            eventID={eventId}
            isFavorite={favorite}
            onToggle={()=>{}}
            innerComponent={
              favorite ?
              <img src={favorite_white_fill} alt={'empty_comment'}/>
              : <img src={favorite_white_border} alt={'filled_comment'}/>
            }
          />
        </IconWrapper>
        {
          !!favorite_count && <CountWrapper>
          {favorite_count}
        </CountWrapper>
        }


        {/*commenting section*/}
        {!isUpEvent && (!isHost ? (
          <>
            <IconWrapper>
              <LabelIcon
                count={comments}
                labelExist={comment}
                isHost={isHost}
                photoID={id}
                eventID={eventId}
                thumbnail={thumbnail}
                ownerName={ownerName}
                innerComponent={comment ? <img src={label_white_filled} alt={'empty_comment'}/>: <img src={label_white_empty} alt={'filled_comment'}/>}
              />
            </IconWrapper>
            {
              !!comments && (
                <CountWrapper>
                  {comments}
                </CountWrapper>
              )
            }
          </>
        ) :  (
          <>
            <IconWrapper disableCursor={!comments}>
              <LabelIcon
                count={comments}
                labelExist={comment}
                isHost={isHost}
                photoID={id}
                eventID={eventId}
                thumbnail={thumbnail}
                ownerName={ownerName}
                innerComponent={<img src={label_white_empty} alt={'empty_comment'}/>}
              />
            </IconWrapper>
            {!!comments && <CountWrapper>
              {comments}
            </CountWrapper>}
          </>
        ))}

        <IconWrapper>
          <a key="download-link" href={url} target="_blank" download>
            <img src={download_white} alt={'download'}/>
          </a>
        </IconWrapper>

        <CountWrapper>
          {imageSize}
        </CountWrapper>
        {onDelete && (
        <IconWrapper>
          <DeleteIcon
            eventID={eventId}
            photoID={awsId}
            iconSvg={trash_white}
            onDeleteCallback={onDelete}
            btnParams={{width: 'auto', height: 'auto', maxWidth: '40' }}
            />
        </IconWrapper>
        )}
      </Container>
      </Wrapper>
    )

  // return (
  //   error ? null :
  //   <>
  //     <DownloadButton eventID={eventId} awsID={awsId} imageSize={imageSize} />
  //     { onDelete ? (
  //       <DeleteButton
  //         eventID={eventId}
  //         photoID={awsId}
  //         onSuccess={() => {
  //           if (typeof onDelete === 'function') {
  //             onDelete();
  //           }
  //         }}
  //       />
  //     ) : null}
  //     <ToggleFavorite
  //       eventID={eventId}
  //       photoID={id}
  //       isFavorite={isFavorite}
  //       onToggle={toggleFavorite}
  //     />
  //   </>
  // )
};