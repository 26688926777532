import React from 'react';
import { Icon } from '../icons/Icon';

const colors = ['#8a8480'];
const hoverColors = ['#8a8480'];

const paths = [ 'M18.917 10.548l-6.431 6.098a.655.655 0 01-.912-.024l-5.753-6.05a.645.645 0 01.935-.887l5.31 5.584 5.964-5.655a.645.645 0 01.887.934z' ];

export const ArrowIcon = (props) => {
    return (
        <Icon viewBox="0 0 26 26" colors={colors} hoverColors={hoverColors} {...props} paths={paths}/>
    )
};