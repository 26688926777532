import React, {useState} from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import {UnderlineInputContainer} from "../forms/parts/underlineInput";

import checkbox_big from "../../static/assets/icons/checkbox-big.svg";
import checknmark_success_big from "../../static/assets/icons/checkmark_success_big.svg";
import {useDispatch, useSelector} from "react-redux";

import Cookies from 'js-cookie';
import {validate} from "../../utils/validateForms";
import {toggleModalEnhancedRaw, toggleModalPromptRaw} from "../../redux/actions/modals";
import {PromptModalInner} from "./promptModal";
import {UnknownErrorPrompt} from "./unkownErrorPrompt";

import {parentPostMessage, messageKeys} from "../../utils/postMessage";
import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";

const {  dispatch_reload_page } = messageKeys;



const Wrapper = styled.div`
  left: 0;
  position: absolute;
  padding: 20px;
  bottom: 0;
  cursor: pointer;

  svg {
    width: 35px;
    height: 35px;
  }
`;

const DescriptionHead = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: ${({theme}) => theme.font.default};
  color: ${({theme}) => theme.palette.dark};
  ${({theme}) =>
	theme.onMD(`
    margin: 35px 0 25px !important;
    font-size: 18px;
  `)}
`;

const DescriptionText = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal 300 16px/25px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;


const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({isMobile}) => isMobile ? 'column': 'row'};
  width: 355px;
  margin: 37px auto 0 auto;
  ${({theme}) =>
	theme.onMD(`
      width: 100%;
  `)}
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 240px;
  height: 38px;
	margin: 0 auto;
  line-height: 36px;
  overflow: hidden;
  vertical-align: center;
  border: 1px solid #D1D3D4;
  border-radius: 19px;
  font-size: 14px ;
  font-family: ${({theme}) => theme.font.default};
  color: ${({ color, theme: {palette}}) => palette[color]};
  background-color: ${({ backgroundColor, theme: {palette} }) => palette[backgroundColor]};
  cursor: pointer;
  &:hover {
    ${({hoverStyle}) => hoverStyle};
  }
  
  ${({theme}) =>
	theme.onMD(`
      width: 100%;
      height: 48px;
      line-height: 46px;
      border-radius: 24px;
  `)}
`;

const InputWrapper= styled.div`
  width: 293px;
  max-width: 100%;
  margin: 51px auto 0 auto;
`;

const CheckBoxContainer = styled.div`
	display: flex;
	width: 293px;
	margin: 30px auto 0 auto;
	cursor: pointer;
  .round {
		width: 34px;
    position: relative;
  }

  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    //border-radius: 50%;
    cursor: pointer;
    //height: 28px;
    //left: -10px;
    position: absolute;
    top: 0;
    //width: 28px;

    //top: 449px;
    left: -4px;
    width: 21px;
    height: 21px;
  }

  .round label:after {
		content: url("${checkbox_big}");
    //border: 2px solid #fff;
    border-top: none;
    border-right: none;
    //content: "";
    //height: 6px;
    //left: 7px;
    opacity: 0;
    //position: absolute;
    //top: 8px;
    //transform: rotate(-45deg);
    width: 21px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    border: 0;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
`;

const NewspaperText = styled.div`
	width: 259px;
  text-align: left;
  font: normal normal normal 14px/20px Roboto;
`;


export const VoucherInnerModal = ({amount, currency, email, removeVoucherFromList}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const activeEvent = useSelector(state => state.activeEvent);
	const {isMobile} = useWindowWidth();
	const isMenuCard = activeEvent.evt_mode === 'menu';

	const [data, setData] = useState({
		email: email || '',
		newsletter: false
	});

	const [errorMessages, setErrorMessages] = useState({
		general: '',
	});


	const changeData = (value) => {
		if (value && value.length > 128) return;

		const tmpValue = value;

		if (tmpValue || value === "") {
			if (errorMessages) setErrorMessages({general: ''});

			const newData = {
				email: tmpValue ?  tmpValue.trim() : value
			};

			setData(newData);
		}
	};

	const changeNewsletter = () => {
		setData({
			...data,
			newsletter: !data.newsletter
		});
	};

	const closeModalEnhancedRaw = () => {
		toggleModalEnhancedRaw(dispatch, false);
	};

	const requestVoucher =	async (event) => {
		const trimValue = data.email && data.email.trim();
		const validateEmail = validate({ email: trimValue });

		if (validateEmail.email.success) {
			const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'auth/create_event_voucher/'}${activeEvent.id}`;

			await fetch(url, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: data.email,
					newsletter: data.newsletter,
					project: Cookies.get('project') || 'post'
				})
			}).then(response => response.json()).then(data => {
				if (data && !data.error) {
					closeModalEnhancedRaw();
					removeVoucherFromList();
					const onClosePrompt = () => {
						toggleModalPromptRaw(dispatch, false);

						if (data.user) {
							parentPostMessage(dispatch_reload_page);
						}
					}
					toggleModalPromptRaw(dispatch, {
						showCloseButton: false,
						children: (
							<PromptModalInner
								title={'In Kürze erhalten Sie eine E-Mail mit dem Gutschein-Code'}
								rawDescription={<div style={{width: '100%', marginTop:'37px', textAlign:"center"}}><img src={checknmark_success_big} style={{margin: '0 auto'}} alt={'big_checkmark'} width={'80px'} height={'80px'}/></div>}
								actionToDo={() => onClosePrompt()}
								onClose={() => onClosePrompt()}
								timerAction={() => onClosePrompt()}
								timerMS={3000}
							/>
						)
					});
				} else {
					if (data.message === 'voucher_already_sent') {
						setErrorMessages({ general: data.message});
					} else {
						closeModalEnhancedRaw();
						toggleModalPromptRaw(dispatch, {
							children: (
								<UnknownErrorPrompt />
							)
						});
					}
				}
			}).catch(err => console.log(err))
		} else {
			setErrorMessages({ general: validateEmail.email.error});
		}
	}

	return (
		<>
			<DescriptionHead>{amount || 0} {currency || '€'} Gutschein sichern</DescriptionHead>
			<DescriptionText>

				{
					isMenuCard ? 'Wir senden Dir einen individuellen Gutscheincode per E-Mail. Der Gutschein kann für alle sendmoments Produkte eingelöst werden.' :
						'Wir senden Dir einen individuellen Gutscheincode per E-Mail. Der Gutschein kann für alle sendmoments Produkte eingelöst werden.'
				}

			</DescriptionText>

			<InputWrapper>
				<UnderlineInputContainer
					label='E-mail*'
					name="email"
					value={data.email}
					type={'email'}
					onChange={({ target: { value } }) => changeData(value) }
					onKeyPress={(e) => {
						let {key, charCode} = e;
						if (charCode === 13 || key === "Enter") requestVoucher();
					}}
					onBlur={ () => {} }
					placeholder={'Email'}
					holderID={'email-holder'}
					readonly={!!email}
					error={t(errorMessages.general)}
				/>
			</InputWrapper>

			<CheckBoxContainer onClick={() => changeNewsletter()}>
				<div className='round'>
					<input
						type="checkbox"
						name="newsletter"
						checked={data.newsletter}
						readOnly
						onChange={changeNewsletter}
					/>
					<label className="form-control"></label>
				</div>
				<NewspaperText>
					Tolle Geschenkideen und Inspirationen für Deine Liebsten.
					Jetzt Newsletter abonnieren und informiert bleiben.
				</NewspaperText>
			</CheckBoxContainer>

			<ButtonsContainer isMobile={isMobile}>
				<Button
					color={'white'}
					backgroundColor={'newDart'}
					onClick={requestVoucher}
					hoverStyle={'background-color: #000000'}
				>
					Jetzt Gutschein sichern
				</Button>
			</ButtonsContainer>
		</>
	);
}
