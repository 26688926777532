import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { toggleModalCodeActivated } from '../../redux/actions/modals';
import { DefaultModal } from './modal';
// import { FullButton } from '../common/fullBtn';
//
// const ModalHead = styled.div`
//   margin-bottom: 20px;
//   font-size: 21px;
//   text-transform: uppercase;
//   font-family: ${({ theme }) => theme.font.default};
//   color: ${({ theme }) => theme.palette.dark};
//   ${({ theme }) =>
//     theme.onMD(`
//     margin: 25px 0 20px !important;
//     font-size: 18px;
//   `)}
// `;

const InfoContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  color: #000000;
  font-size: 18px;
`;

export const CodeActivatedModal = props => {
    const { event } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  const modalState = useSelector(state => state.modalCodeActivated);
  const changeModalState = state => toggleModalCodeActivated(dispatch, state);

  return (
    <DefaultModal 
      modalState={modalState} 
      modalLabel="code-activated modal" 
      onRequestClose={() => changeModalState(false)} 
      title={t('code_activated')}
      buttons={[ 
        { 
          text: t('login'), 
          onClick: () => {
              changeModalState(false);
              history.push('/login'); 
          },
          width: "386px",
          marginTop: 30
        }, 
        {
          text: "Neues Konto anlegen",
          onClick: () => {
            changeModalState(false);
            history.push('/register'); 
          },
          width: "386px"
        }
      ]}
    >
        <InfoContainer>
          {`Wenn Sie der Veranstaltung ${event ? event.name : ''} am ${event ? new Date(event.date).toISOString().slice(0, 10) : ''} beitreten 
            möchten loggen Sie sich mit ihrem sendmoments Konto ein oder legen Sie ein sendmoments Konto an.`}
        </InfoContainer>
    </DefaultModal>
  );
};
