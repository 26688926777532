import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import delete_grey from '../../static/assets/icons/delete_grey.svg';
import { useTranslation } from 'react-i18next';
import { FullButton } from '../common/fullBtn';

import {useWindowDimensions} from "../../utils/useWindowDimensions";

import {parentPostMessage, messageKeys} from "../../utils/postMessage";
import useWindowWidth from "../../utils/useWindowWidth/useWindowWidth";

const { dispatch_modal_open, dispatch_modal_close } = messageKeys;

Modal.setAppElement(document.getElementById('root'));

const getStyles = (styles, fullOnMobile, maxHeight, isMobile) => {
  if (fullOnMobile && isMobile) {

    /**
     *
top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

     * */

    return {
      content: {
        maxWidth: '100%',
        width: '100%',
        height: `${maxHeight}px`,
        maxHeight: `${maxHeight}px`,
        top: '0',
        // left: '0',

        // top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, 0)',
        // transform: 'translate(-50%, -50%)',

        padding: '0',
        borderRadius: '0',
        boxShadow: '0px 0px 6px #00000029',
        position: 'relative',
        ...(styles ? styles.content : null)
      },
      overlay: {
        overflow: 'hidden',
        zIndex: 1000000,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        ...(styles ? styles.overlay : null)
      },
    };
  }

  return {
    content: {
      maxWidth: '98%',
      width: '570px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0',
      borderRadius: '3px',
      border: '1px solid #ccc',
      boxShadow: '0px 0px 6px #00000029',
      position: 'relative',
      maxHeight: '98%',
      ...(styles ? styles.content : null)
    },
    overlay: {
      overflow: 'hidden',
      zIndex: 1000000,
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      ...(styles ? styles.overlay : null)
    },
  };
};

const CloseContainer = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  ${({ theme }) =>
    theme.onMD(`
    right: 10px;
    top: 10px;
    width: 26px;
    height: 26px;
  `)}
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  &:hover {
    filter: invert(100%);
  }
  ${({ theme }) =>
    theme.onMD(`
    // width: 26px;
    // height: 26px;
  `)}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 25px 25px 25px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ theme }) =>
    theme.onMD(`
    justify-content: flex-start;
    padding: 0 15px 25px 15px;
    height: calc(100% - ${ window.innerWidth * 0.02 }px);
  `)}

  & > *:nth-child(2) { 
    //margin-top: auto;
    ${({ theme }) =>
    theme.onMD(`
      width: 100%;
      text-align: center;
  `)}
  }
  & > *:last-child { margin-bottom: auto; }
`;

const ModalHead = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 20px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  ${({ theme }) =>
    theme.onMD(`
    margin: 35px 0 20px !important;
    font-size: 18px;
  `)}
`;

const Content = styled.div`
  width: 100%;
  overflow: auto;
`;

const Buttons = styled.div`
  width: 100%;
`;

const Button = (props) => {
  const { color, text, width, marginTop = 20, onClick = () => {}, href = null } = props;

  return <FullButton 
    genWith={width}
    mt={marginTop}
    btnColor={color}
    text={text}
    click={onClick}
    href={href}
  />;
};

export const DefaultModal = ({
                               modalState,
                               modalLabel,
                               onRequestClose,
                               children,
                               title,
                               buttons,
                               modalStyles,
                               removeCloseBtn = false,
                               fullOnMobile = false
}) => {
  const { t } = useTranslation();
  const { height }  = useWindowDimensions();

  const {isMobile} = useWindowWidth();

  const [innerModalState, changeInnerModalState] = useState(false)

  useEffect(() => {
    changeInnerModalState(modalState);

    modalState ? parentPostMessage(dispatch_modal_open, modalLabel) : parentPostMessage(dispatch_modal_close, modalLabel);

  }, [modalState])



  const dontShowCloseBtn = removeCloseBtn || (fullOnMobile && isMobile);



  return (
    <Modal autoFocus={false} isOpen={innerModalState} style={getStyles(modalStyles, fullOnMobile, height, isMobile)} contentLabel={modalLabel} onRequestClose={onRequestClose} fullOnMobile={fullOnMobile}>
      <Container>
        {
          !dontShowCloseBtn && (
          <CloseContainer onClick={onRequestClose}>
            <Icon src={delete_grey} alt="close" />
          </CloseContainer>
          )
        }
        { title ? <ModalHead>{title}</ModalHead> : null }
        <Content>
          {children}
        </Content>
        <Buttons>
        { buttons && buttons instanceof Array ? 
          buttons.map((button, index) => {
            if (button) {
              const { text = t('accept'), onClick = onRequestClose, style = null, color = "mintGreen", href } = button;
              return button.text ?
                <Button
                  href={href}
                  key={`modal-button-${index}`}
                  color={color}
                  style={style}
                  onClick={onClick}
                  text={text}
                /> :
                button;
            } else {
              return null;
            }
          })
          : null 
        }
        </Buttons>
      </Container>
    </Modal>
  );
};
