import {
  MODAL_JOIN,
  MODAL_GUEST,
  MODAL_EVENT,
  MODAL_INVITATION,
  MODAL_FORGOT_PASSWORD,
  MODAL_CODE_ACTIVATED,
  MODAL_INFO,
  MODAL_IMAGES_SELECTED,
  MODAL_FAST_LOGIN,
  MODAL_ENHANCED, MODAL_PROMPT
} from '../types';

const closeModalInvitation = { type: MODAL_INVITATION, payload: false };
const openModalInvitation = { type: MODAL_INVITATION, payload: true };

const closeModalJoin = { type: MODAL_JOIN, payload: false };
const openModalJoin = { type: MODAL_JOIN, payload: true };

const closeModalEvent = { type: MODAL_EVENT, payload: false };
const openModalEvent = event => ({ type: MODAL_EVENT, payload: { open: true, data: event } });

const closeModalForgotPassword = { type: MODAL_FORGOT_PASSWORD, payload: false };
const openModalForgotPassword = { type: MODAL_FORGOT_PASSWORD, payload: true };

const closeModalCodeActivated = { type: MODAL_CODE_ACTIVATED, payload: false };
const openModalCodeActivated = { type: MODAL_CODE_ACTIVATED, payload: true };

const closeModalInfo = { type: MODAL_INFO, payload: false };
const openModalInfo = ({
                         title, message, onClose, buttons, buttonText
}) => ({
  type: MODAL_INFO, payload: { open: true, title, message, onClose, buttons, buttonText }
});

const closeModalEnhancedRaw = { type: MODAL_ENHANCED, payload: false };
const openModalEnhancedRaw  = ({
                         children, fullOnMobile, reqSetTrueHeight
                       }) => {
  return ({
    type: MODAL_ENHANCED, payload: { open: true, children, fullOnMobile, reqSetTrueHeight }
  })
}

const closeModalPromptRaw = { type: MODAL_PROMPT, payload: false };
const openModalPromptRaw  = ({
                                 children, fullOnMobile, showCloseButton = true
                               }) => {
  return ({
    type: MODAL_PROMPT, payload: { open: true, children, fullOnMobile, showCloseButton }
  })
}



const closeModalGuest = { type: MODAL_GUEST, payload:false };
const openModalGuest = () => ({ type: MODAL_GUEST, payload: { open: true,  } });

const closeModalFastLogin = { type: MODAL_FAST_LOGIN, payload: false };
const openModalFastLogin = ({onClose}) => ({ type: MODAL_FAST_LOGIN, payload: { open: true, onClose } });

const closeModalImagesSelected = { type: MODAL_IMAGES_SELECTED, payload: false };
const openModalImagesSelected = { type: MODAL_IMAGES_SELECTED, payload: true };

export const toggleModalInvitation = (dispatch, state) => {
  if (state) {
    dispatch(openModalInvitation);
  } else {
    dispatch(closeModalInvitation);
  }
};

export const toggleModalJoin = (dispatch, state) => {
  if (state) {
    dispatch(openModalJoin);
  } else {
    dispatch(closeModalJoin);
  }
};

export const toggleModalEnhancedRaw = (dispatch, state) => {
  if (state) {
    dispatch(openModalEnhancedRaw(state));
  } else {
    dispatch(closeModalEnhancedRaw);
  }
}

export const toggleModalPromptRaw = (dispatch, state) => {
  if (state) {
    dispatch(openModalPromptRaw(state));
  } else {
    dispatch(closeModalPromptRaw);
  }
}

export const toggleModalEvent = (dispatch, state) => {
  if (state) {
    dispatch(openModalEvent(state === true ? null : state));
  } else {
    dispatch(closeModalEvent);
  }
};

export const toggleModalForgotPassword = (dispatch, state) => {
  if (state) {
    dispatch(openModalForgotPassword);
  } else {
    dispatch(closeModalForgotPassword);
  }
};

export const toggleModalCodeActivated = (dispatch, state) => {
  if (state) {
    dispatch(openModalCodeActivated);
  } else {
    dispatch(closeModalCodeActivated);
  }
};

export const toggleModalInfo = (dispatch, state) => {
  if (state) {
    dispatch(openModalInfo(state));
  } else {
    dispatch(closeModalInfo);
  }
};

export const toggleModalGuest = (dispatch, state) => {
  if (state) {
    dispatch(openModalGuest(state));
  } else {
    dispatch(closeModalGuest);
  }
};

export const toggleModalFastLogin = (dispatch, state) => {
  if (state) {
    dispatch(openModalFastLogin(state));
  } else {
    dispatch(closeModalFastLogin);
  }
};

export const toggleModalImagesSelected = (dispatch, state) => {
  if (state) {
    dispatch(openModalImagesSelected);
  } else {
    dispatch(closeModalImagesSelected);
  }
};