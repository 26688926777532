import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import store from './redux/store';
import i18n from './i18n/i18n';
import theme from './theme';
import ScrollToTop from './utils/scrollToTop';

import { MainRouter } from './router';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <ScrollToTop>
              <MainRouter />
            </ScrollToTop>
          </I18nextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
