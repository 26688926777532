import React from 'react';
import next_button_gallery from '../../../static/assets/icons/next_button_gallery.svg';


export const ButtonNext = (props) => {
	const {  getStyles, innerProps } = props;

	return (
		<button
			type="button"
			style={{...getStyles('navigationNext', props),  background: 'transparent'}}
			{...innerProps}
		>
			<img src={next_button_gallery} alt={'prev'} height={48} />
		</button>
	);
}

export const ButtonPrev = (props) => {
	const { getStyles, innerProps } = props;

	return (
		<button
			type="button"
			style={{...getStyles('navigationPrev', props), rotate: '180deg', background: 'transparent'}}
			{...innerProps}
		>
			<img src={next_button_gallery} alt={'prev'} height={48} />
		</button>
	);
}