import React from 'react';
import styled from 'styled-components';

import { JoinEventModal } from '../Modals/joinEvent';
import { EventInvitationModal } from '../Modals/eventInvitation';
import { EventGenerator } from '../Modals/eventGenerate';
import { useWindowDimensions } from '../../utils/useWindowDimensions';
import { InfoModal } from '../Modals/infoModal';
import {GuestNickName} from "../Modals/guestNickName";
import {FastLoginPopUp} from "../Modals/fastLogin";
import {EnhancedRawModal} from "../Modals/EnhancedRawModal";
import {PromptModalRaw} from "../Modals/PromptModalRaw";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  min-height: ${({ windowHeight }) => windowHeight}px;
  max-width: ${({ theme: { container } }) => container.maxWidth}px;
  margin: 0 auto;
`;

export const MainContainer = props => {
  const { height}  = useWindowDimensions();

  return (
    <Container windowHeight={height}>
      {props.children}
      <JoinEventModal />
      <EventInvitationModal />
      <EventGenerator />
      <InfoModal />
      <GuestNickName />
      <FastLoginPopUp />
      <EnhancedRawModal />
      <PromptModalRaw />
    </Container>
  );
};
