import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { validate } from '../../utils/validateForms';
import { InputContainer } from './parts/inputContainer';
import { FullButton } from '../common/fullBtn';
import { toggleModalInfo } from '../../redux/actions/modals';
import { ErrorMessage } from '../common/errorMessage';

export const EditPasswordForm = ({ password }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    password: '',
    newPassword: '',
    passwordCopy: ''
  });
  const [errorMessages, setErrorMessages] = useState({
    password: '',
    newPassword: '',
    passwordCopy: '',
    general: ''
  });
  const changeData = (name, value) => {
    const newData = {
      ...data,
    };
    newData[name] = value;
    setData(newData);
  };

  const checkPassword = () => {
    setErrorMessages({ 
        ...errorMessages, 
        password: !data.password ? t('password_cannot_be_empty') : ''
    });

    return !!data.password;
  }

  const checkPasswordCopy = (value) => {
    const copy = value instanceof String ? value : data.passwordCopy;

    setErrorMessages({ 
        ...errorMessages, 
        passwordCopy: copy === data.newPassword ? '' : 'Das neue Passwort und seine Kopie sind nicht identisch'
    });

    return copy === data.newPassword;
  }

  const checkNewPassword = (value) => {
    const newPassword = value instanceof String ? value : data.newPassword;
    const result = validate({password: newPassword});

    setErrorMessages({ 
        ...errorMessages, 
        newPassword: data.password && newPassword === data.password ? 'Das neue Passwort ist das gleiche wie das aktuelle' : (result.password && t(result.password.error) || '')
    });

    return newPassword !== data.password && result.password && result.password.success;
  }

  const submitForm = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'auth/change_password'}`;

    if (checkPassword() && checkNewPassword() && checkPasswordCopy()) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          oldPassword: data.password,
          newPassword: data.newPassword
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            onChangeSuccess();
          } else {
            if (data.error === 'invalid_customer') {
              setErrorMessages({ ...errorMessages, password: 'Aktuelles Passwort falsch', general: ''});
            } else {
              setErrorMessages({ ...errorMessages, general: t(data.error)});
            }
          }
        })
        .catch(err => console.log(err));
    }
  };

  const onChangeSuccess = () => {
    toggleModalInfo(dispatch, {
      title: 'Vielen Dank!',
      message: 'Ihr Passwort wurde geändert.'
    });
  }

  return (
    <>
      <InputContainer 
        name="password" 
        label="Aktuelles Passwort"
        value={data.password}
        onChange={({ target: { value } }) => changeData('password', value) }
        onBlur={ ({ target: { value } }) => checkPassword(value) }
        error={errorMessages.password}
        type="password"
      />

      <InputContainer 
        name="new_password" 
        label="Neues Passwort" 
        value={data.newPassword}
        onChange={({ target: { value } }) => changeData('newPassword', value) }
        onBlur={ ({ target: { value } }) => checkNewPassword(value) }
        error={errorMessages.newPassword}
        type="password"
      />

      <InputContainer 
        name="password_copy" 
        label="Neues Passwort wiederholen" 
        value={data.passwordCopy}
        onChange={({ target: { value } }) => changeData('passwordCopy', value) }
        onBlur={ ({ target: { value } }) => checkPasswordCopy(value) }
        error={errorMessages.passwordCopy}
        type="password"
      />

      {errorMessages.general ? <ErrorMessage>{ errorMessages.general }</ErrorMessage> : null}

      <FullButton genWith="386px"
        mt={30}
        btnColor="mintGreen"
        text={t('save')}
        click={submitForm}></FullButton>
    </>
  );
};