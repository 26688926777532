import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { toggleModalJoin } from '../../redux/actions/modals';
import { DefaultModal } from './modal';

import { ActivateCode } from '../common/activateCode';

const ModalHead = styled.div`
  text-align: center;
  margin-bottom: 12vh;
  font-size: 21px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  ${({ theme }) =>
    theme.onMD(`
    margin: 25px 0 12vh !important;
    font-size: 18px;
  `)}
`;

export const JoinEventModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const modalState = useSelector(state => state.modalJoin);
  const changeModalState = state => toggleModalJoin(dispatch, state);

  return (
    <DefaultModal modalState={modalState} modalLabel="join modal" onRequestClose={() => changeModalState(false)}>
      <ModalHead>{t('join_event')}</ModalHead>
      <ActivateCode callback={() => changeModalState(false)} />
    </DefaultModal>
  );
};
