import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { formatBytes } from '../../../utils/dataFormat/fileSize';
import { timeDistance } from '../../../utils/dateFns';
import { removeContainer, processContainer } from '../../../redux/actions/upload';

import delete_grey from '../../../static/assets/icons/delete_grey.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
  align-items: center;
  width: 500px;
  position: absolute;
  left: 2%;
  .line-break {
    display: none;
  }
  ${({ theme }) =>
    theme.onMidXL(`
      left: 1%;
      .line-break {
        display: block;
      }
  `)};
  ${({ theme }) =>
    theme.onMD(`
    display: flex;
    position: fixed;
    justify-content: center;
    height: 72px;
    padding-bottom: 12px;
    width: 100%;
    top: 100px;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    .line-break {
        display: none;
      }
  `)}
  ${({ theme }) =>
    theme.onXS(`
    top: 60px;
    padding-left: 10px;
    justify-content: flex-start;
    .line-break {
        display: block;
      }
  `)}
`;

const rotate = keyframes`
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
`;

const Loader = styled.div`
  width: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
`;

const LoaderInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  div.text {
    position: absolute;
    width: 60px;
    display: flex;
    justify-content: center;
    top: 22px;
    left: -2px;
  }
  div.spinner {
    position: absolute;
    animation: ${rotate} 1s linear infinite;
    width: 48px;
    height: 48px;
    top: 8px;
    left: 3px;
    border-radius: 50%;
    box-shadow: 0 2px 0 0 ${({ theme: { palette } }) => palette.mintGreen};
    transform-origin: 24px 24px;
    box-sizing: content-box;
  }
`;

const TextData = styled.div`
  margin-left: 8px;
  font-size: 12px;
`;

const CloseContainer = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  cursor: pointer;
  top: -15px;
  right: 0;
  ${({ theme }) =>
    theme.onMidXL(`
    top: -10px;
    left: 300px;
  `)}
  ${({ theme }) =>
    theme.onMD(`
    left: auto;
    right: 10px;
    top: 0;    
    width: 25px;
    height: 25px;
  `)}
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  &:hover {
    filter: invert(100%);
  }
  ${({ theme }) =>
    theme.onMD(`
    width: 26px;
    height: 26px;
  `)}
`;

export const LoadedData = () => {
  const dispatch = useDispatch();
  const activeEvent = useSelector(state => state.activeEvent);
  const uploadedData = useSelector(state => state.uploadContainer);

  const eventID = activeEvent.id;
  const data = uploadedData[eventID];
  // console.log('uploadedData', uploadedData);
  if (!data) return null;
  // uploaded bundle
  let uploadedFiles = 0;
  let totalFiles = 0;
  let uploadedAmount = 0;
  let totalAmount = 0;

  // unprocessed part
  let uploadedFilesProgress = 0;
  let totalFilesProgress = 0;
  let uploadedAmountProgress = 0;
  let totalAmountProgress = 0;

  const dataKeys = Object.keys(data);

  dataKeys.forEach(e => {
    if (e === 'started') return;
    const tempData = data[e];
    if (tempData.done) uploadedFiles += 1;
    uploadedAmount += tempData.uploaded;
    totalFiles += 1;
    totalAmount += tempData.size;
    if (!data[e].processed) {
      if (tempData.done) uploadedFilesProgress += 1;
      uploadedAmountProgress += tempData.uploaded;
      totalFilesProgress += 1;
      totalAmountProgress += tempData.size;
    }
  });

  const amountFullUploaded = uploadedAmount >= totalAmount;
  const filesFullProcessed = totalFiles === uploadedFiles;

  let percentUploaded = Math.round((uploadedAmountProgress * 100) / totalAmountProgress);

  let calcTime = new Date(
    Number(new Date().getTime()) -
      Math.round(
        ((Number(new Date().getTime()) - Number(data.started)) * (100 - percentUploaded)) /
          percentUploaded,
      ),
  );

  const cancelUpload = () => {
    dataKeys.forEach(e => {
      if (e !== 'started') data[e].abort && data[e].abort();
    });
    removeContainer(dispatch, eventID);
  };

  const timeLeft = Date.now() > new Date(calcTime) ? timeDistance(calcTime) : 0;

  if (filesFullProcessed) removeContainer(dispatch, eventID);

  if (amountFullUploaded && !filesFullProcessed) {
    processContainer(dispatch, eventID);
    return (
      <Wrapper>
        {totalFiles - uploadedFiles ? (
          <>
            <Loader>
              <LoaderInner>
                <div className="spinner" />
                <div className="text" />
              </LoaderInner>
            </Loader>
            <TextData>{`Noch zu verarbeiten: ${uploadedFiles} von ${totalFiles} `}</TextData>
          </>
        ) : (
          <TextData>{`Done`}</TextData>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <CloseContainer onClick={cancelUpload}>
        <Icon src={delete_grey} alt="close" />
      </CloseContainer>
      <Loader>
        <LoaderInner>
          <div className="spinner" />
          <div className="text">{percentUploaded}%</div>
        </LoaderInner>
      </Loader>
      <TextData>
        {/* {`Upload: ${uploadedFilesProgress} von ${totalFilesProgress} Bildern.`}
        <br className="line-break" /> */}
        {` ${formatBytes(uploadedAmountProgress)} von ${formatBytes(totalAmountProgress)} ${
          !timeLeft ? '' : `/ ${timeLeft}`
        }`}
      </TextData>
    </Wrapper>
  );
};
