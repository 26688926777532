import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { validate } from '../../utils/validateForms';
import { InputContainer } from './parts/inputContainer';
import { FullButton } from '../common/fullBtn';
import { ErrorMessage } from '../common/errorMessage';

export const LoginForm = ({ isGuest = false, forgotPassword = null }) => {
  const { t } = useTranslation();
  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');
  const [errorMessages, setErrorMessages] = useState({
    email: '',
    password: ''
  });

  const isValid = (key, value) => {
    let valid = true;
    const messages = { ...errorMessages };

    if (!email || key === 'email' && !value) {
      messages.email = t(validate({ email: email.trim() }).email.error);
      valid = false;
    }
    
    if (!password || key === 'password' && !value) {
      messages.password = t(validate({ password: password.trim() }).password.error);
      valid = false;
    }

    setErrorMessages({ ...messages, general: ''});

    return valid;
  }

  const submitForm = () => {
    const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${ isGuest ? 'auth/login_temp' : 'auth/login'}`; //`${process.env.REACT_APP_BACKEND_DOMAIN}auth/login`;
    const data = {
      username: email.trim(),
      password: password.trim(),
    };

    if (isValid()) {
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          let general = data.error ? t(data.error) : '';
          if (data.success) {
            onLoginSuccess();
          } else {
            if (data.error === 'invalid_customer' || data.error === 'wrong_credentials') {
              general = <GeneralError>
                Falsche Anmeldedaten<br/><br/>
                <p>
                  Bitte versuchen Sie es danach erneut. Alternativ können Sie hier (<a href="/register">REGISTRIERUNG</a>) ein Kundenkonto anlegen.
                </p>
              </GeneralError>;
            }
          }

          setErrorMessages({ ...errorMessages, general });
        })
        .catch(err => console.log(err));
    }
  };

  const onLoginSuccess = () => {
    window.location.replace(window.location.origin);
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }

  return (
    <>
      <InputContainer
        label={t('email_address')}
        name="email"
        placeholder={t('email')}
        value={email}
        onChange={
          ({ target: { value } }) => {
            changeEmail(value);
            setErrorMessages({ ...errorMessages, email: '' })
            //isValid('email', value);
          }
        }
        onKeyPress={onKeyPress}
        error={errorMessages.email}
      />

      <InputContainer
        label={t('password')}
        type="password"
        name="password"
        placeholder={t('password')}
        value={password}
        onChange={
          ({ target: { value } }) => { 
            changePassword(value);
            setErrorMessages({ ...errorMessages, password: '' })
            //isValid('password', value);
          }
        }
        onKeyPress={onKeyPress}
        error={errorMessages.password}
        labelLink={forgotPassword}
      />

      { errorMessages.general ? <ErrorMessage>{ errorMessages.general }</ErrorMessage> : null }

      <FullButton
        genWith="386px"
        mt={30}
        btnColor="mintGreen"
        click={submitForm}
        text={t('login')}
      />
    </>
  );
};

const GeneralError = styled.div`
  text-align: center;

  a { 
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    } 
  }

`;

const ForgotPassword = styled.div`
  font-size: 22px;
  color: ${({ theme: { palette } }) => palette.lightGray};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  margin-bottom: auto;
  ${({ theme }) =>
    theme.onSM(`
    font-size: 14px;
    margin-bottom: auto;
  `)}
`;