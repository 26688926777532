import { UPLOAD_CONTAINER, UPDATE_CONTAINER, REMOVE_CONTAINER, PROCESS_CONTAINER } from '../types';

export const uploadContainer = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_CONTAINER:
      return { ...state, ...action.payload };
    case UPDATE_CONTAINER:
      const { eventID, index, newState, done, abort } = action.payload;
      if (state[eventID]) {
        state[eventID][index].uploaded = newState;
        state[eventID][index].done = done;
        state[eventID][index].abort = abort;
        return { ...state };
      } else {
        return state;
      }
    case REMOVE_CONTAINER:
      const copyState = { ...state };
      delete copyState[action.payload.eventID];
      return copyState;
    case PROCESS_CONTAINER:
      const processedState = { ...state };
      Object.keys(processedState[action.payload.eventID]).forEach(e => {
        if (e === 'started') return;
        processedState[action.payload.eventID][e]['processed'] = true;
      });
      processedState[action.payload.eventID].started = new Date().getTime();
      setTimeout(() => (processedState[action.payload.eventID] ? processedState : state), 200);
    default:
      return state;
  }
};
