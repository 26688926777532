import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import { InputContainer } from '../forms/parts/inputContainer';
import { FullButton } from '../common/fullBtn';
import {toggleModalFastLogin, toggleModalInfo} from '../../redux/actions/modals';
import { ErrorMessage } from '../common/errorMessage';
import {DefaultModal} from "./modal";
import {Separator} from "../common/separator";
import {LoginForm} from "../forms/loginForm";
import {validate} from "../../utils/validateForms";
import {getCurrentUser} from "../../redux/actions/log";

import checkbox_checked from '../../static/assets/icons/checkbox_checked.svg';


const Title = styled.div`  
  margin-bottom: 25px;
  margin-top: ${({mt}) => mt || 0 }px;
  font-size: 18px;
  text-align: center;
  font-family: ${({ theme }) => theme.font.default};
  color: ${({ theme }) => theme.palette.dark};
  ${({ theme }) =>
	theme.onMD(`
    font-size: 15px;
  `)}
`;

const DotContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 5px auto;
	text-align: center;
  font-size: 18px;
  ${({ theme }) => theme.onMD(`
    font-size: 15px;
  `)}
`;

const SpaceContainer = styled.span`
	margin: 0 7px;
`;

const InfoText = styled.div`
  width: 100%;
  padding: 20px 10px;
  color: #000000;
  font-size: 18px;
  word-break: break-word;

  p { margin: 20px 0 }
`;

const CheckBoxContainer = styled.div`
	display: flex;
	margin-top: 30px;
  .round {
		width: 70px;
    position: relative;
  }

  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: -10px;
    position: absolute;
    top: 0;
    width: 28px;
  }

  .round label:after {
		content: url("${checkbox_checked}");
    //border: 2px solid #fff;
    border-top: none;
    border-right: none;
    //content: "";
    //height: 6px;
    //left: 7px;
    opacity: 0;
    //position: absolute;
    //top: 8px;
    //transform: rotate(-45deg);
    //width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: ${({ theme: { palette } }) => palette.darkGray};
    border-color: ${({ theme: { palette } }) => palette.darkGray};
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
`;

const NewspaperText = styled.div`
	font-size: 14px;
`;

const BottomSection = styled.div`
	display: flex;
	justify-content: center;
	font-size: 16px;
	color: ${({ theme: { palette } }) => palette.darkGray};
`;

const LoginAnchor = styled.a`
	margin-left: 5px;
		text-decoration: underline;
	`;

export const FastLoginPopUp = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const modalFastLogin = useSelector(state => state.modalFastLogin);
	const {open, onClose} = modalFastLogin;
	const activeEvent = useSelector(state => state.activeEvent);

	const [data, setData] = useState({
		email: '',
		newsletter: true
	});

	const [errorMessages, setErrorMessages] = useState({
		general: '',
	});

	// useEffect(() => {
	// 	toggleModalFastLogin(dispatch, {open: true});
	// }, []);

	const changeModalState = state => toggleModalFastLogin(dispatch, state);

	const onCloseModal = () => {
		if (onClose) onClose();
		changeModalState(false);
	};

	const changeNewsletter = () => {
		setData({
			...data,
			newsletter: !data.newsletter
		});
	};

	const changeEmail = (value) => {

		setData({...data, email: value});
	};

	const isValid = () => {
		const trimValue = data.email && data.email.trim();
		const isValidEmail = validate({ email: trimValue });

		if (isValidEmail.email.success) {
			setErrorMessages({ general: ''});
			return true;
		} else {
			setErrorMessages({ general: isValidEmail.email.error});
			return false;
		}
	};

	const onChangeSuccess = () => {
		getCurrentUser(dispatch, null).then(() => {
			// toggleModalInfo(dispatch, {
			// 	title: 'Vielen Dank!',
			// 	message: 'Wir haben die Änderung Ihrer Daten übernommen.'
			// });
			onCloseModal();
		});

	};

	const submitForm = () => {
		const url = `${process.env.REACT_APP_BACKEND_DOMAIN}${'auth/guest_fast_login'}`;

		if (isValid()) {

			fetch(url, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: data.email.trim(),
					newsletter: data.newsletter
				}),
			})
				.then(response => response.json())
				.then(data => {
					if (data.success) {
						onChangeSuccess();
					} else {
						setErrorMessages({ ...errorMessages, general: t(data.error)});
					}
				})
				.catch(err => console.log(err));
		}
	};

	const openLoginPopup = () => {
		onCloseModal();
		toggleModalInfo(dispatch, {
			title: '',
			message: <LoginForm isGuest={true} />,
			buttons: false,
			onClose: () => toggleModalFastLogin(dispatch, {open: true})
		});
	};

	if (!activeEvent) return null;


	const baseContent = (
		<>
			<Title>Als sendmoments Mitglied können Sie</Title>

			<DotContainer>
				&#x2022; <SpaceContainer /> Favoriten markieren
			</DotContainer>
			<DotContainer>
				&#x2022; <SpaceContainer /> eigene Alben anlegen
			</DotContainer>
			<DotContainer>
				&#x2022;  <SpaceContainer />   gemeinsam Fotos sammeln
			</DotContainer>

			<InputContainer
				name="nickname"
				label={`${t('E-Mail Adresse')}`}
				value={data.email}
				onChange={({ target: { value } }) => changeEmail(value) }
				onBlur={ ({ target: { value } }) => isValid(value) }
				placeholder={`${t('E-mail')}`}
				error={``}
			/>

			{errorMessages.general ? <ErrorMessage>{ t(errorMessages.general) }</ErrorMessage> : null}

			<CheckBoxContainer onClick={() => changeNewsletter()}>
				<div className='round'>
					<input
						type="checkbox"
						name="news"
						checked={data.newsletter}
						readOnly
						// onChange={changeNewsletter}
					/>
					<label className="form-control"></label>
				</div>
				<NewspaperText>
					Tolle Geschenkideen und Inspirationen für Ihre Liebsten.
					Jetzt Newsletter abonnieren und informiert bleiben.
				</NewspaperText>
			</CheckBoxContainer>

			<FullButton genWith="100%"
									mt={30}
									btnColor="mintGreen"
									text={'Mitglied werden'}
									click={() => submitForm(null, null)}/>

			<Separator />

			<BottomSection>Bereits sendmoments Kunde?
				<LoginAnchor onClick={openLoginPopup}>Einloggen</LoginAnchor>
			</BottomSection>
		</>
	);

	return (
		<DefaultModal
			modalState={modalFastLogin && open}
			modalLabel="fast_login_modal"
			onRequestClose={onCloseModal}
			title="Mitglied werden"
		>
			<InfoText>{baseContent}</InfoText>
		</DefaultModal>
	);
};