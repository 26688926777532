import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './static/css/index.css';
import 'react-day-picker/dist/style.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
// import * as serviceWorker from './serviceWorker';

const root = createRoot(document.getElementById('root'));

root.render(<App />)

// serviceWorker.unregister();
